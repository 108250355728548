<template>
  <form :action="url" method="POST" ref="payForm" id="payment-form">
    <csrf-token></csrf-token>
    <membership-select-upgrade :memberships="memberships" :membershipselected.sync="this.membership" :currency="currency" :current-membership="currentMembership"></membership-select-upgrade>
    
    <input type="hidden" name="card" value="0">
    <input type="hidden" name="payment_method_id" :value="payment_method_id">
    <!-- <input type="hidden" name="payment_id" :value="payment_id"> -->
    <input type="hidden" name="payment_id" :value="inscriptionCharge" v-if="inscriptionCharge">
    <input type="hidden" name="token_id" id="token_id" :value="token">
    <input type="hidden" name="device_data_id" :value="deviceDataId">

    <div class="form-group" v-if="showCoupon">
      <label for="coupon" class="form-label">
        {{  __('Enter Travel Voucher') }}
      </label>
      
      <div class="input-group">
        <input
          type="text"
          name="coupon"
          :placeholder="__('Travel Voucher')"
          id="coupon"
          class="form-control"
          v-model="coupon"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-primary" type="button" @click="validateCoupon" :disabled="is_coupon_loading">{{ __('Validate') }}
          </button>
        </div>
      </div>

      <div v-if="coupon_message" class="mt-2 text-muted">{{ coupon_message }}</div>
    </div>

    <button type="submit" class="btn btn-primary btn-block" v-if="shouldHidePayment">
      {{ __('Try subscription') }}
    </button>
  
    <card-component #default="{holderName, cardNumber, cvv2, expirationYear, expirationMonth, states, handlers, validators, monthOptions, yearOptions}"
      :years="years"
      :now="now"
      v-if="currency.code === 'MXN' && !shouldHidePayment"
    >
      <img width="150px;" src="/images/cards/logo.png" />
      <img width="150px;" src="/images/cards/tarjetas1.png" />
      <div class="alert alert-danger" v-if="inscription_error">
        {{ inscription_error }}
      </div>
      <div class="row" id="addNewCard">
        <div class="form-group col-md-6">
          <label for="holder_name" class="form-label">{{ __('Cardholder') }}</label>
          <input type="text" name="holder_name" :placeholder="__('Cardholder')" id="holder_name"
              class="form-control" data-openpay-card="holder_name">
        </div>
        <div class="form-group col-md-6">
          <label for="card_number" class="form-label">{{ __('Card number') }}</label>
          <input maxlength="16" type="text" name="card_number" placeholder="XXXXXXXXXXXXXXXX" id="card_number"
            class="form-control" @input="handlers.cardNumber" @blur="validators.cardNumber" :value="cardNumber"
            :class="{'is-invalid': !states.cardNumber}" data-openpay-card="card_number">
          <div v-if="!states.cardNumber" class="text-danger">
            {{ __('Invalid card number') }}
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="expiration_month" class="form-label">{{ __('Month') }}</label>
              <select :value="expirationMonth" @change="handlers.expirationMonth" @blur="validators.expirationDate" name="expiration_month" class="form-control"
                :class="{'is-invalid': !states.expirationDate}" data-openpay-card="expiration_month"
              >
                <option v-for="option in monthOptions" v-bind:value="option.value" :key="`monty_${option.value}`">
                    {{ option.text }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="expiration_year" class="form-label">{{ __('Year') }}</label>
              <select :value="expirationYear" @change="handlers.expirationYear" @blur="validators.expirationDate" name="expiration_year" class="form-control"
                  :class="{'is-invalid': !states.expirationDate}" data-openpay-card="expiration_year"
              >
                <option v-for="option in yearOptions" v-bind:value="option.value" :key="`year_${option.value}`">
                    {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- <input type="hidden" name="token_subscription" id="token_subscription" :value="token_subscription"> -->
        <div class="form-group col-md-6">
          <label for="cvv2" class="form-label">CVC/CVV</label>
          <input maxlength="3" type="text" name="cvv2" placeholder="123" id="cvv2" class="form-control"
            @input="handlers.cvv2" @blur="validators.cvv2" :value="cvv2"
            :class="{'is-invalid': !states.cvv2}"
            data-openpay-card="cvv2"
          >
          <div v-if="!states.cvv2" class="text-danger">
            CVV {{ __('Incorrect') }}
          </div>
        </div>
      </div>

      <div class="form-check mb-3">
        <input type="checkbox" class="form-group form-check-input" v-model="terms_and_conditions" id="terms_and_conditions">
        <label for="terms_and_conditions">
          {{ __('With the user registration you accept') }}
          <a :href="`/termsAndConditions`" target="_blank">
            {{ __('Terms and conditions') }}
          </a> {{ __('and') }}
          <a :href="`/docs/privacy-policies-${lang}.pdf`">{{ __('Privacy policies') }}</a>.
        </label>
      </div>
    </card-component>
    <stripe-checkout v-else-if="currency.code === 'USD' && currentMembership === null && !shouldHidePayment"
      :total="total"
      :currency="currency.code"
      subscribible
      :type="(membershipObject.registration_pricestripe != null && membershipObject.registration_pricestripe > 0) ? 'payment' : 'createMethod'"
      @create-payment-method-successfully="createPaymentMethodSuccessfully"
      @payment-successfully="paymentSuccessfully"
      show-terms
    ></stripe-checkout>

    <button type="submit" class="btn btn-primary btn-block" v-if="currency.code === 'USD' && currentMembership !== null  && !shouldHidePayment">
      {{ __('Confirm') }}
    </button>
    <button :disabled="loader || !terms_and_conditions" type="submit" class="btn btn-primary" v-if="currency.code === 'MXN' && !shouldHidePayment" @click.prevent="makeToken"><i v-if="loader" class="fas fa-spin fa-spinner"></i>Pagar</button>
  </form>
</template>

<script>
export default {
  props: {
    memberships: {
      type: Array,
      required: true
    },
    now: {
      tpye: String,
      required: true,
    },
    years: {
      type: Array,
      required: true
    },
    currency: {
      type: Object,
      required: true
    },
    membership: {
      type: Number,
      default: null,
    },
    showCoupon: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true
    },
    currentMembership: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      payment_method_id: null,
      coupon: '',
      couponObj: null,
      coupon_validated: false,
      valid_coupon: false,
      is_coupon_loading: false,
      coupon_message: null,
     //  token_subscription:null,
      membershipselec: null,
      token: null,
      deviceDataId: '',
      inscriptionCharge: null,
      inscription_error: null,
      loader: false,

      terms_and_conditions: false, // this is only for openpay, for stripe is inside StripeCheckout component
    }
  },
  computed: {
    membershipObject() {
      if (this.membershipselec == null) return null;
      return this.memberships.find(m => m.id == this.membershipselec);
    },
    shouldHidePayment() {
      return this.valid_coupon && this.couponObj.applied_to == 'subscription';
    },
    total() {
      let t = 0;
      if (this.membershipObject) {
        if (this.currency.code === 'USD') {
          t = this.membershipObject.registration_pricestripe || 0;
        } else {
          t = this.membershipObject.registration_priceopenpay || 0;
        }
      }

      if (this.valid_coupon && this.couponObj.applied_to == 'inscripcion') {
        t = this.couponObj.fixed_rate == 1 ? this.couponObj.value : t - this.couponObj.value;
      }

      return t;
    }
  },
  methods: {
    createPaymentMethodSuccessfully(paymentMethod) {
      this.payment_method_id = paymentMethod.id;
      this.$nextTick(() => {
        this.$refs['payForm'].submit();
      });
    },
    paymentSuccessfully(paymentResult) {
      this.inscriptionCharge = paymentResult.id;
      this.$nextTick(() => {
        this.$refs['payForm'].submit();
      });
    },
    async validateCoupon() {
      this.is_coupon_loading = true;
      let response = await axios.get(`/membership-coupon/${this.coupon}`);
      this.coupon_validated = true;
      this.valid_coupon = response.data.is_valid;
      this.is_coupon_loading = false;
      this.couponObj = response.data.coupon;
      this.coupon_message = response.data.message || null;
    },
    makeToken: function (stop = false) {
      this.inscription_error = null;
      this.loader = true;
      const success = (response) => {
          this.token = response.data.id;
          this.$nextTick(() => {
              this.$refs['payForm'].submit();
          });
      }
      const err = (err) => {
          console.log('error ', err);
          this.loader = false;
      }
      const createSecondToken = async (response) => {
        let self = this;
        try {
          await axios.post(`/incriptions/${self.membershipselec}/payment`, {
            token_id: response.data.id,
            device_data_id: self.deviceDataId,
            coupon: this.valid_coupon ? this.coupon : null
          }).then(function (response) {
            self.inscriptionCharge = response.data.data;
          });
        } catch (error) {
          this.loader = false;
          if (error.response) {
            self.inscription_error = error.response.data.message;
          }  else {
            self.inscription_error = __('*We could not make the payment, try again.');
          }
          return;
        }
        // Nos aseguramos que hayan pasado al menos 2 segundos antes de generar el siguiente token
        setTimeout(() => {
          // this.token_subscription = response.data.id;
          OpenPay.token.extractFormAndCreate('payment-form', success, err);
        }, 2000); 
      }

      OpenPay.token.extractFormAndCreate('payment-form', (this.membershipObject.registration_priceopenpay != null && this.membershipObject.registration_pricestripe > 0) ? createSecondToken : success, err);
      
    }
  },
  mounted() {
    if (this.currency.code === 'MXN') {
      this.deviceDataId = OpenPay.deviceData.setup();
    }
    if (this.membership != null) {
      this.membershipselec = this.membership;
    }
  }
}
</script>