<template>
  <form class="px-4 py-3 add-tour-to-cart-form" @submit.prevent="submit">
    <div class="form-group">
      <label class="font-weight-bold" :for="'adult_' + tourId">{{ __('Adults') }}:</label>
      <select class="form-control" name="adult" :id="'adult_' + tourId" v-model="adult">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>
    </div>

    <div class="form-group">
      <label class="font-weight-bold" :for="'child_' + tourId">{{ __('Children') }}:</label>
      <select class="form-control" name="child" :id="'child_' + tourId" v-model="child">
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>
    </div>

    <div class="form-group">
      <label class="font-weight-bold" :for="'infant_' + tourId">{{ __('Infants') }}:</label>
      <select class="form-control" name="infant" :id="'infant_' + tourId" v-model="infant">
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>
    </div>
  
    <button type="submit" class="btn btn-primary" :disabled="saving">
      <i class="fas fa-spin fa-spinner" v-if="saving"></i>
      <span v-else>{{ __('Add') }}</span>
    </button>
  </form>
</template>

<script>
import EventBus from '../event-bus';

export default {
  props: [
    'url',
    'adultPrice',
    'childPrice',
    'infantPrice',
    'tourId'
  ],
  data() {
    return {
      adult: 2,
      child: 0,
      infant:0,
      saving: false
    }
  },
  methods: {
    async submit() {
      this.saving = true;
      const { data } = await axios.post(this.url, {
        id: this.tourId,
        type: 'tours',
        adult_price: this.adultPrice,
        adult: this.adult,
        child_price: this.childPrice,
        child: this.child,
        infant_price: this.infantPrice,
        infant: this.infant
      });
      this.saving = false;
      this.$swal(this.__('Tour added to cart'));
      EventBus.$emit('cart-changed', data);
    }
  }
}
</script>

<style>
@media screen and (min-width: 769px) {  
  .add-tour-to-cart-form {
    min-width: 400px;
  }
}
</style>
