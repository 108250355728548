<template>
  <div class="rounded p-3" :class="bg">
    <slot>
      <h3 v-if="lang == 'es'" class="f-24" style="color: #0078b3;">Yate</h3>
      <h3 v-if="lang == 'en'" class="f-24" style="color: #0078b3;">Yacht</h3>
    </slot>
    <form action="/list/yates" method="GET">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3">
            <select name="country" id="country" class="form-control" v-model="countrySearch">
              <option value="">{{ __('Select an option') }}</option>
              <option v-for="co in countrys" :key="co.id" :value="co.id">{{ co.city }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <button
            type="submit"
            style="color:#fff; background-color: #0078b3;"
            id="searchButton"
            class="btn btn-block white-a"
          >{{ __('Search for the best offer') }} <i v-if="loader" class="fas fa-spin fa-spinner"></i></button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import debounce from 'lodash/debounce';
import DatePicker from 'vue2-datepicker';
import isBefore from 'date-fns/isBefore';
import parse from 'date-fns/parse';

const API_URL_B = "/api/getcodeb";
const today = new Date();

export default {
  props: {
    months: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    },
    bg: {
      type: String,
      required: false,
      default: "bg-white"
    },
    type: {
      type: String,
      default: ""
    },
    countrys: {
      type: Array,
      default: function () {
        return [];
      }
    },
    lang: {
      type: String,
      default: ""
    },
    date: {
      type: String,
      default: ""
    },
    datef: {
      type: String,
      default: ""
    },
    citysele: {
      type: String,
      default: ""
    },
    hour: {
      type: String,
      default: ""
    },
    numpersonsel: {
      type: String,
      default: ""
    },
    date: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      hoursSerch: "",
      countrySearch:"",
      selectedDate: null,
      selectedDateF: null,
      errors: [],
      cityOptions: [],
      loader: false,
      loaderb:false,
      loadert: false,
      loading: false,
      country:"",
      type:"",
      idcity:"",
      typeSearch:"",
      cityname:"",
      numPersonSelected:"",
      numperson:[
       {'id': 1, 'type': '1 Pax'}
      ,{'id': 2, 'type': '2 Pax'}
      ,{'id': 3, 'type': '3 Pax'}
      ,{'id': 4, 'type': '4 Pax'}
      ,{'id': 5, 'type': '5 Pax'}
      ,{'id': 6, 'type': '6 Pax'}
      ,{'id': 7, 'type': '7 Pax'}
      ,{'id': 8, 'type': '8 Pax'}
      ,{'id': 9, 'type': '9 Pax'}
      ,{'id': 10, 'type': '10 Pax'}
      ,{'id': 11, 'type': '11 Pax'}
      ,{'id': 12, 'type': '12 Pax'}
      ,{'id': 13, 'type': '13 Pax'}
      ,{'id': 14, 'type': '14 Pax'}
      ,{'id': 15, 'type': '15 Pax'}
      ,{'id': 16, 'type': '16 Pax'}
      ,{'id': 17, 'type': '17 Pax'}
      ,{'id': 18, 'type': '18 Pax'}
      ,{'id': 19, 'type': '19 Pax'}
      ,{'id': 20, 'type': '20 Pax'}]
    };
  },
  components: {
    DatePicker
  },
  mounted() {
    if(this.numpersonsel != ""){
      this.numPersonSelected = this.numpersonsel;
    }

    if(this.hour != ""){
      this.hoursSerch = this.hour;
    }

    if(this.citysele != ""){
      this.countrySearch = this.citysele;
    }

    if (this.date) {
      this.selectedDate = parse(this.date, 'yyyy-MM-dd', new Date());
    } 
     if (this.datef) {
      this.selectedDateF = parse(this.datef, 'yyyy-MM-dd', new Date());
     }
  },
  computed: {
    isDisabled: function(){
      return !this.loaderb;
    }
  },
  methods: {
    notBeforeToday(date) {
      return date < today;
    },
    notBeforeStartDate(date) {
      return this.selectedDate === null ? date < today : isBefore(date, this.selectedDate);
    }
  },
  watch: {
  }
};
</script>

<style scoped>
.white-translucent {
  background: rgba(255, 255, 255, 0.8);
}
.white-a {
  color: #fff;
}
</style>