<template>
    <div>
        <div v-if="condo">
            <div class="bg-gray-100 ">
                <div class="container py-3">
                    <div class="row">
                        <div class="col-12 col-xl-8 p-1 d-xl-block" style="max-height: 600px;">
                            <img v-bind:src="condo.images[0].public_path" alt="..." class="img-fluid h-100 w-100 imglist" data-fancybox data-caption="" v-bind:href="condo.images[0].public_path">
                        </div>
                        <div class="col-12 col-xl-4 pt-1 pb-0 pl-0 pr-0">
                            <div class="swiper-container" v-swiper v-if="condo.images">
                                <div class="swiper-wrapper" style="max-height: 600px;">
                                    <div class="swiper-slide w-100" v-for="(asset, index) of condo.images" v-bind:key="asset.id" v-if="index != 0">
                                        <a data-fancybox data-caption="..." v-bind:href="asset.public_path" class="imglist">
                                            <img v-bind:src="asset.public_path" alt="..." class="img-fluid w-100 h-100">
                                        </a>
                                    </div>
                                </div>
                                <div class="swiper-pagination d-none d-xl-block"></div>
                                <div class="swiper-button-next d-xl-none"></div>
                                <div class="swiper-button-prev d-xl-none"></div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="d-flex justify-content-between align-items-start flex-column flex-lg-row align-items-lg-end">
                    <div class="mb-4 mb-lg-0">
                        <h2 class="mt-3" style="color: #000;">{{condo.name}}</h2>
                    </div>
                </div>
            </div>
        
            <section>
                <div class="container">
                    <div class="row">
                        
                        <div class="col-lg-8">
                            <div class="text-block text-justify text-break">
                                <h3 class="mb-3" style="color: #000;">{{ __('About this hotel') }}</h3>
                                <p> 
                                <span v-if="lang == 'en'">
                                    {{condo.descriptionen}} 
                                </span>
                                <span v-else>
                                    {{condo.description}} 
                                </span>

                                </p>
                            </div>
                            <div class="text-block" v-if="lang == 'es'">
                                <h3 class="mb-4" style="color: #000;">{{ __('Amenities') }}</h3>
                                <div class="row">
                                    <div class="col-4 d-flex align-items-start mb-2" v-for="amenity of condo.itincludeses" v-bind:key="amenity+1">
                                        <i class="fas fa-check-circle mt-2 mr-2 text-justify text-muted"></i>
                                        <span>{{amenity}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-block" v-else>
                                <h3 class="mb-4" style="color: #000;">{{ __('Amenities') }}</h3>
                                <div class="row">
                                    <div class="col-4 d-flex align-items-start mb-2" v-for="amenity of condo.itincludesen" v-bind:key="amenity+1">
                                        <i class="fas fa-check-circle mt-2 mr-2 text-justify text-muted"></i>
                                        <span>{{amenity}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-block" v-if="lang == 'es'">
                                <h3 class="mt-3 mb-4" style="color: #000;">{{ __('Notes to consider') }}</h3>
                                <div class="row">
                                    <div class="col-12 text-justify text-break">
                                        {{ condo.noteses }}
                                    </div>
                                </div>
                            </div>
                            <div class="text-block" v-else>
                                <h3 class="mb-4 mt-3" style="color: #000;">{{ __('Notes to consider') }}</h3>
                                <div class="row">
                                    <div class="col-12 text-justify text-break">
                                        {{ condo.notesen }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="pl-xl-4">
                                <div class="card border-0 shadow mb-5 d-sm-block">
                                    <div class="card-header bg-gray-100 py-4 border-0" style="background-color: #fff !important;">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <p class="subtitle text-sm font-weight-bold" style="color: #0078B3">¡{{ __('Single offer') }}!</p>
                                                <h4 class="m-0">{{ __('Availability') }}</h4>

                                                <small><!-- Studio --></small>
                                                <ul class="list-inline text-sm m-0 mb-2 d-flex justify-content-between mt-3">
                                                    <li class="list-inline-item mr-3">
                                                        <i class="fa fa-users mr-1 text-secondary"></i>{{condo.numperson}} {{ __('Guest') }}
                                                    </li>
                                                </ul>
                                                <ul v-if="condo.numninos" class="list-inline text-sm m-0 mb-2 d-flex justify-content-between mt-3">
                                                    <li class="list-inline-item mr-3">
                                                        <i class="fa fa-child mr-1 text-secondary"></i>{{condo.numninos}} {{ __('Children') }}
                                                    </li>
                                                </ul>
                                                <!--ul class="list-inline text-sm m-0 mb-2 d-flex justify-content-between mt-3">
                                                    <li class="list-inline-item mr-3">
                                                        <i class="fa fa-moon mr-1 text-secondary"></i>{{condo.numnight}} {{ __('Nights') }}
                                                    </li>
                                                </ul-->
                                                <div v-if="condo.price != '' && condo.price != null" class="subtitle text-sm" style="color: #fff; background-color: #6e0068;text-align: center;border-radius: 10px;">
                                                    <span v-if="lang == 'en'">
                                                        <span class="font-weight-bold" style="font-size: 26px;"> ${{ formatPrice(condo.price) }} Dlls</span>
                                                        <br><div  style="font-size: 10px;margin-top: -8px;margin-left: 70px;">PER PERSON PER NIGHT.</div>
                                                    </span>
                                                    
                                                    <span v-else>
                                                        <span class="font-weight-bold" style="font-size: 26px;"> ${{ formatPrice(condo.price) }} Dlls</span>
                                                        <br><div style="font-size: 10px;margin-top: -8px;margin-left: 70px;">POR PERSONA POR NOCHE.</div>
                                                    </span>   
                                                </div><br>
                                                <button v-on:click="selectCondo(condo)"
                                                :disabled="loader"
                                                class="btn btn-block" style="background-color: #0078B3; color: #fff;"> <i
                                                    class="far fa-paper-plane mr-2"></i>¡{{ __('I want this offer') }}! <i v-if="loader" class="fas fa-spin fa-spinner"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Contact-->
                                <div class="card border-0 shadow mb-5">                
                                    <div class="card border-0">
                                        <div class=" bg-gray-100 py-2 border-0 card-header" style="background-color: #fff !important;">
                                            <div class="font-weight-bold mb-1" style="background-color: #fff; color: #0078B3;">{{ __('If you have doubts') }}</div>
                                            <span style="font-size: 18px;">{{ __('Contact us') }}</span>
                                        </div>
                                        <div class="card-body" style="background-color: #fff !important;">
                                            <div class="row">
                                                <div class="col">
                                                    <a href="#" class="text-sm text-decoration-none"  style="color: #000;">
                                                      <b>Mex:</b>  <span class="text-muted">{{ phone }}</span>
                                                    </a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" class="text-sm text-decoration-none" style="color: #000;">
                                                       <b>Usa:</b> <span class="text-muted">{{ cellphone }}</span>
                                                    </a>  
                                                </div>
                                            </div>
                                            <hr>
                                            <div style="text-align: end; font-size: 30px;">
                                                <i class="fab fa-facebook mr-3" style="color: #0078B3;"></i>
                                                <i class="fab fa-instagram mr-3" style="color: #0078B3;"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="text-center" v-else>
            <div class="spinner-border text-primary my-5" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</template>


<script>

    export default {
        props: ['currency','condo','lang'],
        data() {
            return {
                loader: false,
                name:'',
                mail:'',
                phone:'',
                comments:''
            }
        },
        mounted() {
        },
        methods: {
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },selectCondo(condo) {
                this.loader = true;
                localStorage.setItem('selectedCondo', JSON.stringify(condo));
               window.location.href = "/client/information/condo/"+condo.id;
            }
        }
    }

</script>
<style type="text/css">
    .swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic{
       width: 15px;                                                                                        
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main{
        border: 2px solid rgb(98, 37, 107);
        height: 14px;
        width: 14px;
        margin-left: -1px;
        opacity: 1;
        transform: scale(1) !important;
        background: rgb(255, 255, 255) !important;
    }
    /*.swiper-pagination-bullet*/
    .swiper-pagination-bullet.swiper-pagination-bullet-active-prev, .swiper-pagination-bullet.swiper-pagination-bullet-active-next, .swiper-pagination-bullet.swiper-pagination-bullet-active-next-next{
        border: 2px solid rgb(98, 37, 107);
        height: 14px;
        width: 14px;
        margin-left: -1px;
        opacity: 1;
        transform: scale(.8) !important;
        background: rgb(255, 255, 255) !important;                                                                                                                              
    }

    .btn-outline-primary{
        background-color: #F02480 !important ;
        border-color: #F02480 !important ;
        color: #fff !important ;
    }
</style>