<template>
  <li class="nav-item dropdown">
    <a id="cartDropdown" class="nav-link dropdown-toggle u-header__nav-link" href="#" role="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img width="30px;" src="/images/common/carrito.png">        
        <span class="position-absolute text-danger font-weight-bold" style="top: 0px; right: 0px; padding: 0.15rem 0.25rem; font-size: 0.70rem;" v-if="cart_ !== null">
            {{ cart_.totalItems }}
        </span>
    </a>

    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="cartDropdown">
      <div class="p-3 cart-container">
        <div v-if="cart_ !== null && cart_.items.length > 0">
          <div class="row mb-2" v-for="(item, index) in cart_.items" :key="index">
            <div class="col-3">
              <img :src="item.info.main_picture" :alt="item.info.name" class="img-fluid">
            </div>
            <div class="col-9 position-relative">
              <div class="d-flex justify-content-between">
                <h6 class="text-muted">{{ item.info.name }}</h6>
                <i class="fas fa-spin fa-spinner" v-if="deleting === item.itemId"></i>
                <button v-else type="button" class="close" aria-label="Close" @click="remove(item.itemId)">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div><span class="h5">{{ item.price | currency }}</span> {{ item.currency }}</div>
            </div>
          </div>
          <div>
            <a href="/cart" class="btn btn-success btn-block">
              {{ __('Pay') }}
            </a>
          </div>
        </div>
        <div v-else>
          {{ __('Select a hotel or a tour') }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import EventBus from '../event-bus';

export default {
  props: {
    cart: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      cart_: null,
      deleting: null
    };
  },
  mounted() {
    this.cart_ = this.cart;
    EventBus.$on('cart-changed', (cart) => {
      this.cart_ = cart;
    });
  },
  methods: {
    async remove(id) {
      this.deleting = id;
      let { data } = await axios.delete(`/cart-item/${id}`);
      this.deleting = null;
      EventBus.$emit('cart-changed', data);
    }
  }
}
</script>

<style scoped>
.cart-container {
  min-width: 100%;
}

@media screen and (min-width: 769px) {
  .cart-container {
    min-width: 450px;
  }
}
</style>