<template>
  <div class="mt-3">
    <div v-for="m of memberships" :key="m.id">
      <div class="p-3 rounded d-flex justify-content-between" 
        :style="{
          background: m.bg_color,
        }"
      >
        <div>
          <span class="d-inline-block mr-2 border-right pl-2"><img :src="m.svgIcon" alt="Icon" style="width: 36px;" v-if="m.svgIcon"> </span>
          <span style="font-size: 24px; font-weight: bold; color: white">{{ m.name }} / </span>
          <span class="text-white font-weight-bold">{{ m.price }} {{ currency.code }} x {{ __(m.periodicity) }}</span>
        </div>

        <div class="text-center">
          <button type="button"
            :style="{
              'background-color': m.bg_color,
              'border-color': 'white',
              'color': 'white' 
            }"  
            class="btn  btn-block"
            @click="membership_ = m.id"  
          >
              ¡{{ __('I want to be a traveler') }} {{ m.name }}!
          </button>
        </div>
      </div>
      <div v-if="m.trial_months > 0" class="px-2 pt-2">
        <i class="fa fa-ticket-alt mr-3" :style="{color: m.bg_color}"></i>$ {{ currency.code === 'MXN' ? m.registration_priceopenpay : m.registration_pricestripe }} {{ currency.code }} {{ __('registration fee') }}
      </div>
      <div v-if="m.all_benefits.length > 0" class="p-3">
        <div v-for="(benefit, index) in m.all_benefits" :key="`benefit_${index}`">
          <i :style="{color: m.bg_color}" class="fas fa-check-circle"></i> <span class="d-inline-block ml-3">{{ benefit }}</span>
        </div>
      </div>
    </div>

    <div v-if="membership_ !== null">
      <modal @close="membership_ = null">
        <template #modal-body>
          <pay-membership-form
            :memberships="memberships"
            :years="years"
            now="now"
            :currency="currency"
            :membership="membership_"
            :show-coupon="showCoupon"
            :url="url"
            :current-membership="currentMembership"
          ></pay-membership-form>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    memberships: {
      type: Array,
      required: true
    },
    now: {
      tpye: String,
      required: true,
    },
    years: {
      type: Array,
      required: true
    },
    currency: {
      type: Object,
      required: true
    },
    membership: {
      type: Number,
      default: null,
    },
    showCoupon: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true
    },
    currentMembership: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      membership_: null,
    }
  },
  mounted() {
    if (this.membership) {
      this.membership_ = this.membership;
    }
  },
  watch: {
    membership(newMembership) {
      this.membership_ = newMembership;
    }
  }
}
</script>