<template>
  <div class="py-5 px-md-5" style="box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25); margin-top: 50px;">
        <div style="padding-left: 15px;padding-right: 15px;">
          <img src="/images/common/logo.png" style="width: 40%;" />
          <svg style="float: right; width: 10%; height:10%" class="primary" viewBox="0 0 83 83" fill="#90ad32" xmlns="https://www.w3.org/2000/svg">
          <path d="M41.5631 17.8887C29.8156 17.8887 20.218 27.4863 20.218 39.2337C20.218 45.3762 22.9053 51.2883 27.4354 55.2809C30.8138 58.6593 30.5066 65.8767 30.4299 65.9534C30.4299 66.3373 30.5066 66.6445 30.8138 66.9516C31.0441 67.1819 31.428 67.3355 31.7351 67.3355H51.3142C51.6981 67.3355 52.0052 67.1819 52.2356 66.9516C52.4659 66.7212 52.6195 66.3373 52.6195 65.9534C52.6195 65.8767 52.2356 58.6593 55.6139 55.2809C55.6907 55.2041 55.7675 55.1273 55.8443 55.0506C60.2976 50.9812 62.9081 45.2226 62.9081 39.2337C62.9081 27.4863 53.3105 17.8887 41.5631 17.8887ZM53.848 53.2846C53.7712 53.3614 53.6176 53.5149 53.6176 53.5917C50.6232 56.8165 50.0857 62.1144 50.0089 64.6482H33.0404C32.9636 62.1144 32.4262 56.5862 29.2014 53.2846C25.132 49.7527 22.7518 44.6084 22.7518 39.1569C22.7518 28.7915 31.1209 20.4224 41.4863 20.4224C51.8517 20.4224 60.2208 28.7915 60.2208 39.1569C60.2208 44.6084 57.9174 49.7527 53.848 53.2846Z" fill="#90ad32"/>
          <path d="M41.4867 23.3398C40.7956 23.3398 40.1814 23.9541 40.1814 24.6451C40.1814 25.3361 40.7956 25.9504 41.4867 25.9504C49.2415 25.9504 55.4608 32.2464 55.4608 39.9245C55.4608 40.6155 56.075 41.2298 56.766 41.2298C57.4571 41.2298 58.0713 40.6155 58.0713 39.9245C58.1481 30.7876 50.7004 23.3398 41.4867 23.3398Z" fill="#90ad32"/>
          <path d="M50.0094 68.7949H32.9641C31.1981 68.7949 29.7393 70.2538 29.7393 72.0197C29.7393 73.7857 31.1981 75.2445 32.9641 75.2445H49.9326C51.7753 75.1677 53.2342 73.7857 53.2342 72.0197C53.2342 70.2538 51.7753 68.7949 50.0094 68.7949ZM50.0094 72.5572H32.9641C32.6569 72.5572 32.3498 72.3268 32.3498 71.9429C32.3498 71.559 32.5801 71.3287 32.9641 71.3287H49.9326C50.2397 71.3287 50.5468 71.559 50.5468 71.9429C50.5468 72.3268 50.3165 72.5572 50.0094 72.5572Z" fill="#90ad32"/>
          <path d="M47.4753 76.5488H35.4975C33.7315 76.5488 32.2727 78.0077 32.2727 79.7736C32.2727 81.5396 33.7315 82.9984 35.4975 82.9984H47.4753C49.2412 82.9984 50.7001 81.5396 50.7001 79.7736C50.7001 77.9309 49.2412 76.5488 47.4753 76.5488ZM47.4753 80.3111H35.4975C35.1904 80.3111 34.8833 80.0807 34.8833 79.6968C34.8833 79.3129 35.1136 79.0826 35.4975 79.0826H47.4753C47.7824 79.0826 48.0895 79.3129 48.0895 79.6968C48.0895 80.0807 47.7824 80.3111 47.4753 80.3111Z" fill="#90ad32"/>
          <path d="M41.4867 11.5171C42.2545 11.5171 42.792 10.9029 42.792 10.2118V1.30527C42.792 0.614246 42.1777 0 41.4867 0C40.7956 0 40.1814 0.614246 40.1814 1.30527V10.2118C40.1814 10.9029 40.7956 11.5171 41.4867 11.5171Z" fill="#90ad32"/>
          <path d="M63.2154 6.60247C62.6011 6.14179 61.8333 6.37213 61.4494 6.9096L56.5354 14.2805C56.0748 14.8948 56.2283 15.7394 56.8426 16.1233C57.0729 16.2768 57.3033 16.3536 57.5336 16.3536C57.9943 16.3536 58.3782 16.1233 58.6085 15.7394L63.5225 8.36843C63.9832 7.83097 63.8296 6.98638 63.2154 6.60247Z" fill="#90ad32"/>
          <path d="M25.9 16.0476C26.1303 16.0476 26.3607 15.9708 26.591 15.8173C27.2053 15.4334 27.3588 14.5888 26.9749 13.9745L22.2145 6.5268C21.8306 5.91255 20.986 5.75899 20.3718 6.1429C19.7575 6.5268 19.604 7.37139 19.9879 7.98563L24.7483 15.4334C25.0554 15.894 25.4393 16.0476 25.9 16.0476Z" fill="#90ad32"/>
          <path d="M16.6096 24.1885L8.85471 19.9655C8.24046 19.6584 7.39587 19.8887 7.08875 20.503C6.70484 21.1172 6.93519 21.9618 7.62621 22.2689L15.3811 26.4919C15.6114 26.5687 15.765 26.6455 15.9953 26.6455C16.456 26.6455 16.9167 26.4151 17.147 25.9544C17.4542 25.3402 17.2238 24.4956 16.6096 24.1885Z" fill="#90ad32"/>
          <path d="M75.9609 20.503C75.6538 19.8887 74.8092 19.6584 74.195 19.9655L66.3633 24.1885C65.7491 24.4956 65.5188 25.3402 65.8259 25.9544C66.0562 26.4151 66.5169 26.6455 66.9776 26.6455C67.2079 26.6455 67.4383 26.5687 67.5918 26.4919L75.4235 22.2689C76.0377 21.9618 76.2681 21.1172 75.9609 20.503Z" fill="#90ad32"/>
          </svg>
          <svg style="float: right; width: 10%; height:10%" viewBox="0 0 83 83" fill="#90ad32" xmlns="https://www.w3.org/2000/svg">
          <path d="M67.8643 19.3504C61.9817 13.468 54.4793 9.79526 46.3632 8.74447V4.86326C46.3632 2.18166 44.1815 0 41.4999 0C38.8183 0 36.6367 2.18166 36.6367 4.86326V8.74625C31.0945 9.46991 25.7913 11.4274 21.0836 14.5146C20.335 15.0054 20.1261 16.0105 20.6171 16.7591C21.108 17.5077 22.1129 17.7165 22.8616 17.2257C27.5292 14.1647 32.8389 12.3198 38.374 11.8143C38.3999 11.8125 38.4259 11.8106 38.4515 11.8075C39.4611 11.7177 40.478 11.6718 41.4999 11.6718C60.2711 11.6718 75.5428 26.9434 75.5428 45.7147C75.5428 64.4859 60.2711 79.7575 41.4999 79.7575C22.7287 79.7575 7.4571 64.4859 7.4571 45.7147C7.4571 39.0625 9.37776 32.6172 13.0116 27.0752C13.5025 26.3265 13.2935 25.3215 12.5449 24.8307C11.7961 24.34 10.7914 24.5488 10.3003 25.2974C6.31926 31.369 4.21509 38.4292 4.21509 45.7147C4.21509 55.6738 8.09338 65.0369 15.1355 72.0792C22.1777 79.1214 31.5408 82.9997 41.4999 82.9997C51.4591 82.9997 60.8222 79.1214 67.8643 72.0792C74.9066 65.0371 78.7849 55.6738 78.7849 45.7147C78.7849 35.7555 74.9066 26.3928 67.8643 19.3504ZM39.8787 8.46629V4.86326C39.8787 3.96939 40.6059 3.24217 41.4998 3.24217C42.3936 3.24217 43.1209 3.96939 43.1209 4.86326V8.46661C42.5823 8.44376 42.042 8.42998 41.4998 8.42998C40.958 8.42998 40.4177 8.44311 39.8787 8.46629Z" fill="#90ad32"/>
          <path d="M41.4999 76.5154C58.4835 76.5154 72.3005 62.6984 72.3005 45.7147C72.3005 28.7311 58.4835 14.9141 41.4999 14.9141C24.5164 14.9141 10.6992 28.7312 10.6992 45.7147C10.6992 62.6982 24.5164 76.5154 41.4999 76.5154ZM41.4999 18.1561C56.6958 18.1561 69.0584 30.5188 69.0584 45.7146C69.0584 60.9103 56.6956 73.2731 41.4999 73.2731C26.3041 73.2731 13.9414 60.9103 13.9414 45.7146C13.9416 30.5188 26.3043 18.1561 41.4999 18.1561Z" fill="#90ad32"/>
          <path d="M22.2996 47.3354H35.8667L37.2825 49.4747C37.4027 49.6563 37.5581 49.8118 37.7397 49.9319L39.879 51.3479V64.915C39.879 65.8103 40.6048 66.536 41.5001 66.536C42.3953 66.536 43.1212 65.8103 43.1212 64.915V53.4936L54.6896 61.1503C54.9631 61.3312 55.2742 61.4196 55.584 61.4196C56.0024 61.4196 56.4175 61.2579 56.7307 60.9448C57.2756 60.3997 57.3617 59.5464 56.9363 58.9038L49.2796 47.3354H60.7009C61.5961 47.3354 62.322 46.6096 62.322 45.7143C62.322 44.819 61.5961 44.0932 60.7009 44.0932H47.1337L45.7179 41.9539C45.5978 41.7723 45.4423 41.6168 45.2609 41.4967L43.1215 40.0807V26.5137C43.1215 25.6183 42.3956 24.8926 41.5005 24.8926C40.6051 24.8926 39.8794 25.6183 39.8794 26.5137V37.9349L28.311 30.2782C27.6682 29.8528 26.8149 29.9387 26.2698 30.4837C25.7248 31.0287 25.6389 31.8821 26.0643 32.5247L33.721 44.0932H22.2998C21.4045 44.0932 20.6787 44.819 20.6787 45.7143C20.6787 46.6096 21.4043 47.3354 22.2996 47.3354ZM43.1961 44.0183L49.8353 54.0495L39.8041 47.4103L33.165 37.3792L43.1961 44.0183Z" fill="#90ad32"/>
          <path d="M16.2821 22.1175C16.6712 22.1175 17.0615 21.9783 17.3721 21.6959L17.3729 21.6951C18.0353 21.0928 18.0838 20.068 17.4816 19.4054C16.8796 18.7431 15.8537 18.6944 15.1913 19.2968C14.5289 19.8991 14.4799 20.9244 15.0822 21.5868C15.4022 21.9389 15.8413 22.1175 16.2821 22.1175Z" fill="#90ad32"/>
          </svg>
          <svg style="float: right; width: 10%; height:10%" viewBox="0 0 83 83" fill="#90ad32" xmlns="https://www.w3.org/2000/svg">
          <path d="M25.5503 35.4818C16.8727 42.2844 13.4645 53.842 17.0628 64.2645C20.6611 74.687 30.4738 81.6803 41.4999 81.6803C52.5261 81.6803 62.3387 74.687 65.937 64.2645C69.5353 53.842 66.1272 42.2844 57.4495 35.4818L67.6544 18.4736C68.4546 17.1492 68.4329 15.4853 67.5986 14.1821L58.9762 0.629984C58.7162 0.248422 58.2893 0.0142603 57.8278 0H25.172C24.7128 0.015749 24.2886 0.249817 24.0305 0.629984L15.408 14.1794C14.5737 15.4826 14.5521 17.1465 15.3522 18.4709L25.5503 35.4818ZM64.6311 55.7869C64.6311 68.5619 54.2749 78.918 41.4999 78.918C28.7249 78.918 18.3688 68.5619 18.3688 55.7869C18.3688 43.0119 28.7249 32.6557 41.4999 32.6557C54.269 32.67 64.6168 43.0178 64.6311 55.7869ZM47.0636 16.3279H36.524L27.6797 2.72131H55.399L47.0636 16.3279ZM45.3968 19.0492L41.9489 24.6755L38.2928 19.0492H45.3968ZM65.3209 17.0694L55.2167 33.913C51.2284 31.396 46.6237 30.0265 41.9081 29.9548L57.8509 3.92957L65.3019 15.6394C65.5784 16.074 65.5858 16.6276 65.3209 17.0694ZM17.698 15.6407L25.1816 3.87651L40.3747 27.2471L38.6235 30.1045C34.7683 30.5379 31.0618 31.8406 27.7831 33.9143L17.6789 17.0708C17.4168 16.6306 17.4242 16.0805 17.698 15.6475V15.6407Z" fill="#90ad32"/>
          <path d="M41.4999 73.475C31.7355 73.4637 23.8226 65.5509 23.8114 55.7864C23.8114 55.035 23.2022 54.4258 22.4507 54.4258C21.6993 54.4258 21.0901 55.035 21.0901 55.7864C21.1028 67.0532 30.2332 76.1835 41.4999 76.1963C42.2514 76.1963 42.8606 75.5871 42.8606 74.8356C42.8606 74.0841 42.2514 73.475 41.4999 73.475Z" fill="#90ad32"/>
          <path d="M40.1392 36.7376C40.1392 37.4891 40.7483 38.0983 41.4998 38.0983C51.2643 38.1095 59.1771 46.0223 59.1883 55.7868C59.1883 56.5383 59.7975 57.1474 60.549 57.1474C61.3005 57.1474 61.9096 56.5383 61.9096 55.7868C61.8969 44.52 52.7666 35.3897 41.4998 35.377C40.7483 35.377 40.1392 35.9861 40.1392 36.7376Z" fill="#90ad32"/>
          <path d="M51.8667 49.842L46.0798 48.9603L43.4946 43.4551C43.1355 42.6787 42.358 42.1816 41.5026 42.1816C40.6472 42.1816 39.8697 42.6787 39.5106 43.4551L36.9254 48.9603L31.1385 49.842C30.3131 49.9658 29.6283 50.5451 29.3696 51.3387C29.0985 52.1402 29.3011 53.026 29.8935 53.6301L34.1251 57.9583L33.1264 64.0731C32.9818 64.9224 33.3387 65.7792 34.0435 66.2746C34.7262 66.7576 35.6253 66.8068 36.3566 66.4012L41.4999 63.5628L46.6404 66.4012C47.3714 66.8084 48.2714 66.7592 48.9536 66.2746C49.6584 65.7792 50.0153 64.9224 49.8706 64.0731L48.8719 57.9583L53.1036 53.6301C53.696 53.026 53.8985 52.1402 53.6274 51.3387C53.37 50.5475 52.6891 49.9686 51.8667 49.842ZM46.7615 56.2235C46.2598 56.7362 46.0324 57.4573 46.1492 58.1651L47.0228 63.5016L42.553 61.0361C41.8973 60.6734 41.1011 60.6734 40.4454 61.0361L35.977 63.5003L36.8492 58.1665C36.9675 57.4593 36.7416 56.7381 36.2409 56.2248L32.4896 52.3864L37.6084 51.6068C38.3353 51.4929 38.9577 51.0246 39.2684 50.3577L41.4999 45.6144L43.7314 50.359C44.0424 51.0268 44.6661 51.4953 45.3941 51.6081L50.5101 52.3878L46.7615 56.2235Z" fill="#90ad32"/>
          </svg>
          <a href="/" style="margin-right: -32% !important; margin-top: -8%; cursor:pointer;" class="close">
            <span aria-hidden="true">&times;</span>
          </a>
          <img style="margin-top:12%" width="100%" src="/images/common/line1.png" />
        </div>
        <div style="padding-top: 30px; padding-left: 15px;padding-right: 15px;">

        <div v-if="type == 'membership'">
          <p>{{ __('Congratulationsandwelcome') }}</p><br>
          <p style="text-align: justify;">
            {{ __('tusubs') }} {{membership}} 
            {{ __('hasidoregistrada') }}
             <a href="/termsAndConditions">{{ __('Terms and conditions') }}</a> 
            <br>
            {{ __('gracias') }}
          </p>
          <div style="margin-top:15%;"> 
            <p>{{ __('atenta') }}</p>
            <p style="color:#703869;"><b>{{ __('team') }}</b></p>
          </div>
        </div>

        <div v-if="type == 'cuenta'">
          <p style="text-align: justify;">
            {{ __('hello') }} {{name}}<br>
            {{ __('felicidades') }} <br><br>
            {{ __('texto32') }} <br>
            {{ __('texto33') }} <br>
            {{ __('texto34') }} <br>
            {{ __('texto35') }} <br>
            {{ __('texto36') }} <br>

          </p>

          <div style="margin-top:15%;"> 
            <p>{{ __('atenta') }}</p>
            <p style="color:#703869;">
              <b>
                {{ __('team') }}
              </b>
            </p>
          </div>
        </div>

        <div v-if="type == 'solicitud'">
          <p>{{ __('Dear') }} {{name}}</p><br>
          <p style="text-align: justify;">
            {{ __('graciassolicitud') }}
            {{numtran}}
            {{ __('leinformamos') }} 
          </p>
          <div style="margin-top:15%;"> 
            <p>{{ __('atenta') }}</p>
            <p style="color:#703869;"><b>
            {{ __('team') }}</b></p>
          </div>
        </div>

        <div v-if="type == 'prueba'">
          <p>{{ __('hola') }} {{name}}</p><br>
          <p style="text-align: justify;">
            {{ __('felicidades') }}<br><br>
            {{ __('hasregistrado') }}<br><br> 
            {{ __('texto16') }}<br><br>
            {{ __('texto17') }}<br><br> 
            {{ __('texto18') }}<br><br>
            {{ __('nota') }}<br><br>
            {{ __('texto19') }}
          </p>
          <div style="margin-top:15%;"> 
            <p>{{ __('atenta') }}</p>
            <p style="color:#703869;"><b>
            {{ __('team') }}</b></p>
          </div>
        </div>
        </div>
        <div style="padding-left: 15px;padding-right: 15px;">
        <img style="margin-top:4%" width="100%" src="/images/common/line1.png" />
        </div>
        <div style="padding-top: 30px; padding-left: 15px; padding-right: 15px;">
          <div class="row">
            <div class="col-md-4 mb-5 mt-md-0 mb-md-0">
              <center>
              <img width="30%" src="/images/common/phone.png" /><br>
              +52 8005238381<br>
              +52 8126031751
              </center>  
            </div>
            <div class="col-md-4 mb-5 mt-md-0 mb-md-0">
              <center>
              <img width="30%" src="/images/common/whatsapp.png" /><br>
              8005238381<br>
              8126031751
              </center>  
            </div>
            <div class="col-md-4 mb-5 mt-md-0 mb-md-0">
              <center>
              <img width="30%" src="/images/common/mail.png" /><br>
              admin@mail.com
              </center>  
            </div>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  props: ['type', 'membership', 'name','numtran','lang'],
  data() {
    return {
      client: {  
        name: ''
      }
    }
  },
  mounted() {
  },
  computed: {

  },
  methods: {
   
  }
}
</script>