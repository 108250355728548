<template>
  <div class="rounded p-3" :class="bg">
    <slot>
      <h3 class="text-primary f-24">Tours</h3>
    </slot>
    <form action="/list/tours" method="GET" ref="toursAvailabilityForm">
      <div class="row">
        <div class="col-md-2">
          <div class="mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                  <span class="input-group-text bg-white px-1">
                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                    <svg  v-else
                      class="bi bi-geo"
                      width="0.5rem"
                      height="0.5rem"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      xmlns="https://www.w3.org/2000/svg">
                      <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD"/>
                    </svg>
                  </span>
                </div>
               <select name="country" id="country" class="form-control" v-model="countrySearch">
                <option value="">{{ __('Select an option') }}</option>
                <option v-for="co in countrys" :key="co.id" :value="co.country_name">{{ co.country_name }}</option>
              </select>
            </div>
           
          </div>
        </div>
        <input type="hidden" name="typeCity" :value="this.type" />
        <input type="hidden" name="idCity" :value="this.idcity" />
        <input type="hidden" name="cityname" :value="this.cityname" />

        <div class="col-md-2">
          <div class="mb-3">
            <i v-if="loadert" class="fas fa-spin fa-spinner"></i>
            <div class="input-group">
              <div class="input-group-prepend">
                  <span class="input-group-text bg-white px-1">
                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                    <svg  v-else
                      class="bi bi-geo"
                      width="0.5rem"
                      height="0.5rem"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      xmlns="https://www.w3.org/2000/svg">
                      <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD"/>
                    </svg>
                  </span>
                </div>
               <select v-if="!loadert" name="destination" id="destination" class="form-control" v-model="citySearch">
                <option value="">{{ __('Select an option') }}</option>
                <option v-for="c in cityOptions" :key="c.id" :value="c">{{ c.city_name }}</option>
              </select>
            </div>
            
          </div>
        </div>
        <div class="col-md-2">
          <div class="mb-3">
            <div class="input-group input-group form">
              <date-picker v-model="selectedDate" 
                type="date" 
                :disabled-date="notBeforeToday"
                :input-attr="{name: 'date', placeholder: 'Date'}"
                input-class="form-control"  
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="mb-3">
            <div class="input-group input-group form">
              <select name="type" id="type" class="form-control" v-model="typeSearch">
                <option value="">{{ __('Select an option') }}</option>
                <option v-for="t in typeTourOptionsTours" :key="t.id" :value="t.id">{{ __(t.type)}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <a
            type="btn"
            styñe="color:#fff;"
            id="searchButton"
            class="btn btn-block btn-primary white-a"
            @click="checkForm"
            :disabled='isDisabled'
          >{{ __('Search for the best offer') }} <i v-if="loader" class="fas fa-spin fa-spinner"></i></a>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import debounce from 'lodash/debounce';
import DatePicker from 'vue2-datepicker';
import isBefore from 'date-fns/isBefore';
import parse from 'date-fns/parse';

const API_URL = "/api/citiesTour";
const API_URL_B = "/api/getcodeb";
const today = new Date();

export default {
  props: {
    months: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    },
    bg: {
      type: String,
      required: false,
      default: "bg-white"
    },
    type: {
      type: String,
      default: ""
    },
    countrys: {
      type: Array,
      default: function () {
        return [];
      }
    },
    lang: {
      type: String,
      default: ""
    },
    typeTourOptions: {
      type: Array,
      default: function () {
        return [];
      }
    },
    date: {
      type: String,
      default: ""
    },
    datef: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      citySearch: "",
      countrySearch:"",
      selectedDate: null,
      typeSearch:"",
      selectedDateF: null,
      errors: [],
      cityOptions: [],
      loader: false,
      loaderb:false,
      loadert: false,
      loading: false,
      country:"",
      type:"",
      idcity:"",
      cityname:"",
      typeTourOptionsTours:[],
      numperson:[
       {'id': 1, 'type': '1 Pax'}
      ,{'id': 2, 'type': '2 Pax'}
      ,{'id': 3, 'type': '3 Pax'}
      ,{'id': 4, 'type': '4 Pax'}
      ,{'id': 5, 'type': '5 Pax'}
      ,{'id': 6, 'type': '6 Pax'}
      ,{'id': 7, 'type': '7 Pax'}
      ,{'id': 8, 'type': '8 Pax'}
      ,{'id': 9, 'type': '9 Pax'}
      ,{'id': 10, 'type': '10 Pax'}]
    };
  },
  components: {
    DatePicker
  },
  mounted() {
    if (this.date) {
      this.selectedDate = parse(this.date, 'yyyy-MM-dd', new Date());
    } 
     if (this.datef) {
      this.selectedDateF = parse(this.datef, 'yyyy-MM-dd', new Date());
     }

    this.countrySearch = this.country;
    this.typeSearch = this.type;
    // this.$refs.typeahead.$data.inputValue = this.city;
  },
  computed: {
    isDisabled: function(){
      return !this.loaderb;
    }
  },
  methods: {
    notBeforeToday(date) {
      return date < today;
    },
    notBeforeStartDate(date) {
      return this.selectedDate === null ? date < today : isBefore(date, this.selectedDate);
    },
    async getCities(query) {
      this.loader = true;
      this.loadert = true;
      this.loading = true;
      const res = await axios.get(API_URL, {
        params: {
          query: encodeURIComponent(query)
        }
      });
      var suggestions = res.data;
      this.cityOptions = suggestions;
      this.loading = false;
      this.loader = false;
      this.loadert = false;
      this.loaderb = true;
    },
    async checkForm(e){
      this.loader = true;
      if(this.type == "booking"){
          if(!this.typeSearch){
            this.typeSearch = 2;
          }
          const res = await axios.get(API_URL_B, {
            params: {
              date:this.selectedDate,
              idCity: this.citySearch.id,
              type: this.typeSearch,
              lang: this.lang
            }
          });
          this.loader = false;
          var url = "https://booking.astutotravel.com/"+this.lang+"/microsite/astutotravel/list-activity/"+res.data;
          var win = window.open(url, '_blank');
         // Cambiar el foco al nuevo tab (punto opcional)
          win.focus();
      }else{
        if (this.citySearch && this.selectedDate ) {
          if(this.type == "propio"){
            // $("#cover-spin").show(0);
            this.$refs['toursAvailabilityForm'].submit();
          }
        }
      }
    }
  },
  watch: {
    countrySearch: debounce(function(country) {
        this.getCities(country);
    }, 200),
    citySearch: debounce(function() {
      if(this.citySearch.type == "booking"){
        this.typeTourOptionsTours = this.numperson;
        this.type = "booking";
      }else{
        this.typeTourOptionsTours = this.typeTourOptions;
        this.type = "propio";
      }

      this.idcity = this.citySearch.id;
      this.cityname = this.citySearch.city_name;
    }, 200),
  }
};
</script>

<style scoped>
.white-translucent {
  background: rgba(255, 255, 255, 0.8);
}
.white-a {
  color: #fff;
}
</style>