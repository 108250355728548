<template>
  <form :action="`/car-rentals/${category.id}/checkout`" method="POST" id="payment-form" ref="paymentForm" @submit.prevent="makeToken">
    <csrf-token></csrf-token>
    <input type="hidden" name="date" :value="date" />
    <input type="hidden" name="zone" :value="zone" />
    <input type="hidden" name="end_date" :value="endDate" />
    <input type="hidden" name="total" :value="category.price.price" />
    <input type="hidden" name="token" :value="token" />
    <input type="hidden" name="device_data_id" :value="deviceDataId" />
    <div class="row">
      <div class="col-md-8">
        <div class="card border-0 mb-3">
          <div class="row no-gutters">
            <div class="col-md-4">
              <img :src="category.image" class="card-image img-fluid" alt="">
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">{{ category.name }}</h5>
                <div class="card-text">
                  {{ __('Up to :people people', {'people': category.max_pax}) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-3 bg-gray-200 mb-3 rounded border">
          <h5>{{ __('Holder') }}</h5>
          <input type="hidden" name="price" :value="category.price.price">
          <div class="form-group row mb-3">
            <div class="col-form-label col-md-3">{{ __('Name') }}</div>
            <div class="col-md-9">
              <input type="text" class="form-control" v-model="name" name="name">
            </div>
          </div>
          
          <div class="form-group row mb-3">
            <div class="col-form-label col-md-3">{{ __('Last Name') }}</div>
            <div class="col-md-9">
              <input type="text" class="form-control" v-model="last_name" name="last_name">
            </div>
          </div>
  
          <div class="form-group row mb-3">
            <div class="col-form-label col-md-3">{{ __('Email') }}</div>
            <div class="col-md-9">
              <input type="text" class="form-control" v-model="email" name="email">
            </div>
          </div>
  
          <div class="form-group row mb-3">
            <div class="col-form-label col-md-3">{{ __('Phone number') }}</div>
            <div class="col-md-9">
              <input type="text" class="form-control" v-model="phone_number" name="phone_number">
            </div>
          </div>
        </div>
      </div>
  
      <div class="col-md-4 border-left">
        <h5 class="text-info">{{ __('Payment info') }}</h5>
        <p class="h6 mb-3">{{ __('Choose the payment method') }}</p>
        <div class="list-group mb-3">
          <input type="radio" name="method" v-model="method" value="card" id="card" />
          <label class="list-group-item p-2" for="card"><i class="fas fa-credit-card"></i> {{ __('Credit or debit card') }}</label>
          
          <input type="radio" name="method" v-model="method" id="store" value="store" />
          <label class="list-group-item p-2" for="store"><i class="fas fa-money-bill-wave"></i> {{ __('Payment in stores') }}</label>
          
          <input type="radio" name="method" v-model="method" id="bank_account" value="bank_account" />
          <label class="list-group-item p-2" for="bank_account"><i class="fas fa-money-check"></i> {{ __('Transfer (SPEI)') }}</label>
        </div>
        <card-component v-if="method == 'card'"
          #default="{holderName, cardNumber, cvv2, expirationYear, expirationMonth, states, handlers, validators, monthOptions, yearOptions}"
          :years="years"
          :now="now"
        >
          <div>
            <!-- Card holder -->
            <div class="form-group">
              <label for="holder_name" class="form-label">{{ __('Cardholder') }}</label>
              <input type="text" 
                :placeholder="__('Cardholder')"
                id="holder_name"
                class="form-control"
                data-openpay-card="holder_name"
              >
            </div>
  
            <!-- Card number -->
            <div class="form-group">
              <label for="card_number" class="form-label">{{ __('Card number') }}</label>
              <input type="text" 
                placeholder="XXXXXXXXXXXXXXXX"
                id="card_number" 
                class="form-control" 
                @input="handlers.cardNumber"
                @blur="validators.cardNumber" 
                :value="cardNumber"
                :class="{'is-invalid': !states.cardNumber}" 
                data-openpay-card="card_number"
              >
              <div v-if="!states.cardNumber" class="text-danger">
                {{ __('Invalid card number') }}
              </div>
            </div>
  
            <div class="row">
              <!-- Expiratino month -->
              <div class="form-group col-md-6">
                <label for="expiration_month" class="form-label">{{ __('Month') }}</label>
                <select :value="expirationMonth" 
                  @change="handlers.expirationMonth" 
                  @blur="validators.expirationDate" 
                  class="form-control"
                  :class="{'is-invalid': !states.expirationDate}" 
                  data-openpay-card="expiration_month"
                >
                  <option v-for="option in monthOptions" :key="option.value" v-bind:value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
  
              <!-- Expiration year -->
              <div class="form-group col-md-6">
                <label for="expiration_year" class="form-label">{{ __('Year') }}</label>
                <select :value="expirationYear" 
                  @change="handlers.expirationYear" 
                  @blur="validators.expirationDate" 
                  class="form-control"
                  :class="{'is-invalid': !states.expirationDate}" 
                  data-openpay-card="expiration_year"
                >
                  <option v-for="option in yearOptions" :key="option.value" v-bind:value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
  
              <!-- CVC -->
              <div class="form-group col-md-6">
                <label for="cvv2" class="form-label">CVC/CVV</label>
                <input type="text" 
                  placeholder="123" 
                  id="cvv2"
                  class="form-control" 
                  @input="handlers.cvv2" 
                  @blur="validators.cvv2"
                  :value="cvv2" 
                  :class="{'is-invalid': !states.cvv2}" 
                  data-openpay-card="cvv2">
                <div v-if="!states.cvv2" class="text-danger">
                  CVV {{ __('Incorrect') }}
                </div>
              </div>
            </div>
            <button type="submit" 
              id="searchButton"
              class="btn btn-block btn-primary"
              :disabled="loader || !states.cardNumber || !states.expirationDate || !states.cvv2 || !cardNumber || !expirationMonth || !expirationYear || !cvv2"
            >
              {{ __('Pay') }} {{ category.price.price }} {{ category.price.currency_code }} <i v-if="loader" class="fas fa-spin fa-spinner"></i>
            </button>
          </div>
        </card-component>
        <div v-if="method == 'store'">
          <p class="mb-5 font-weight-bold">{{ __('With the payment option in stores, you can pay at any of the following establishments') }}:</p>
          <div class="row">
            <div class="col-lg-3 col-md-4 col-6 mb-5 mt-5">
              <img class="img-fluid" src="/images/01.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-5 mt-5">
              <img class="img-fluid"  src="/images/02.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-5 mt-5">
              <img class="img-fluid"  src="/images/03.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-5 mt-5">
              <img class="img-fluid"  src="/images/04.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-5 mt-5">
              <img class="img-fluid"  src="/images/05.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-5 mt-5">
              <img class="img-fluid"  src="/images/06.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-5 mt-5">
              <img class="img-fluid"  src="/images/07.png" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-5 mt-5">
              <img class="img-fluid"  src="/images/08.png" alt="">
            </div>
          </div>
          <button type="submit"
            id="searchButton"
            class="btn btn-block btn-primary"
            :disabled="loader"
          >
            {{ __('Get payment coupon') }} <i v-if="loader" class="fas fa-spin fa-spinner"></i>
          </button>
        </div>
  
        <div v-if="method == 'bank_account'">
          <p class="mb-5 font-weight-bold">
            {{ __('Make a reference payment from your bank and identify it in real time, without requesting a payment receipt') }}.
          </p> 
          <button type="submit"
            id="searchButton"
            class="btn btn-block btn-primary"
            :disabled="loader"
          >
            {{ __('Get reference coupon') }} <i v-if="loader" class="fas fa-spin fa-spinner"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: ['category', 'date', 'years', 'now', 'zone', 'endDate', 'user'],
  data: function () {
    return {
      name: '',
      last_name: '',
      email: '',
      phone_number: '',
      method: 'card',
      token: null,
      deviceDataId: '',
      loader: false
    }
  },
 
  mounted() {
    this.deviceDataId = OpenPay.deviceData.setup();

    if (this.user) {
      this.name = this.user.name;
      this.last_name = this.user.last_name;
      this.email = this.user.email;
      this.phone_number = this.user.phone_number;
    }
  },
  methods: {
    makeToken: function () {
      this.loader = true;
      if (this.method !== 'card') {
        this.$refs.paymentForm.submit();
        return;
      }

      const success = (response) => {
        this.errorMessage = null;
        this.token = response.data.id;
        this.$nextTick(() => {
            this.$refs.paymentForm.submit();
        });
      }

      const err = (err) => {
        console.log('error ', err);
        this.loader = false;
        this.errorMessage = this.__('Check that you have entered the card details correctly');
      }

      OpenPay.token.extractFormAndCreate(document.querySelector('#payment-form'), success, err);
    }
  },
}
</script>
