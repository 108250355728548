<template>
  <div>
    <br>
    <gmap-map
        :zoom="16"    
        :center="center"
        style="width:100%;  height: 400px;"
      >
      <gmap-marker
        :key="index"
        v-for="(m, index) in locationMarkers"
        :position="m.position"
        @click="center=m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>
 
<script>
export default {
  props: ['lat', 'long'],
  name: "AddGoogleMap",
  data() {
    return {
      center:{},
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null
    };
  },
 
  mounted() {
    console.log(this.lat);
    console.log(this.long);
    var loc = {position: {lat: this.lat, lng: this.long}};
    this.center = {lat: this.lat, lng: this.long};
    this.locationMarkers.push(loc);
    this.locateGeoLocation();
  },
  methods: {
    locateGeoLocation: function() {
      navigator.geolocation.getCurrentPosition(res => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude
        };
      });
    }
  }
};
</script>