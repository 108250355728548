// window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('@fancyapps/fancybox');
    require('bootstrap');
} catch (e) {}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });


$(function(){
    $('#register-form').submit(function() {
        $('#cover-spin').show(0)
        return true;
    });
    $('#payment-form').submit(function() {
        $('#cover-spin').show(0)
        return true;
    });
    
});

$(function () {
    var header = $(".u-header");
    var footer = $('footer');
    switch (window.location.pathname) {
        case '/':
            $(window).scroll(function () {
                var scroll = $(window).scrollTop();
                if (scroll >= 200) {
                    header.removeClass('u-header--bg-transparent').addClass("u-scrolled");
                } else {
                    header.removeClass("u-scrolled").addClass('u-header--bg-transparent');
                }
            });
            break;
        case '/login':
            header.hide();
            footer.removeClass("mt-5")
            break;
        case '/register':
            // header.hide();
            header.removeClass('u-header--sticky-top-lg').addClass('u-header__nav-item_black');
            footer.removeClass("mt-5")
            break;
        case '/password/reset':
            footer.removeClass("mt-5");
            break;
        default:
            header.removeClass('u-header--sticky-top-lg').addClass('u-header__nav-item_black');
            break;
    }

});

$(document).ready(function () {
    
    $().fancybox({
        selector : '.gallery a:visible'
    });

    $(document).on('click', '.btn-loader-indicator', function (e) {
        $(this).append('<i class="fa fa-spin fa-spinner"></i>');
    });

    /* var guidesSlider = new Swiper('.swiper-container', {
        slidesPerView: 5,
        spaceBetween: 15,
        loop: true,
        roundLengths: true,
        breakpoints: {
            1200: {
                slidesPerView: 4
            },
            991: {
                slidesPerView: 3
            },
            768: {
                slidesPerView: 2
            },
            0: {
                slidesPerView: 1
            }
        },
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
    }); */


});
