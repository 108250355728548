<template>
  <div>
    <form :action="`/membershipsNew/checkout`" method="POST" id="payment-form" ref="paymentForm">
      <csrf-token></csrf-token>
      <input type="hidden" name="payment_id" :value="payment_id">
      <input type="hidden" name="total" :value="totalToPay" />
      <input type="hidden" name="currency" :value="currencyToPay" />
      <input type="hidden" name="amount_type" :value="amount_type">
      <input type="hidden" name="id_client_new" :value="this.usernew.id">
      <input type="hidden" name="id_client" :value="this.usernew.id_client">
      <input type="hidden" name="membership_id" :value="this.usernew.membership_id">
      <input type="hidden" name="type_pay" :value="this.typepay">
      <input type="hidden" name="payment_method_id" :value="this.payment_method_id">
      <div class="row">
        <div class="col-md-12 p-3 mt-3 rounded" style="background: #EDF2F7;">
          <div>
            <div v-if="this.usernew.captured == 'web'" class="p-3 mt-3 rounded" style="background: #EDF2F7;">
              <div class="row">
                <div class="col" style="text-align: center;">
                  <input @change="typePay('twel')" type="checkbox" name="twelpay" id="twelpay" style="width: 18px;height: 18px;"><span style="color: #482448;"> <span style="font-weight: 700;"> ${{totalToPay}} USD /</span> por mes(Total ${{totalToPay*12}} al año)</span>
                </div>
                <div class="col" style="text-align: center;">
                  <input @change="typePay('one')" checked="true" type="checkbox" name="onepay" id="onepay" style="width: 18px;height: 18px;"><span style="color: #482448;"> <span style="font-weight: 700;"> ${{totalToPay * 10}} USD /</span> por año (Ahorro anual de ${{totalToPay * 2}} USD)</span>
                </div>
              </div>
            </div>
            <stripe-checkout-new :total="this.usernew.captured == 'web'? this.typepay == 'payment'? this.usernew.price * 10 : this.usernew.price :totalToPay" 
              :currency="currencyToPay"
              :type="this.usernew.captured == 'web'? this.typepay : 'payment'"
              :subscribible="this.typepay == 'twel'"
              @payment-successfully="paymentSuccessfully"
              @create-payment-method-successfully="createPaymentMethodSuccessfully"
            ></stripe-checkout-new>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>

export default {
  props: ['usernew'],
  data: function () {
    return {
      loader: false,
      amount_type: 'complete',
      payment_id: null,
      typepay:'payment',
      payment_method_id:null
    }
  },
  computed: {
    totalToPay() {
      if(this.usernew.captured == 'admin'){
        return this.usernew.firspay;
      }else{
        return this.usernew.price;
      }
    },
    currencyToPay() {
      return this.usernew.currency;
    }
  },
  mounted(){
  },
  methods: {
    typePay(type){
      if(type == 'one' && document.getElementById('twelpay').checked && document.getElementById('onepay').checked){
        document.getElementById('twelpay').checked = false;
        this.typepay = 'payment';
      }

      if(type == 'twel' && document.getElementById('onepay').checked && document.getElementById('twelpay').checked){
        document.getElementById('onepay').checked = false;
        this.typepay = 'twel';
      }
    },
    paymentSuccessfully(paymentResult) {
      this.payment_id = paymentResult.id;
      this.$nextTick(() => {
        this.$refs.paymentForm.submit();
      })
    },
    createPaymentMethodSuccessfully(paymentMethod) {
      this.payment_method_id = paymentMethod;
      this.$nextTick(() => {
        this.$refs['paymentForm'].submit();
      });
    }
  }
}
</script>