<template>
    <div class="rounded p-3 card" style="height: 110px;opacity: 0.8;border-radius: 10px !important;">
        <form action="/list/hotels" method="GET" @submit="checkForm" ref="hotelAvailabilityForm">
            <div class="row" v-show="!hasState">
                <div class="col-md-3">
                    <label style="color: #00adef; font-family: 'custom-monse';" for="">
                        {{ __('Country') }}
                    </label>
                    <div class="mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-1">
                                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                                    <svg v-else class="bi bi-geo" width="0.8rem" height="1.4rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD" />
                                    </svg>
                                </span>
                            </div>
                            <select v-model="countryLocal" @change="getStates()" class="form-control border-left-0 pl-0" name="country">
                                <option v-for="option in countries" :value="option.Country">{{ option.Country }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label style="color: #00adef; font-family: 'custom-monse';" for="">
                        {{ __('City') }}
                    </label>
                    <div class="mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-1">
                                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                                    <svg v-else class="bi bi-geo" width="0.8rem" height="1.4rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD" />
                                    </svg>
                                </span>
                            </div>
                            <select v-model="city_" @change="getMonths()" class="form-control border-left-0 pl-0" name="city">
                                <option v-for="option in cities" :value="option.City">{{ __(  option.City )}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label style="color: #00adef; font-family: 'custom-monse';" for="">
                        {{ __('Check-in') }}
                    </label>
                    <div class="mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-1" id="basic-addon1">
                                    <i v-if="loadingMonths" class="fas fa-spin fa-spinner"></i>
                                    <svg v-else class="bi bi-calendar" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M16 2H4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zM3 5.857C3 5.384 3.448 5 4 5h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H4c-.552 0-1-.384-1-.857V5.857z" clip-rule="evenodd" />
                                        <path fill-rule="evenodd" d="M8.5 9a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                            </div>
                            <select v-model="startMonth" name="startMonth" @change="selectedMonth()" class="form-control border-left-0 pl-0">
                                <option v-for="option in startMonthOptions" :value="option.MesIni+' '+option.YearIni">{{ option.stringMonth }} {{option.YearIni}}</option>
                            </select>
                        </div>
                        <div v-if="monthsLoaded && months.length === 0 && !loadingMonths">{{ __('Available dates not found') }}</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label style="color: #00adef; font-family: 'custom-monse';" for="">
                        {{ __('Check-out') }}
                    </label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-white px-1" id="basic-addon2">
                                <i v-if="loadingMonths" class="fas fa-spin fa-spinner"></i>
                                <svg v-else class="bi bi-calendar" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M16 2H4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zM3 5.857C3 5.384 3.448 5 4 5h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H4c-.552 0-1-.384-1-.857V5.857z" clip-rule="evenodd" />
                                    <path fill-rule="evenodd" d="M8.5 9a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </div>
                        <select v-model="endMonth" name="endMonth" class="form-control border-left-0 pl-0">
                            <option v-for="option in endMonthOptions" :value="option.MesIni+' '+option.YearIni">{{ option.stringMonth }} {{option.YearIni}}</option>
                        </select>
                    </div>
                    <div v-if="monthsLoaded && months.length === 0 && !loadingMonths">{{ __('Available dates not found') }}</div>
                </div>
                <div class="col-md-2">
                    <br>
                    <button type="submit" id="searchButton" style="background-color: #00adef !important; border-color: #00adef !important;width: 100%;" class="btn btn-block btn-btns" :disabled="loader">{{ __('Search') }} <i v-if="loader" class="fas fa-spin fa-spinner"></i></button>
                </div>
                <input type="hidden" name="start_date" v-if="selectedStartDateObject" :value="selectedStartDateObject.start">
                <input type="hidden" name="end_date" v-if="selectedEndDateObject" :value="selectedEndDateObject.end">
            </div>
            <div class="row" v-show="hasState">
                <div class="col-md-2">
                    <label style="color: #00adef; font-family: 'custom-monse';" for="">
                        {{ __('Country') }}
                    </label>
                    <div class="mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-1">
                                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                                    <svg v-else class="bi bi-geo" width="0.8rem" height="1.4rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD" />
                                    </svg>
                                </span>
                            </div>
                            <select v-model="countryLocal" @change="getStates()" class="form-control border-left-0 pl-0" name="country">
                                <option v-for="option in countries" :value="option.Country">{{ option.Country }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label style="color: #00adef; font-family: 'custom-monse';" for="">
                        {{ __('State') }}
                    </label>
                    <div class="mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-1">
                                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                                    <svg v-else class="bi bi-geo" width="0.8rem" height="1.4rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD" />
                                    </svg>
                                </span>
                            </div>
                            <select v-model="stateSelected_" @change="getCities()" class="form-control border-left-0 pl-0" name="state">
                                <option v-for="option in states" :value="option.Nombre">{{ option.Nombre }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label style="color: #00adef; font-family: 'custom-monse';" for="">
                        {{ __('City') }}
                    </label>
                    <div class="mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-1">
                                    <i v-if="loading" class="fas fa-spin fa-spinner"></i>
                                    <svg v-else class="bi bi-geo" width="0.8rem" height="1.4rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M8.52368 22.536C1.33445 13.0737 0 12.1025 0 8.625C0 3.86153 4.25328 0 9.5 0C14.7467 0 19 3.86153 19 8.625C19 12.1025 17.6655 13.0737 10.4763 22.536C10.0045 23.1547 8.99541 23.1547 8.52368 22.536ZM9.5 12.2188C11.6861 12.2188 13.4583 10.6098 13.4583 8.625C13.4583 6.64022 11.6861 5.03125 9.5 5.03125C7.31386 5.03125 5.54167 6.64022 5.54167 8.625C5.54167 10.6098 7.31386 12.2188 9.5 12.2188Z" fill="#ADADAD" />
                                    </svg>
                                </span>
                            </div>
                            <select v-model="city_" @change="getMonths()" class="form-control border-left-0 pl-0" name="city">
                                <option v-for="option in cities" :value="option.City">{{ __(  option.City )}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label style="color: #00adef; font-family: 'custom-monse';" for="">
                        {{ __('Check-in') }}
                    </label>
                    <div class="mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-1" id="basic-addon1">
                                    <i v-if="loadingMonths" class="fas fa-spin fa-spinner"></i>
                                    <svg v-else class="bi bi-calendar" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M16 2H4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zM3 5.857C3 5.384 3.448 5 4 5h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H4c-.552 0-1-.384-1-.857V5.857z" clip-rule="evenodd" />
                                        <path fill-rule="evenodd" d="M8.5 9a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                            </div>
                            <select v-model="startMonth" @change="selectedMonth()" class="form-control border-left-0 pl-0">
                                <option v-for="option in startMonthOptions" :value="option.MesIni+' '+option.YearIni">{{ option.stringMonth }} {{option.YearIni}}</option>
                            </select>
                        </div>
                        <div v-if="monthsLoaded && months.length === 0 && !loadingMonths">{{ __('Available dates not found') }}</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label style="color: #00adef; font-family: 'custom-monse';" for="">
                        {{ __('Check-out') }}
                    </label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-white px-1" id="basic-addon2">
                                <i v-if="loadingMonths" class="fas fa-spin fa-spinner"></i>
                                <svg v-else class="bi bi-calendar" width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M16 2H4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zM3 5.857C3 5.384 3.448 5 4 5h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H4c-.552 0-1-.384-1-.857V5.857z" clip-rule="evenodd" />
                                    <path fill-rule="evenodd" d="M8.5 9a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </div>
                        <select v-model="endMonth" class="form-control border-left-0 pl-0">
                            <option v-for="option in endMonthOptions" :value="option.MesIni+' '+option.YearIni">{{ option.stringMonth }} {{option.YearIni}}</option>
                        </select>
                    </div>
                    <div v-if="monthsLoaded && months.length === 0 && !loadingMonths">{{ __('Available dates not found') }}</div>
                </div>
                <div class="col-md-2">
                    <br>
                    <button type="submit" id="searchButton" style="background-color: #00adef !important; border-color: #00adef !important;width: 100%;" class="btn btn-block btn-btns" :disabled="loader">{{ __('Search') }} <i v-if="loader" class="fas fa-spin fa-spinner"></i></button>
                </div>
                <input type="hidden" name="start_date" v-if="selectedStartDateObject" :value="selectedStartDateObject.start">
                <input type="hidden" name="end_date" v-if="selectedEndDateObject" :value="selectedEndDateObject.end">
            </div>
            <div class="row justify-content-end">
            </div>
        </form>
    </div>
</template>
<script>
import debounce from 'lodash/debounce';
import parse from 'date-fns/parse';
import getMonth from 'date-fns/getMonth';

const API_URL = "/api/cities";
const today = new Date();

export default {
    props: {
        bg: {
            type: String,
            required: false,
            default: "bg-white"
        },
        city: {
            type: Number,
            default: 0
        },
        state: {
            type: Number,
            default: 0
        },
        country: {
            type: Number,
            default: 0
        },
        selectedStartDate: {
            type: String,
            default: ''
        },
        selectedEndDate: {
            type: String,
            default: ''
        },
        monthsAvailability: {
            type: Array,
            default: () => []
        },
        countries: {
            type: Array,
            required: true
        },
        lang: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            countryLocal: null,
            stateSelected_: null,
            city_: null,
            startMonth: null,
            endMonth: null,
            loader: false,
            loading: false,
            loadingMonths: false,
            monthsLoaded: false,
            startMonthOptions: [],
            endMonthOptions: [],
            selectedStartDateObject: '',
            selectedEndDateObject: '',
            months: [],
            states: [],
            hasState: false,
            cities: []
        };
    },
    mounted() {
        const lastCountry = window.localStorage.getItem('last_country');
        const lastCity = window.localStorage.getItem('last_city');
        const lastState = window.localStorage.getItem('last_state');
        const lastStart = window.localStorage.getItem('last_start_month');
        const lastEnd = window.localStorage.getItem('last_end_month');
    },
    computed: {},
    methods: {
        checkForm: function(e) {
            if (this.city_ !== null && this.startMonth && this.endMonth) {
                $("#cover-spin").show(0);
                this.loader = true;
                //window.localStorage.setItem('last_country', this.countryLocal);
                //window.localStorage.setItem('last_city', this.city_);
                //window.localStorage.setItem('last_start_month', this.startMonth);
                //window.localStorage.setItem('last_end_month', this.endMonth);


                if (this.stateSelected_) {
                    //window.localStorage.setItem('last_state', this.stateSelected_);
                } else {
                    //window.localStorage.removeItem('last_state');
                }
                this.$refs['hotelAvailabilityForm'].submit();
            }

            this.errors = [];

            e.preventDefault();
        },
        async getStates() {
            this.states = [];
            this.cities = [];
            this.months = [];
            this.monthsF = [];
            this.stateSelected = null;
            this.citySelected = null;
            this.startMonth = '';
            this.endMonth = '';
            if (this.countryLocal == 'USA' /*|| this.countryLocal == 'Canada'*/) {
                this.loading = true;
                let country = this.countryLocal;
                let response = await axios.get('/api/getStates', { params: { country } });
                this.states = response.data;
                this.loading = false;
            } else {
                this.getCitiesCountry();
            }
        },
        async getCities() {
            this.months = [];
            this.monthsF = [];
            this.city_ = null;
            this.loading = true;
            this.startMonth = '';
            this.endMonth = '';
            let country = this.countryLocal;
            let state = this.stateSelected_;
            let response = await axios.get('/api/getCities', { params: { state, country } });
            this.cities = response.data;
            this.loading = false;
        },
        async getCitiesCountry() {
            this.loading = true;
            let country = this.countryLocal;
            let response = await axios.get('/api/getCitiesCountry', { params: { country } });
            this.cities = response.data;
            this.loading = false;
        },
        async getMonths() {
            this.loading = true;
            let country = this.countryLocal;
            let state = this.stateSelected_;
            let city = this.city_;
            let lang = this.lang;
            let response = await axios.get('/api/getMonths', { params: { country, state, city, lang } });
            this.startMonthOptions = response.data;
            this.loading = false;
        },
        async selectedMonth() {
            this.endMonthOptions = [];
            this.endMonth = "";
            var addMonthF = false;
            var mes = this.startMonth.split(" ");
            for (var i = 0; i < this.startMonthOptions.length; i++) {
                if (mes[0] <= this.startMonthOptions[i].MesIni && mes[1] <= this.startMonthOptions[i].YearIni) {
                    this.endMonthOptions.push(this.startMonthOptions[i]);
                }else if( mes[1] < this.startMonthOptions[i].YearIni){
                    this.endMonthOptions.push(this.startMonthOptions[i]);
                }
            }
        },
    },
    watch: {
        countryLocal: {
            inmediate: true,
            handler(country) {
                if (country == 'USA' /*|| country == 'Canada'*/) {
                    this.hasState = true;
                } else {
                    this.hasState = false;
                }

                if (this.dirty) {
                    this.stateSelected_ = null;
                    this.city_ = null;
                }
            }
        },
        stateSelected_(val) {
            if (this.dirty) {
                if (val === 1000000000) {
                    this.city_ = 1000000000;
                } else {
                    this.city_ = null;
                }
            }

            if (val) {
                this.dirty = true;
            }
        }
    }
};

</script>
<style scoped>
.white-translucent {
    background: rgba(255, 255, 255, 0.8);
}
@font-face {
    src: url('/Montserrat.ttf');
    font-family: "custom-monse";
}
</style>
