<template>
  <div>
    <div class="form-group" v-if="memberships.length > 1">
      <div class="form-label">{{ __('Subscription') }}</div>
      <select name="membership_type_id" id="membership_id" class="form-control" v-model="membership">
        <option :value="null">{{ __('Choose a membership') }}</option>
        <option :value="membership.id" v-for="membership in memberships" :key="membership.id">{{ membership.name }}</option>
      </select>
    </div>
    <div v-else>
      <input type="hidden" name="membership_type_id" :value="membership">
    </div>

    <div class="row no-gutters">
      <div v-if="currentMembership !== null" class="col-md-6">
        <div class="p-3 rounded d-flex justify-content-between align-items-center" 
          :style="{
            background: currentMembership.bg_color,
          }"
          v-if="currentMembership"
        >
          <div>
            <img :src="currentMembership.svgIcon" alt="Icon" style="width: 36px;" v-if="currentMembership.svgIcon"> 
            <span style="font-size: 24px; font-weight: bold; color: white">{{ currentMembership.name }} /</span>
            <span class="text-white font-weight-bold">{{ currency.code === 'MXN' ? currentMembership.price : currentMembership.stripe_price }} {{ currency.code }} x {{ __(currentMembership.periodicity) }}</span>
          </div>
          <i class="fa fa-arrow-right text-white"></i>
        </div>

        <div v-if="currentMembership.all_benefits.length > 0" class="p-3">
          <div v-for="(benefit, index) in currentMembership.all_benefits" :key="`benefit_${index}`">
            <i :style="{color: currentMembership.bg_color}" class="fas fa-check-circle"></i> <span class="d-inline-block ml-3">{{ benefit }}</span>
          </div>
        </div>
      </div>
      <div v-if="membershipObject" class="col">
        <div class="p-3 rounded" 
          :style="{
            background: membershipObject.bg_color,
          }"
          v-if="membershipObject"
        >
          <img :src="membershipObject.svgIcon" alt="Icon" style="width: 36px;" v-if="membershipObject.svgIcon"> 
          <span style="font-size: 24px; font-weight: bold; color: white">{{ membershipObject.name }} /</span>
          <span class="text-white font-weight-bold">{{ membershipObject.price }} {{ currency.code }} x {{ __(membershipObject.periodicity) }}</span>
        </div>
        <div v-if="membershipObject.trial_months > 0 && currentMembership == null" class="px-2 pt-2">
          <i class="fa fa-ticket-alt mr-3" :style="{color: membershipObject.bg_color}"></i> {{ currency.code === 'MXN' ? membershipObject.registration_priceopenpay : membershipObject.registration_pricestripe }} {{ currency.code }} {{ __('registration fee') }}
        </div>
        <div v-if="benefits.length > 0" class="p-3">
          <div v-for="(benefit, index) in benefits" :key="`benefit_${index}`">
            <i :style="{color: membershipObject.bg_color}" class="fas fa-check-circle"></i> <span class="d-inline-block ml-3">{{ benefit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    memberships: {
      type: Array,
      required: true
    },
    membershipselected: {
      type: Number,
      default: null,
    },
    currency: {
      type: Object,
      required: true
    },
    currentMembership: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      membership: null
    }
  },
  mounted() {
    if (this.memberships.length === 1) {
      this.membership = this.memberships[0].id;
    }
    
    if (this.membershipselected) {
      this.membership = this.membershipselected;
    }
  },
  computed: {
    membershipObject() {
      if (this.membership === null) return null;

      return this.memberships.find(m => m.id === this.membership);
    },
    benefits() {
      if (this.membershipObject === null) return [];

      return this.membershipObject.all_benefits;
    }
  },
  watch: {
    membership(newValue) {
      this.$emit('update:membership-selected', newValue)
    }
  }
}
</script>
