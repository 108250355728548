/* (function ($) {
    $.fn.extend({
        donetyping: function (callback, timeout) {
            timeout = timeout || 1e3; // 1 second default timeout
            var timeoutReference,
                doneTyping = function (el) {
                    if (!timeoutReference) return;
                    timeoutReference = null;
                    callback.call(el);
                };
            return this.each(function (i, el) {
                var $el = $(el);
                // Chrome Fix (Use keyup over keypress to detect backspace)
                // thank you @palerdot
                $el.is(':input') && $el.on('keyup keypress paste', function (e) {
                    // This catches the backspace button in chrome, but also prevents
                    // the event from triggering too preemptively. Without this line,
                    // using tab/shift+tab will make the focused element fire the callback.
                    if (e.type == 'keyup' && e.keyCode != 8) return;

                    // Check if timeout has been set. If it has, "reset" the clock and
                    // start over again.
                    if (timeoutReference) clearTimeout(timeoutReference);
                    timeoutReference = setTimeout(function () {
                        // if we made it here, our timeout has elapsed. Fire the
                        // callback
                        doneTyping(el);
                    }, timeout);
                }).on('blur', function () {
                    // If we can, fire the event since we're leaving the field
                    doneTyping(el);
                });
            });
        }
    });
})(jQuery);


var cities = new Bloodhound({
    datumTokenizer: function (data) {
        var cityName = Bloodhound.tokenizers.whitespace(data.city_name);
        var countryName = Bloodhound.tokenizers.whitespace(data.country_name);
        return cityName.concat(countryName);
    },
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        url: '/api/cities/%QUERY',
        wildcard: '%QUERY'
    }
});

cities.initialize();
var city = null;
var month = 1;
$('#bloodhound .typeahead').typeahead({
        minLength: 3,
        highlight: true,
        hint: false
    }, {
        name: 'cities',
        display: 'city_name',
        templates: {
            notFound: '<div>0 results</div>',
            suggestion: function (data) {
                let str;
                str = '<p class="typeahead-txt"> <i style="position: absolute;font-size: 22px;left: 10px;color: #E91E63;" class="fa fa-map-marker"></i> <span style="margin-left: 35px;">' + data.city_name + ', ' + data.country_name + '</span><br>'
                return str;
            }
        },
        source: cities.ttAdapter(),
        limit: 20
    }
).on('typeahead:selected', function(el, item) {
    city = item;
});

$("#month").change(function(){
    month = $(this).children("option:selected").val();
});

$( "#searchButton" ).click(function() {
    let params = {
        month: month,
        city: city
    }
    if(city !== null)
        window.location.href = "/list/hotels?city=" + params.city.city_name + "&country=" + params.city.country_name + "&m=" + params.month;
  });
  
$('#bloodhound .typeahead').bind('typeahead:asyncrequest', function (event, suggestion) {
    $("#bloodhound .typeahead").addClass("type-spinner");
});

$('#bloodhound .typeahead').bind('typeahead:asyncreceive', function (event, suggestion) {
    $("#bloodhound .typeahead").removeClass("type-spinner");
});*/

$('.dropdown-menu input, .dropdown-menu').click(function(e) { e.stopPropagation(); });
    
 $().fancybox({
    selector : '.imglist',
    // loop: true,
    smallBtn: true,
    autoFocus: false,
    backFocus: false,
    buttons: [
    "close"
    ],
    btnTpl: {
        arrowLeft:
          '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left arrow-custom" title="{{PREV}}">' +
          '<div><i class="fas fa-caret-left fa-6x"></i></div>' +
          "</button>",

        arrowRight:
          '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right arrow-custom" title="{{NEXT}}">' +
          '<div><i class="fas fa-caret-right fa-6x"></i></div>' +
          "</button>",
          close:
          '<button data-fancybox-close class="fancybox-button fancybox-button--close arrow-custom" title="{{CLOSE}}">' +
          '<i class="fas fa-times-circle"></i>' +
          "</button>",
          smallBtn:
          '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small arrow-custom" title="{{CLOSE}}">' +
          '<i class="fas fa-times-circle fa-2x"></i>' +
          "</button>"

    },
    afterShow: function( instance, current ) {
        var left = ((window.screen.width - current.$image[0].offsetWidth) / 2 ) / 2;
        var right = current.$image[0].offsetWidth + ((window.screen.width - current.$image[0].offsetWidth) / 2) + left;

        $('.fancybox-button--arrow_left').css('left', 0);
        $('.fancybox-button--arrow_right').css('right',0); 

        $('.fancybox-button--arrow_left').css('left',  left - $('.fancybox-button--arrow_left').width() - 20);
        $('.fancybox-button--arrow_right').css('left', right - 10);
    }
});
