<template>
    <section class="py-5">
        <div class="container">
            <div class="card border-0">
                <div class="card-body">
                    <div class="text-center">
                        
                        <h3>
                            <img clas="img-fluid text-center" style="max-width: 100px;"
                            src="https://www.activehands.com/wp-content/uploads/2019/05/kisspng-check-mark-computer-icons-clip-art-green-check-circle-5b3fb28f711452.5419564815309011354632.png"> 
                            ¡{{ __('Congratulations, your reservation has been confirmed') }}!
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row" v-if="hotel">
                <div class="col-md-8">
                    <div class="card mb-3 border-0">
                        <div class="row no-gutters">
                            <div class="col-md-4">
                                <img v-bind:src="hotel.ImageProfile" style="height: 250px;" class="card-img" alt="...">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">{{hotel.ResortName}}</h5>
                                    <p class="card-text"><span class="font-weight-bold">{{ __('Confirmation code') }} :</span>
                                        {{reservation_code}}</p>
                                    <p class="card-text"><span class="font-weight-bold">Check-In:</span>
                                        {{hotel.CheckInDate | formatDate}}</p>
                                    <p class="card-text"><span class="font-weight-bold">Check-Out:</span>
                                        {{hotel.CheckOutDate | formatDate}}</p>
                                    <p class="card-text"><span class="font-weight-bold">{{hotel.Nights}} {{ __('Nights') }}</span>
                                    </p>
                                    <p class="card-text"><span class="font-weight-bold">
                                        {{hotel.MaxOccupancy}} {{ __('People') }}.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mt-md-5 border-0">
                        <div class="card-body text-center">
                            <i class="fas fa-file-signature fa-3x"></i>
                            <p class="card-text"><a v-bind:href="'/reservation-voucher?code=' + reservation_code" target="_blank">{{ __('See confirmation sheet') }}</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="hasCart">
                <div class="col-md-9">

                    <div class="row">
                        <div class="col-md-4 mb-3" v-for="item in currentCart" :key="item.id">
                            <div class="card border-0 shadow">
                                <div class="card-poster-200">
                                    <img :src="item.reservable.image" class="img-fluid">
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title text-primary mb-1">{{ item.reservable.name }}</h5>
                                    <div>
                                        {{ item.adults }} {{ __('adult') }}s / {{ item.childs}} {{ __('Kid') }}s 
                                    </div>
                                    <div class="text-succes text-right">
                                        <span class="h4 text-primary">{{ item.price }}</span> {{ item.currency_code }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-3" v-if="pending > 0">
                    <div class="card border-0 shadow">
                        <div class="card-body">
                            <form id="payment-form" name="payment-form"
                                @submit.prevent="confirmation"
                            >
                                <div class="my-3" v-if="cart.length > 0">
                                    <h6 class="mb-0">{{ __('Participating Bank Cards') }}</h6>
                                    <div class="text-muted"><i class="fab fa-cc-amex fa-2x mr-2"> </i><i
                                            class="fab fa-cc-visa fa-2x mr-2"> </i><i
                                            class="fab fa-cc-mastercard fa-2x"></i>
                                    </div>
                                </div>
                                
                                <card-component
                                    #default="{holderName, cardNumber, cvv2, expirationYear, expirationMonth, states, handlers, validators, monthOptions, yearOptions}"
                                    :years="years"
                                    :now="now">
                                    <div>
                                        <div id="addNewCard">
                                            <div class="form-group">
                                                <label for="holder_name" class="form-label">{{ __('Cardholder') }}</label>
                                                <input type="text" :placeholder="__('Cardholder')"
                                                    id="holder_name" class="form-control" data-openpay-card="holder_name">
                                            </div>
                                            <div class="form-group">
                                                <label for="card_number" class="form-label">{{ __('Card number') }}</label>
                                                <input type="text" placeholder="XXXXXXXXXXXXXXXX"
                                                    id="card_number" class="form-control" @input="handlers.cardNumber"
                                                    @blur="validators.cardNumber" :value="cardNumber"
                                                    :class="{'is-invalid': !states.cardNumber}" data-openpay-card="card_number">
                                                <div v-if="!states.cardNumber" class="text-danger">
                                                    {{ __('Invalid card number') }}
                                                </div>
                                            </div>
                    
                                            <div class="row">
                                                <div class="form-group col-md-6">
                                                    <label for="expiration_month" class="form-label">{{ __('Month') }}</label>
                                                    <select :value="expirationMonth" @change="handlers.expirationMonth" @blur="validators.expirationDate" class="form-control"
                                                        :class="{'is-invalid': !states.expirationDate}" data-openpay-card="expiration_month"
                                                    >
                                                        <option v-for="option in monthOptions" v-bind:value="option.value" :key="option.value">
                                                            {{ option.text }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="expiration_year" class="form-label">{{ __('Year') }}</label>
                                                    <select :value="expirationYear" @change="handlers.expirationYear" @blur="validators.expirationDate" class="form-control"
                                                        :class="{'is-invalid': !states.expirationDate}" data-openpay-card="expiration_year"
                                                    >
                                                        <option v-for="option in yearOptions" v-bind:value="option.value" :key="option.value">
                                                            {{ option.text }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
        
                                            <div class="form-group">
                                                <label for="cvv2" class="form-label">CVC/CVV</label>
                                                <input type="text" placeholder="123" id="cvv2"
                                                    class="form-control" @input="handlers.cvv2" @blur="validators.cvv2"
                                                    :value="cvv2" :class="{'is-invalid': !states.cvv2}" data-openpay-card="cvv2">
                                                <div v-if="!states.cvv2" class="text-danger">
                                                    CVV {{ __('Incorrect') }}
                                                </div>
                                            </div>
                                        </div>
                                            <button type="submit" 
                                            id="searchButton"
                                            class="btn btn-block btn-primary"
                                            :disabled="!states.cardNumber || !states.expirationDate || !states.cvv2 || !cardNumber || !expirationMonth || !expirationYear || !cvv2"
                                        >
                                            {{ __('Pay') }}
                                        </button>
                                    </div>
                                </card-component>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-md-8">

                    <h5 v-if="tours.length > 0">{{ __('Take advantage of our promotions on tours for your family') }}</h5>
                    <div class="swiper-container tours-slider mx-n2 pt-3 " v-swiper="{xl: 3}">
                        <div class="swiper-wrapper pb-5 ">
                            <div class="swiper-slide h-auto px-2 " style="width: 240px; margin-right: 20px;" v-for="tour of tours" v-bind:key="tour.id">
                                <div class="card hover-animate border-0 shadow">
                                    <img v-bind:src="tour.imagePath"
                                        alt="Card image" class="img-fluid card-poster-200">
                                    <div class="card-body">
                                        <h6 class="card-title text-primary text-uppercase text-center mb-1">{{tour.name}}</h6>
                                        <p class="card-text text-sm text-center">
                                            {{tour.price_per_adult}} {{tour.currency_code}} x {{ __('Adult') }}
                                        </p>
                                        <p class="card-text text-sm text-center">
                                            {{tour.price_per_child}} {{tour.currency_code}} x {{ __('Kid') }}
                                        </p>
                                        <hr>
                                        <label>{{ __('Adult') }}s</label>
                                        <select class="form-control form-control-sm" v-model="tour.adults">
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                        </select>
                                        <label>{{ __('Kid') }}s</label>
                                        <select class="form-control form-control-sm" v-model="tour.child">
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                        </select>
                                        <button class="btn btn-primary btn-sm btn-block mt-3" @click.prevent="addToCart(tour)">{{ __('Add to cart') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                                        <!-- If we need navigation buttons -->
                        <!-- <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-pagination"></div> -->
                    </div>

                    <!-- traslados -->
                    <div class="swiper-container transportation-slider mx-n2 pt-3" v-for="transportationArea of transportations" :key="transportationArea.id" v-swiper="{xl: 3}">
                        <h5>{{ __('Transfers') }} {{ __('in') }} {{ transportationArea.name }}</h5>
                        <div class="swiper-wrapper pb-5 ">
                            <div class="swiper-slide h-auto px-2 " style="width: 240px; margin-right: 20px;" v-for="transportation of transportationArea.transportations" v-bind:key="transportation.id">
                                <div class="card hover-animate border-0 shadow">
                                    <img v-bind:src="transportation.image"
                                        alt="Card image" class="img-fluid card-poster-200">
                                    <div class="card-body">
                                        <h6 class="card-title text-primary text-uppercase text-center mb-1">{{transportation.name}}</h6>
                                        <p class="card-text text-sm text-center">{{ __('Capacity') }}: 1 - {{transportation.max_pax}}</p>
                                        <select class="form-control form-control-sm" v-model="transportation.transportation_type">
                                            <option v-for="(option, index) in transportation.options" v-bind:value="option.value" :key="index">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                        <div class="form-group">
                                            <label for="">{{ __('Check in') }}</label>
                                            <select class="form-control form-control-sm" v-model="transportation.start">
                                                <option v-for="(option, index) in hours" v-bind:value="option.value" :key="index">
                                                    {{ option.text }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group" v-if="transportation.transportation_type == 1">
                                            <label for="">{{ __('Check out') }}</label>
                                            <select class="form-control form-control-sm" v-model="transportation.end">
                                                <option v-for="(option, index) in hours" v-bind:value="option.value" :key="index">
                                                    {{ option.text }}
                                                </option>
                                            </select>
                                        </div>
                                        <button class="btn btn-primary btn-sm btn-block mt-3" @click.prevent="addToCart(transportation)">{{ __('Add to cart') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                                        <!-- If we need navigation buttons -->
                        <!-- <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-pagination"></div> -->
                    </div>
                </div>
                <div class="col-lg-4 pl-xl-4" v-if="tours.length > 0">
                    <div class="card border-0 shadow">
                        <div class="card-body ">
                            <div class="text-block pt-3 pb-0">
                                <table class="w-100">
                                    <tbody>
                                        <tr>
                                            <th>{{ __('Additional') }}</th>
                                        </tr>
                                        <tr v-for="(item, index) of cart" :key="index">
                                            <th class="font-weight-normal py-2">{{item.name}}<br/> 
                                            <span v-if="item.type == 'tours'">{{item.adults}} {{ __('Adult') }}s, {{item.child}} {{ __('Kid') }}s</span>
                                            </th>
                                            <td class="text-right py-2">$
                                                {{formatPrice(item.price_per_adult * item.adults + item.price_per_child * item.child)}}
                                                {{ currency }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr class="border-top">
                                            <th class="pt-3">Total</th>
                                            <td class="font-weight-bold text-right pt-3">$
                                                {{formatPrice(total)}} {{ currency }}</td>
                                        </tr>
                                        <!-- <tr>
                                        </tr> -->
                                    </tfoot>
                                </table>
                                <form id="one-click-payment-form" name="one-click-payment-form" v-if="payWithOneClick">
                                    <input type="hidden" name="method" value="onClick">
                                    <button type="button" class="btn btn-primary btn-block mt-5" v-if="cart.length > 0"
                                        v-on:click="confirmation">{{ __('Confirm with a click') }}</button>
                                </form>
                                <form id="payment-form" name="payment-form" v-else
                                    @submit.prevent="confirmation"
                                >
                                    <div class="alert alert-danger" v-if="errorMessage">
                                        {{ errorMessage }}
                                    </div>

                                    <div class="my-3" v-if="cart.length > 0">
                                        <h6 class="mb-0">{{ __('Participating Bank Cards') }}</h6>
                                        <div class="text-muted"><i class="fab fa-cc-amex fa-2x mr-2"> </i><i
                                                class="fab fa-cc-visa fa-2x mr-2"> </i><i
                                                class="fab fa-cc-mastercard fa-2x"></i>
                                        </div>
                                    </div>
                                    
                                    <card-component v-if="cart.length > 0"
                                        #default="{holderName, cardNumber, cvv2, expirationYear, expirationMonth, states, handlers, validators, monthOptions, yearOptions}"
                                        :years="years"
                                        :now="now">
                                        <div>
                                            <div id="addNewCard">
                                                <div class="form-group">
                                                    <label for="holder_name" class="form-label">{{ __('Cardholder') }}</label>
                                                    <input type="text" :placeholder="__('Cardholder')"
                                                        id="holder_name" class="form-control" data-openpay-card="holder_name">
                                                </div>
                                                <div class="form-group">
                                                    <label for="card_number" class="form-label">{{ __('Card number') }}</label>
                                                    <input type="text" placeholder="XXXXXXXXXXXXXXXX"
                                                        id="card_number" class="form-control" @input="handlers.cardNumber"
                                                        @blur="validators.cardNumber" :value="cardNumber"
                                                        :class="{'is-invalid': !states.cardNumber}" data-openpay-card="card_number">
                                                    <div v-if="!states.cardNumber" class="text-danger">
                                                        {{ __('Invalid card number') }}
                                                    </div>
                                                </div>
                        
                                                <div class="row">
                                                    <div class="form-group col-md-6">
                                                        <label for="expiration_month" class="form-label">{{ __('Month') }}</label>
                                                        <select :value="expirationMonth" @change="handlers.expirationMonth" @blur="validators.expirationDate" class="form-control"
                                                            :class="{'is-invalid': !states.expirationDate}" data-openpay-card="expiration_month"
                                                        >
                                                            <option v-for="option in monthOptions" v-bind:value="option.value" :key="option.value">
                                                                {{ option.text }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label for="expiration_year" class="form-label">{{ __('Year') }}</label>
                                                        <select :value="expirationYear" @change="handlers.expirationYear" @blur="validators.expirationDate" class="form-control"
                                                            :class="{'is-invalid': !states.expirationDate}" data-openpay-card="expiration_year"
                                                        >
                                                            <option v-for="option in yearOptions" v-bind:value="option.value" :key="option.value">
                                                                {{ option.text }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label for="cvv2" class="form-label">CVC/CVV</label>
                                                    <input type="text" placeholder="123" id="cvv2"
                                                        class="form-control" @input="handlers.cvv2" @blur="validators.cvv2"
                                                        :value="cvv2" :class="{'is-invalid': !states.cvv2}" data-openpay-card="cvv2">
                                                    <div v-if="!states.cvv2" class="text-danger">
                                                        CVV {{ __('Incorrect') }}
                                                    </div>
                                                </div>
                                            </div>
                                                <button type="submit" 
                                                id="searchButton"
                                                class="btn btn-block btn-primary"
                                                :disabled="!states.cardNumber || !states.expirationDate || !states.cvv2 || !cardNumber || !expirationMonth || !expirationYear || !cvv2"
                                            >
                                                {{ __('Pay') }}
                                            </button>
                                        </div>
                                    </card-component>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        props: ['reservation_id', 'reservation_code', 'currency', 'canPayWithOneClick', 'now', 'years', 'currentCart', 'pending'],
        data() {
            return {
                hotel: null,
                searchParams: null,
                tours: [],
                transportations: [],
                cart: [],
                total: 0,
                confirmationStatus: [],
                paymentStatus: 1, // 1: not action, 2: success, 3: failed
                errorMessage: null,
            }
        },
        methods: {
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            addToCart(item) {
                for (let i = 0; i < item.amount; i++) {
                    this.cart.push(item)
                }

                this.total = 0;
                this.cart.forEach(element => {
                    if (element.type == 'tours') {
                        this.total += element.adults * element.price_per_adult + element.child * element
                            .price_per_child,
                            element.startDate = this.hotel.CheckInDate,
                            element.endDate = this.hotel.CheckInDate
                    }

                    if (element.type == 'transportation_category') {
                        let price = element.pricing.find(p => p.transportation_type == element.transportation_type);
                        this.total += parseFloat(price.price);
                        element.startDate = `${this.hotel.CheckInDate} ${element.start}:00`;
                        element.adults = 1;
                        element.child = 0;
                        element.endDate = element.transportation_type == 1 ? `${this.hotel.CheckOutDate} ${element.end}:00` : null;
                        element.price_per_adult = price.price;
                        element.price_per_child = 0;
                    }
                });
                console.log(this.cart)
            },
            confirmation: function () {
                $('#cover-spin').show(0)
                
                this.getConfirmationData().then(data => {
                    axios.post('/api/payment/additionals', {
                            ...data
                        })
                        .then(function (response) {
                            window.location.href = response.data.url;
                        })
                        .catch(function (error) {
                            if (error.response) {
                                this.errorMessage = error.response.message;
                            }

                            this.paymentStatus = 3;
                            $('#cover-spin').hide(0)
                        })
                        .finally(function () {
                            // always executed
                        });
                }).catch(err => {
                    console.log(err);
                    $('#cover-spin').hide(0)
                });
            },
            getConfirmationData() {
                return new Promise((resolve, reject) => {
                    if (this.payWithOneClick) {
                        resolve({
                            method: 'oneClick',
                            cart: this.cart,
                            total: this.total,
                            reservation_id: this.reservation_id,
                            device_data_id: this.deviceDataId
                        });
                    } else {
                        const success = (response) => {
                            let token = response.data.id;
                            resolve({
                                method: 'card',
                                token: token,
                                cart: this.cart,
                                total: this.total,
                                reservation_id: this.reservation_id,
                                device_data_id: this.deviceDataId
                            })
                        }

                        const err = (err) => {
                            this.errorMessage = this.__('Check that you have entered the card details correctly');
                            reject(err);
                        }

                        OpenPay.token.extractFormAndCreate('payment-form', success, err);
                    }
                });
            }
        },
        mounted() {
            console.log(this.reservation_id)
            this.hotel = JSON.parse(localStorage.getItem('selectedHotel'))
            this.searchParams = JSON.parse(localStorage.getItem('searchParams'))

            this.deviceDataId = OpenPay.deviceData.setup();

            if (this.hasCart) {
                this.total = this.currentCart.reduce((a, i) => a + parseFloat(i.price), 0);
                this.paymentStatus = 3;
            }

            let t = this;
            axios.get('/api/additionals', {
                    params: {
                        reservation_id: this.reservation_id
                    }
                })
                .then(function (response) {
                    t.tours = response.data.tours
                    t.transportations = response.data.transportations.map(ta => {
                        ta.transportations = ta.transportations.map(t => {
                            t.options = t.pricing.map(p => {
                                return {
                                    value: p.transportation_type,
                                    text: `${p.type} x ${t.amount} - ${t.amount * p.price} ${p.currency_code}`
                                }
                            })

                            t.transportation_type = t.options[0].value;
                            t.start = '12:00';
                            t.end = '12:00';

                            return t;
                        });

                        return ta;
                    })
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });

        },
        computed: {
            hasCart() {
                return this.currentCart.length > 0;
            },
            payWithOneClick() {
                return this.canPayWithOneClick && this.paymentStatus != 3;
            },
            hours() {
                let hs = [];

                for (let i = 0; i < 24; i++) {
                    let val = i < 10 ? `0${i}:00` : `${i}:00`
                    hs.push({value: val, text: `${val} hrs`});
                }

                return hs;
            }
        }
    }

</script>
