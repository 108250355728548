<template>
    <div>
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 border-left-2">
                        <div class="row">
                            <div class="col-auto mr-auto mb-2 pl-4" style="background-color: #0078b3;"><h3>{{ __('General data') }}</h3></div>
                            <div class="col-auto text-muted px-4"><h3>{{ __('Reservation request') }}</h3></div>
                        </div>
                        <!-- <h3 class="mb-2 text-primary col-12"> {{ __('General data') }} <span class="text-muted float-right">{{ __('Step 1') }}</span></h3> -->
                        <div class="bg-white p-3 shadow">
                            <form v-if="this.yatesel" novalidate="novalidate" method="POST" :action="`/yates-top/${this.yatesel.id}/request`" ref="yate_request_form" @submit="saveLead">
                                <csrf-token></csrf-token>
                                <input type="hidden" name="points" :value="points" />
                                <input type="hidden" name="points_value" :value="pointsValue" />
                                <div class="mb-4">
                                    <h5>{{ __('Reservation holder') }}</h5>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>{{ __('Full name') }}</label>
                                            <div class="input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    :class="{'is-invalid': hasError('name')}"
                                                    name="name"
                                                    required="" 
                                                    :placeholder="__('Full name')"
                                                    aria-label="Nombre completo" 
                                                    v-model="name">
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('name')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>{{ __('Last Name') }}</label>
                                            <div class="input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    name="last_name"
                                                    :class="{'is-invalid': hasError('last_name')}"
                                                    required="" 
                                                    :placeholder="__('Last Name')" 
                                                    aria-label="Apellidos"
                                                    v-model="last_name">
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('last_name')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label>{{ __('Email') }}</label>
                                    <div class="input-group input-group form">
                                        <input 
                                            type="email" 
                                            class="form-control form__input" 
                                            name="email" 
                                            :class="{'is-invalid': hasError('email')}"
                                            required=""
                                            :placeholder="__('Email')" 
                                            aria-label="Correo electrónico"
                                            v-model="email">
                                        <div class="invalid-feedback" v-for="(error, index) in getErrors('email')" :key="index">
                                            <small class="d-block">{{ error }}</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="js-form-message mb-3">
                                            <label for="international_code" class="form-label">{{ __('Country') }}</label>
                                            <select
                                            class="form-control form__input"
                                            name="international_code"
                                            :class="{'is-invalid': hasError('international_code')}"
                                            required="" 
                                            v-model="international_code"
                                            >
                                            <option :value="null">{{ __('Choose a option') }}</option>
                                            <option :value="'+52'">MEXICO +52</option>
                                            <option :value="'+1'">UNITED STATES +1</option>
                                            <option :value="'+1'">CANADA +1</option>
                                            <option :value="'+57'">COLOMBIA +57</option>
                                            <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
                                            </select>
                                        </div> 
                                    </div>
                                    <div class="col-md-6">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Phone number') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    name="phone_number"
                                                    :class="{'is-invalid': hasError('phone_number')}"
                                                    required="" 
                                                    :placeholder="__('Phone number')" 
                                                    aria-label="Teléfono"
                                                    v-model="phone_number">
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('phone_number')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Address') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    name="address"
                                                    :class="{'is-invalid': hasError('address')}"
                                                    required="" 
                                                    :placeholder="__('Address')" 
                                                    aria-label="Address"
                                                    v-model="address"
                                                />
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('address')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Zip code') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    type="number" 
                                                    class="form-control form__input" 
                                                    name="zip_code"
                                                    :class="{'is-invalid': hasError('zip_code')}"
                                                    required="" 
                                                    placeholder="77711" 
                                                    aria-label="Código postal"
                                                    v-model="zipCode"
                                                />
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('zip_code')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Country') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <select
                                                    class="form-control form__input" 
                                                    name="country_id"
                                                    :class="{'is-invalid': hasError('country_id')}"
                                                    required="" 
                                                    aria-label="Country"
                                                    v-model="country"
                                                >
                                                    <option :value="null">{{ __('Choose a option') }}</option>
                                                    <option :value="121">MEXICO</option>
                                                    <option :value="195">USA</option>
                                                    <option :value="32">CANADA</option>
                                                    <option :value="40">COLOMBIA</option>
                                                    <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                                                      {{ option.text }}
                                                    </option>
                                                </select>
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('country_id')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4" v-if="stateOptions.length > 0">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('State') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <select 
                                                    class="form-control form__input" 
                                                    name="state_id"
                                                    :placeholder="__('State')" 
                                                    aria-label="state"
                                                    v-model="state"
                                                >
                                                    <option v-for="option in stateOptions" :key="option.value" :value="option.value">
                                                        {{ option.text }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('City') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    class="form-control form__input" 
                                                    name="city_id"
                                                    required="" 
                                                    :placeholder="__('City')" 
                                                    aria-label="City"
                                                    v-model="city"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Date Check in') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <date-picker v-model="dateReserve"
                                                type="date" 
                                                :input-attr="{name: 'date', placeholder: 'Date'}"
                                                input-class="form-control"
                                              ></date-picker>
                                            </div>
                                            <div class="text-danger" v-for="(error, index) in getErrors('date')" :key="index">
                                                <small class="d-block">{{ error }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="js-form-message mb-3">
                                            <label># {{ __('Package') }}.</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    type="number" 
                                                    class="form-control form__input" 
                                                    name="number_of_people"
                                                    required="" 
                                                    aria-label="Number Person"
                                                    :class="{'is-invalid': hasError('number_of_people')}"
                                                    v-model="numberPerson"
                                                />
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('number_of_people')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto mr-auto mb-3 pl-4" style="background-color: #0078b3;"><h3>{{ __('Payment info') }}</h3></div>
                                <div class="row">
                                        <div class="col-md-6">
                                            <div class="js-form-message mb-3">
                                                <label>{{ __('Cardholder Name') }}.</label>
                                                <div class="js-focus-state input-group input-group form">
                                                    <input 
                                                        type="text" 
                                                        class="form-control form__input" 
                                                        name="card_holder_name"
                                                        required=""
                                                        placeholder="Jonh" 
                                                        aria-label="Cardholder Name"
                                                        :class="{'is-invalid': hasError('card_holder_name')}"
                                                        v-model="cardholderName"
                                                    />
                                                    <div class="invalid-feedback" v-for="(error, index) in getErrors('card_holder_name')" :key="index">
                                                        <small class="d-block">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="js-form-message mb-3">
                                                <label>{{ __('Number Card') }}.</label>
                                                <div class="js-focus-state input-group input-group form">
                                                    <input 
                                                        type="text" 
                                                        class="form-control form__input" 
                                                        name="number_card"
                                                        required=""
                                                        maxlength="16"
                                                        placeholder="1234 1234 1234 1234" 
                                                        :class="{'is-invalid': hasError('number_card')}"
                                                        v-model="numberCard"
                                                    />
                                                    <div class="invalid-feedback" v-for="(error, index) in getErrors('number_card')" :key="index">
                                                        <small class="d-block">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="js-form-message mb-3">
                                                <label>{{ __('Expiration Month') }}.</label>
                                                <div class="js-focus-state input-group input-group form">
                                                    <input 
                                                        type="text" 
                                                        class="form-control form__input" 
                                                        name="expiration_card"
                                                        required=""
                                                        maxlength="2"
                                                        placeholder="01" 
                                                        aria-label="Name Card"
                                                        :class="{'is-invalid': hasError('expiration_card')}"
                                                        v-model="expirationCard"
                                                    />
                                                    <div class="invalid-feedback" v-for="(error, index) in getErrors('expiration_card')" :key="index">
                                                        <small class="d-block">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="js-form-message mb-3">
                                                <label>{{ __('Expiration Year') }}.</label>
                                                <div class="js-focus-state input-group input-group form">
                                                    <input 
                                                        type="text" 
                                                        class="form-control form__input" 
                                                        name="expiration_year_card"
                                                        required=""
                                                        maxlength="4"
                                                        placeholder="2025" 
                                                        aria-label="Name Card"
                                                        :class="{'is-invalid': hasError('expirationyear_card')}"
                                                        v-model="expirationYearCard"
                                                    />
                                                    <div class="invalid-feedback" v-for="(error, index) in getErrors('expirationyear_card')" :key="index">
                                                        <small class="d-block">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="js-form-message mb-3">
                                                <label>{{ __('CVC') }}.</label>
                                                <div class="js-focus-state input-group input-group form">
                                                    <input 
                                                        type="text" 
                                                        class="form-control form__input" 
                                                        name="cvc"
                                                        required=""
                                                        maxlength="3"
                                                        aria-label="CVC"
                                                        placeholder="123" 
                                                        :class="{'is-invalid': hasError('cvc')}"
                                                        v-model="cvc"
                                                    />
                                                    <div class="invalid-feedback" v-for="(error, index) in getErrors('cvc')" :key="index">
                                                        <small class="d-block">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-4">
                                        <template>
                                            <vue-recaptcha 
                                            sitekey="6LfpS6gZAAAAABDvDo7FeMwPzeUjlQgVmcaFPFJc" 
                                            :loadRecaptchaScript="true"
                                            ref="recaptcha"
                                            @verify="onCaptchaVerified"
                                            @expired="onCaptchaExpired"
                                            >
                                            </vue-recaptcha>
                                        </template>
                                    </div>
                                <button 
                                    type="sumit"
                                    style="background-color: #0078b3;"
                                    class="btn btn-block"
                                    :disabled="disableButton">
                                    {{ __('Reserve request') }} <i class="fas fa-spin fa-spinner" v-if="loader"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-5 pl-xl-5" v-if="this.yatesel">
                        <div class="card border-0 shadow">
                            <div class="card-body p-4">
                                <div class="text-block pb-3">
                                    <div class="media align-items-center">
                                        <div class="media-body">
                                            <img v-bind:src="this.yatesel.images.length > 0 ? this.yatesel.images[0].public_path : ''"
                                            alt="" class="rounded w-100 mb-2" style="height: 200px">
                                            <h4><i style="color: #0078b3;" class="fas fa-ship" aria-hidden="true"></i> {{this.yatesel.name}}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-block py-3">
                                    <ul class="list-unstyled mb-3">
                                        <li>
                                            <i class="fas fa-users fa-fw mr-2" style="background-color: #0078b3;"></i>
                                            {{this.yatesel.max_pax}}
                                            {{ __('People') }}.
                                        </li>
                                        <li class="list-inline-item mr-3">
                                            <i class="fas fa-ruler-horizontal fa-fw mr-2" style="background-color: #0078b3;"></i> {{ yate.pies }} pies
                                        </li>
                                    </ul>
                                </div>

                                <div class="text-block py-3">
                                    <h4 class="mb-4" style="background-color: #0078b3;" v-if="lang == 'en'">Prices</h4>
                                    <h4 class="mb-4" style="background-color: #0078b3;" v-if="lang == 'es'">Precios</h4>
                                    <div v-if="this.yatesel.prices" class="subtitle text-sm">
                                        <span v-if="lang == 'en'">
                                            <div v-for="price in yatesel.prices" style="color: #fff; background-color: #0078b3;text-align: center;border-radius: 10px;margin-bottom: 10px;">
                                                <span class="font-weight-bold" style="font-size: 26px;"> ${{ formatPrice(price.price_publicusd) }} Dlls</span>
                                                <div  style="font-size: 10px;margin-top: -8px;margin-left: 70px;">PER {{price.ours}} HOURS.</div>
                                            </div>
                                        </span>
                                        
                                        <span v-else>
                                            <div v-for="price in yatesel.prices" style="color: #fff; background-color: #0078b3;text-align: center;border-radius: 10px;margin-bottom: 10px;">
                                                <span class="font-weight-bold" style="font-size: 26px;"> ${{ formatPrice(price.price_publicmxn) }} Dlls</span>
                                                <div  style="font-size: 10px;margin-top: -8px;margin-left: 70px;">POR {{price.ours}} HORA.</div>
                                            </div>
                                        </span>   
                                    </div>
                                    <div class="alert alert-info d-flex justify-content-between" v-if="points > 0">
                                        <span>{{ points }} {{ __('Points') }}</span>
                                        <span class="font-weight-bold">$ {{ pointsValue }} {{ currency }}</span>
                                    </div>
                                </div>

                                <div class="text-block pt-3 pb-0">
                                    <div v-if="lang == 'es'">
                                        <h3 class="mb-4" style="background-color: #0078b3;">{{ __('Amenities') }}</h3>
                                        <div class="row">
                                            <div class="col-4 d-flex align-items-start mb-2" v-for="tag in this.yate.amenities.split(',')" v-bind:key="tag+1">
                                                <i class="fas fa-check-circle mt-2 mr-2 text-justify text-muted"></i>
                                                <span>{{tag}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="lang == 'en'">
                                        <h3 class="mb-4" style="background-color: #0078b3;">{{ __('Amenities') }}</h3>
                                        <div class="row">
                                            <div class="col-4 d-flex align-items-start mb-2" v-for="tag in this.yate.amenitien.split(',')" v-bind:key="tag+1">
                                                <i class="fas fa-check-circle mt-2 mr-2 text-justify text-muted"></i>
                                                <span>{{tag}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-block" v-if="lang == 'es'">
                                        <h3 class="mt-3 mb-4" style="background-color: #0078b3;">{{ __('Equipamento') }}</h3>
                                        <div class="row">
                                            <div class="col-4 d-flex align-items-start mb-2" v-for="tag in this.yate.equipmentes.split(',')" v-bind:key="tag+1">
                                                <i class="fas fa-check-circle mt-2 mr-2 text-justify text-muted"></i>
                                                <span>{{tag}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-block" v-else>
                                        <h3 class="mb-4 mt-3" style="background-color: #0078b3;">{{ __('Equipament') }}</h3>
                                        <div class="row">
                                            <div class="col-4 d-flex align-items-start mb-2" v-for="tag in this.yate.equipmenten.split(',')" v-bind:key="tag+1">
                                                <i class="fas fa-check-circle mt-2 mr-2 text-justify text-muted"></i>
                                                <span>{{tag}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import errors from "../mixins/errors";

    export default {
        mixins: [errors],
        props: ['currency', 'lang','codes','yatesel','points','pointsValue'],
        data() {
            return {
                name: '',
                last_name: '',
                international_code: '',
                email: '',
                dateReserve:'',
                numberPerson:'',
                phone_number: '',
                terms_and_conditions: true,
                errors: [],
                searchParams : null,
                address: '',
                zipCode: null,
                cardholderName:'', 
                numberCard:'',
                expirationCard:'',
                expirationYearCard:'',
                cvc:'',
                country: null,
                state: null,
                city: null,
                countries: [],
                captchaver: false, 
                loader: false
            }
        },
        mounted() {
            this.yate = JSON.parse(localStorage.getItem('selectedYate'))
            this.searchParams = JSON.parse(localStorage.getItem('searchParams'))
            this.loadCountries()
        },
        computed: {
            countryOptions() {
                return [].concat(this.countries.map(country => {
                    return {
                        text: country.country_name.toUpperCase(),
                        value: country.id
                    }
                }));
            },
            countrySelected() {
                if (this.country === null) return null;

                return this.countries.find(c => c.id == this.country);
            },
            cityOptions() {
                let options = [{text: this.__('Choose a city'), value: null}];
                if (this.countrySelected !== null) {
                    options = options.concat(this.countrySelected.cities.map(city => {
                        return {
                            text: city.city_name.toUpperCase(),
                            value: city.id
                        }
                    }));
                }

                return options;
            },
            stateOptions() {
                let options = [];
                if (this.countrySelected !== null) {
                    options = options.concat(this.countrySelected.states.map(state => {
                        return {
                            text: state.name.toUpperCase(),
                            value: state.id
                        }
                    }));
                }

                return options;
            },
            disableButton() {
                return (
                    !this.name || 
                    !this.last_name ||
                    !this.international_code || 
                    !this.phone_number || 
                    !this.email || 
                    !this.validEmail(this.email) ||
                    !this.city ||
                    !this.address ||
                    !this.country ||
                    !this.zipCode ||
                    !this.cardholderName || 
                    !this.numberCard ||
                    !this.expirationCard ||
                    !this.expirationYearCard ||
                    !this.cvc ||
                    !this.captchaver
                )  || this.loader;
            }
        },
        methods: {
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace(',', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            onCaptchaVerified(recaptchaToken){
                this.captchaver = true;
            },
            onCaptchaExpired: function () {
                this.$refs.recaptcha.reset();
            },
            async loadCountries() {
                let { data: countries } = await axios.get('/api/countries');

                this.countries = countries;
            },
            updateValue(event) {
                const value = event.target.value
                if (String(value).length <= 10) {
                   // this.cell_phone = value
                }
                this.$forceUpdate()
            },
            validEmail: function (email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            saveLead: function () {
                
                this.errors = []

                if (!this.name) {
                    this.errors.push("El nombre es obligatorio.")
                }
                if (!this.last_name) {
                    this.errors.push("El nombre es obligatorio.")
                }
                if (!this.email) {
                    this.errors.push('El correo electrónico es obligatorio.')
                } else if (!this.validEmail(this.email)) {
                    this.errors.push('El correo electrónico debe ser válido.')
                }

                if (this.errors.length > 0) {
                    return true
                }
                                
                this.loader = true;
                
                this.$refs['yate_request_form'].submit();
            }
        }
    }

</script>