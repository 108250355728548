<template>
  <div>
    <form :action="`/tours/${tour.id}/checkout`" method="POST" id="payment-form" ref="paymentForm">
      <csrf-token></csrf-token>
      <input type="hidden" name="date" :value="date" />
      <!-- input type="hidden" name="datef" :value="datef" /-->
      <input type="hidden" name="payment_id" :value="payment_id">
      <input type="hidden" name="total" :value="total" />
      <input type="hidden" name="partial_total" :value="partialTotal" />
      <input type="hidden" name="amount_type" :value="amount_type">
      <input type="hidden" name="city_id" :value="tour.city_id">
      <div class="row">
        <div class="col-md-3 border-right order-3 order-md-1">
          <!-- <div class="card border-0">

            
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <span>{{ __('Price by adult') }}</span>
                <span class="text-danger font-weight-bold">$ {{ tour.adult_price}} {{ tour.currency }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span>{{ __('Price by child') }}</span>
                <span class="text-danger font-weight-bold">$ {{ tour.child_price }} {{ tour.currency }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span>{{ __('Price by infant') }}</span>
                <span class="text-danger font-weight-bold">$ {{ tour.infant_price }} {{ tour.currency }}</span>
              </div>
            </div>
          </div> -->

          <div class="card-memberships-tours mb-2" v-if="tour.it_includes != null">
            <div class="tab-membership-header p-2">
              <h5 class="mb-0">
                <div class="text-primary font-weight-bold" aria-controls="collapse-includes">
                  {{ __('include') }}
                </div>
              </h5>
            </div>
            <div class="card-body p-0 pr-3">
              <ul class="includes-tours">
                  <li style="margin-top: -12px;" v-for="item in (tour.it_includes.split('|'))" class="text-justify">{{ item }}</li>
              </ul>
            </div>
          </div>

           <div class="card-memberships-tours mb-2" v-if="tour.does_not_include != null">
            <div class="tab-membership-header p-2">
              <h5 class="mb-0">
                <div class="text-primary font-weight-bold" aria-controls="collapse-includes">
                  {{ __('doesIncludes') }}
                </div>
              </h5>
            </div>
            <div class="card-body p-0 pr-3">
              <ul class="does-includes-tours">
                  <li style="margin-top: -12px;" v-for="item in (tour.does_not_include.split('|'))" class="text-justify">{{ item }}</li>
              </ul>
            </div>
          </div>

          
          <div class="card">
            <div class="card-body bg-info border-info">
              <h6 class="text-white font-weight-bold">{{ __('Additional Information') }}</h6>
              <p class="text-white">
                {{ tour.cancellation_policies }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2">
          <h5 class="text-primary font-weight-bold ">{{ tour.name }}</h5>
          <div class="row">
            <div class="card card-memberships-tours">
              <div class="card-body">
                <div :id="`tourCarousel${ tour.id }`" class="carousel slide" date-ride="carousel">
                  <div class="carousel-inner rounded">
                    <div v-for="(image, index) in tour.images" :key="image.id" class="carousel-item" :class="{active: index == 0}">
                      <img :src="image.public_path" :alt="`Image ${ image.id }`" class="d-block w-100 rounded">
                    </div>
                  </div>
      
                  <a class="carousel-control-prev" :href="`#tourCarousel${ tour.id }`" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" :href="`#tourCarousel${ tour.id }`" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
                <div class="text-justify mt-2">
                  {{ tour.body }} 
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div class="card-memberships-tours">
                <div class="tab-membership-header p-2">
                  <h5 class="text-primary font-weight-bold ">{{ __('persons') }}</h5>
                </div>

                <div class="row p-2">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="adults" class="">{{ __('Adults') }}</label>
                      <select name="adults" id="adults" v-model="adultsCount" class="form-control">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                      <small class="form-text text-muted">* {{ __('Over :age years', {age: minAdultAge} ) }}</small>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="kids" class="">{{ __('Children') }}</label>
                      <select name="kids" id="kids" v-model="childrenCount" class="form-control">
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                      <small class="form-text text-muted">* {{ __('Between :age and :age2 years', {age: maxChildAge, age2: minChildAge}) }}</small>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="infants" class="">{{ __('Infants') }}</label>
                      <select name="infants" id="infants" v-model="infantsCount" class="form-control">
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                      <small class="form-text text-muted">* {{ __('Between :age and :age2 years', {age: maxInfantAge, age2: minInfantAge}) }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="p-3 bg-gray-200 mb-3 rounded border">
            <h5>{{ __('Adult') }}</h5>
            <input type="hidden" name="price" :value="tour.adult_price">
            <div class="form-group row mb-3">
              <div class="col-form-label col-md-3">{{ __('Name') }}</div>
              <div class="col-md-9">
                <input type="text" class="form-control" v-model="name" name="name">
              </div>
            </div>
            
            <div class="form-group row mb-3">
              <div class="col-form-label col-md-3">{{ __('Last Name') }}</div>
              <div class="col-md-9">
                <input type="text" class="form-control" v-model="last_name" name="last_name">
              </div>
            </div>
  
            <div class="form-group row mb-3">
              <div class="col-form-label col-md-3">{{ __('Email') }}</div>
              <div class="col-md-9">
                <input type="text" class="form-control" v-model="email" name="email">
              </div>
            </div>
  
            <div class="form-group row mb-3">
              <div class="col-form-label col-md-3">{{ __('Phone number') }}</div>
              <div class="col-md-9">
                <input type="text" class="form-control" v-model="phone_number" name="phone_number">
              </div>
            </div>

            <div class="form-group row mb-3">
              <div class="col-form-label col-md-3">{{ __('Date') }}</div>
              <div class="col-md-9">
                <!-- :disabled-date="notBeforeToday" -->
                <date-picker v-model="dateTour"
                type="date" 
                :disabled-date="disabledRule"
                :input-attr="{name: 'datef', placeholder: 'Date'}"
                input-class="form-control"  
              ></date-picker>
              </div>
            </div>
            
            <div class="form-group col-md-12">
                <p class="text-sm text-muted">
                  <input type="checkbox" class="form-group form-check-input" v-model="terms_and_conditions" id="terms_and_conditions">
                    {{ __('I accept') }}
                  <a :href="`/termsAndConditions`" target="_blank">
                    {{ __('Terms and conditions') }}
                  </a> {{ __('and') }}
                  <a :href="`/docs/privacy-policies-${lang}.pdf`">
                    {{ __('Privacy policies') }}
                  </a>.
                </p>
            </div>
          </div>
  
          <div class="form-group row mb-3" v-for="(adult, index) in adults" :key="adult.position">
            <input type="hidden" :name="`adult[${index}][price]`" :value="adult.price">
            <div class="col-form-label col-md-3">{{ __('Adult name :number', {number: adult.position + 1 }) }}</div>
            <div class="col-md-9">
              <input type="text" class="form-control" v-model="adult.name" :name="`adult[${index}][name]`">
            </div>
          </div>
  
          <div class="form-group row mb-3" v-for="(child, index) in children" :key="child.position">
            <input type="hidden" :name="`child[${index}][price]`" :value="child.price">
            <label class="col-form-label col-md-3">{{ __('Child name :number', {number: child.position}) }}</label>
            <div class="col-md-9">
              <input type="text" class="form-control" v-model="child.name" :name="`child[${index}][name]`">
            </div>
          </div>

          <div class="form-group row mb-3" v-for="(infant, index) in infants" :key="infant.position">
            <input type="hidden" :name="`infant[${index}][price]`" :value="infant.price">
            <label class="col-form-label col-md-3">{{ __('Infant name :number', {number: infant.position}) }}</label>
            <div class="col-md-9">
              <input type="text" class="form-control" v-model="infant.name" :name="`infant[${index}][name]`">
            </div>
          </div>
        </div>
  
        <div class="col-md-3 border-left order-2 order-md-3">
          <ul class="list-group mb-5" v-if="canPayAPortion">
            <li class="list-group-item">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" v-model="amount_type" id="complete" name="amount_type" value="complete">
                <label class="custom-control-label" for="complete">
                  {{ __('100% payment') }}
                </label>
              </div>
            </li>
            <li class="list-group-item">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" v-model="amount_type" id="partial" name="amount_type" value="partial">
                <label class="custom-control-label" for="partial">
                  {{ __('60% partial payment (Liquid before Check-In)') }}
                </label>
              </div>
            </li>
          </ul>
          <div id="scroll">
            <h5 class="text-primary">{{ __('Payment info') }}</h5>
          </div>
          <div>
            <stripe-checkout :total="totalToPay" 
              :currency="tour.currency" 
              @payment-successfully="paymentSuccessfully"
              :disabled="!isValid"
            ></stripe-checkout>
          </div>
        </div>
      </div>
    </form>
    <div id="off">
    </div>
  </div>
</template>

<script>
import isWithinInterval from 'date-fns/isWithinInterval';
import differenceInDays from 'date-fns/differenceInDays';
import parse from 'date-fns/parse';
import every from "lodash/every";

window.onscroll = function() {scrollFunction()};
function scrollFunction() {

  if($("#off").length){                             
    var off = $("#off").offset();
    let windowOffset = $(window).offset();
    off = (off.top/2) + 500;
    if(document.documentElement.scrollTop < off ){
      var valor = document.documentElement.scrollTop - 100;
     if(document.documentElement.scrollTop < 99 || window.innerWidth < 768){
        document.getElementById("scroll").style.marginTop = "0px";
      }else if(document.documentElement.scrollTop > 100){
        document.getElementById("scroll").style.marginTop = valor+"px";
        document.getElementById("scroll").style.top = "250px";
      }
    }
  }
}

export default {
  props: ['tour', 'date', 'years', 'now', 'user', 'datef', 'hasSubscription', 'mexicanCurrencyExchageRate'],
  data: function () {
    return {
      name: '',
      last_name: '',
      email: '',
      phone_number: '',
      terms_and_conditions:false,
      dateTour: '',
      adultsCount: 0,
      childrenCount: 0,
      infantsCount: 0,
      adults: [],
      children: [],
      infants: [],
      method: 'card',
      loader: false,
      amount_type: 'complete',
      payment_id: null,
    }
  },
  computed: {
    total() {
      let pricePerAdult = this.tour.adult_price;
      let pricePerChild = this.tour.child_price;
      let pricePerInfant = this.tour.infant_price;

      let complete = (this.adultsCount * pricePerAdult) + (this.childrenCount * pricePerChild) + (this.infantsCount * pricePerInfant);

      return complete;
    },
    canPayWithMultipleOptions() {
      return this.totalInMexicanCurrency < 10000;
    },
    totalToPay() {
      return this.amount_type === 'partial' ? this.partialTotal : this.total;
    },
    totalInMexicanCurrency() {
      if (this.tour.currency !== this.mexicanCurrencyExchageRate.code) {
        return parseFloat(this.mexicanCurrencyExchageRate.value) * this.totalToPay;
      }

      return this.totalToPay;
    },
    partialTotal() {
      if (this.amount_type === 'partial') {
        return Math.round(this.total * .6);
      }

      return this.total;
    },
    minAdultAge() {
      return this.tour.min_adult_age;
    },
    maxChildAge() {
      return this.minAdultAge - 1;
    },
    minChildAge() {
      return this.tour.min_child_age;
    },
    maxInfantAge() {
      return this.minChildAge - 1;
    },
    minInfantAge() {
      return this.tour.min_infant_age;
    },
    startDate() {
      if (this.date) {
        return parse(this.date, 'yyyy-MM-dd', new Date());
      }

      return null;
    },
    endDate() {
      if (this.datef) {
        return parse(this.datef, 'yyyy-MM-dd', new Date());
      }

      return null;
    },
    canPayAPortion() {
      if (this.dateTour) {
        return differenceInDays(this.dateTour, parse(this.now, 'yyyy-MM-dd', new Date())) > 30 && this.hasSubscription;
      }

      return false;
    },
    adultsIsValid() {
      return this.adultsCount > 0 && every(this.adults, adult => adult.name !== '');
    },
    childrenIsValid() {
      return every(this.children, child => child.name !== '');
    },
    holderIsValid() {
      return this.name !== '' &&
        this.last_name !== '' &&
        this.email !== '' &&
        this.terms_and_conditions !== false &&
        this.phone_number !== '';
    },
    isValid() {
      return this.adultsIsValid && this.childrenIsValid && this.holderIsValid;
    }
  },
  mounted() {
    if (this.user) {
      this.name = this.user.name;
      this.last_name = this.user.last_name;
      this.email = this.user.email;
      this.phone_number = this.user.phone_number;
      this.dateTour = this.user.dateTour;
    }

    this.adultsCount = 2;
  },
  methods: {
    paymentSuccessfully(paymentResult) {
      this.payment_id = paymentResult.id;
      this.$nextTick(() => {
        this.$refs.paymentForm.submit();
      })
    },
    disabledRule(date) {
      if (this.startDate !== null && this.endDate !== null) {
        return ! isWithinInterval(date, {start: this.startDate, end: this.endDate});
      }

      return false;
    }
  },
  watch: {
    adultsCount(next) {
      this.adults.splice(0, this.adults.length);
      
      for (let i = 1; i < this.adultsCount; i++) {
        this.adults.push({
          position: i,
          name: '',
          price: this.tour.adult_price,
        });
      }
    },
    childrenCount(newxt) {
      this.children.splice(0, this.adults.length);

      for (let i = 1; i <= this.childrenCount; i++) {
        this.children.push({
          position: i,
          name: '',
          price: this.tour.child_price,
        });
      }
    },
    infantsCount(newxt) {
      this.infants.splice(0, this.infants.length);

      for (let i = 1; i <= this.infantsCount; i++) {
        this.infants.push({
          position: i,
          name: '',
          price: this.tour.infant_price
        });
      }
    }
  }
}
</script>