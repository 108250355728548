<template>
    <div>
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 border-left-2">
                        <div class="alert alert-info mb-3">
                            <svg class="bi bi-alert-triangle" width="1rem" height="1rem" viewBox="0 0 20 20" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M9.938 4.016a.146.146 0 00-.054.057L3.027 15.74a.176.176 0 00-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 00.066-.017.163.163 0 00.055-.06.176.176 0 00-.003-.183L10.12 4.073a.146.146 0 00-.054-.057.13.13 0 00-.063-.016.13.13 0 00-.064.016zm1.043-.45a1.13 1.13 0 00-1.96 0L2.166 15.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L10.982 3.566z" clip-rule="evenodd"/>
                                <rect width="2" height="2" x="9.002" y="13" rx="1"/>
                                <path d="M9.1 7.995a.905.905 0 111.8 0l-.35 3.507a.553.553 0 01-1.1 0L9.1 7.995z"/>
                            </svg>
                            {{ __('This accommodation is seen many times a day') }}.
                        </div>
                        <div class="row">
                            <div class="col-auto mr-auto mb-2 pl-4" style="color: #0078B3;"><h3>{{ __('General data') }}</h3></div>
                            <div class="col-auto text-muted px-4"><h3>{{ __('Reservation request') }}</h3></div>
                        </div>
                        <!-- <h3 class="mb-2 text-primary col-12"> {{ __('General data') }} <span class="text-muted float-right">{{ __('Step 1') }}</span></h3> -->
                        <div class="bg-white p-3 shadow">
                            <form v-if="condo" novalidate="novalidate" method="POST" :action="`/condos-top/${condo.id}/request`" ref="condo_request_form" @submit="saveLead">
                                <csrf-token></csrf-token>
                                <div class="mb-4">
                                    <h5>{{ __('Reservation holder') }}</h5>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>{{ __('Full name') }}</label>
                                            <div class="input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    :class="{'is-invalid': hasError('name')}"
                                                    name="name"
                                                    required="" 
                                                    :placeholder="__('Full name')"
                                                    aria-label="Nombre completo" 
                                                    v-model="name">
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('name')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>{{ __('Last Name') }}</label>
                                            <div class="input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    name="last_name"
                                                    :class="{'is-invalid': hasError('last_name')}"
                                                    required="" 
                                                    :placeholder="__('Last Name')" 
                                                    aria-label="Apellidos"
                                                    v-model="last_name">
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('last_name')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label>{{ __('Email') }}</label>
                                    <div class="input-group input-group form">
                                        <input 
                                            type="email" 
                                            class="form-control form__input" 
                                            name="email" 
                                            :class="{'is-invalid': hasError('email')}"
                                            required=""
                                            :placeholder="__('Email')" 
                                            aria-label="Correo electrónico"
                                            v-model="email">
                                        <div class="invalid-feedback" v-for="(error, index) in getErrors('email')" :key="index">
                                            <small class="d-block">{{ error }}</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="js-form-message mb-3">
                                            <label for="international_code" class="form-label">{{ __('Country') }}</label>
                                            <select
                                            class="form-control form__input"
                                            name="international_code"
                                            :class="{'is-invalid': hasError('international_code')}"
                                            required="" 
                                            v-model="international_code"
                                            >
                                            <option :value="null">{{ __('Choose a option') }}</option>
                                            <option :value="'+52'">MEXICO +52</option>
                                            <option :value="'+1'">UNITED STATES +1</option>
                                            <option :value="'+1'">CANADA +1</option>
                                            <option :value="'+57'">COLOMBIA +57</option>
                                            <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
                                            </select>
                                        </div> 
                                    </div>
                                    <div class="col-md-6">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Phone number') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    name="phone_number"
                                                    :class="{'is-invalid': hasError('phone_number')}"
                                                    required="" 
                                                    :placeholder="__('Phone number')" 
                                                    aria-label="Teléfono"
                                                    v-model="phone_number">
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('phone_number')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- div class="row">
                                    <div class="col-md-9">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Address') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    type="text" 
                                                    class="form-control form__input" 
                                                    name="address"
                                                    :class="{'is-invalid': hasError('address')}"
                                                    required="" 
                                                    :placeholder="__('Address')" 
                                                    aria-label="Address"
                                                    v-model="address"
                                                />
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('address')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Zip code') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    type="number" 
                                                    class="form-control form__input" 
                                                    name="zip_code"
                                                    :class="{'is-invalid': hasError('zip_code')}"
                                                    required="" 
                                                    placeholder="77711" 
                                                    aria-label="Código postal"
                                                    v-model="zipCode"
                                                />
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('zip_code')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Country') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <select
                                                    class="form-control form__input" 
                                                    name="country_id"
                                                    :class="{'is-invalid': hasError('country_id')}"
                                                    required="" 
                                                    aria-label="Country"
                                                    v-model="country"
                                                >
                                                    <option :value="null">{{ __('Choose a option') }}</option>
                                                    <option :value="121">MEXICO</option>
                                                    <option :value="195">USA</option>
                                                    <option :value="32">CANADA</option>
                                                    <option :value="40">COLOMBIA</option>
                                                    <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                                                      {{ option.text }}
                                                    </option>
                                                </select>
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('country_id')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4" v-if="stateOptions.length > 0">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('State') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <select 
                                                    class="form-control form__input" 
                                                    name="state_id"
                                                    :placeholder="__('State')" 
                                                    aria-label="state"
                                                    v-model="state"
                                                >
                                                    <option v-for="option in stateOptions" :key="option.value" :value="option.value">
                                                        {{ option.text }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('City') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    class="form-control form__input" 
                                                    name="city_id"
                                                    required="" 
                                                    :placeholder="__('City')" 
                                                    aria-label="City"
                                                    v-model="city"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div -->
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Date Check in') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <date-picker v-model="dateReserve"
                                                type="date" 
                                                :input-attr="{name: 'date', placeholder: 'Date'}"
                                                input-class="form-control"
                                              ></date-picker>
                                            </div>
                                            <div class="text-danger" v-for="(error, index) in getErrors('date')" :key="index">
                                                <small class="d-block">{{ error }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="js-form-message mb-3">
                                            <label>{{ __('Date Check out') }}</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <date-picker v-model="dateReservecout"
                                                type="date" 
                                                :input-attr="{name: 'datecout', placeholder: 'Date'}"
                                                input-class="form-control"
                                              ></date-picker>
                                            </div>
                                            <div class="text-danger" v-for="(error, index) in getErrors('date')" :key="index">
                                                <small class="d-block">{{ error }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="js-form-message mb-3">
                                            <label># {{ __('Person') }}.</label>
                                            <div class="js-focus-state input-group input-group form">
                                                <input 
                                                    type="number" 
                                                    class="form-control form__input" 
                                                    name="number_of_people"
                                                    required="" 
                                                    aria-label="Number Person"
                                                    :class="{'is-invalid': hasError('number_of_people')}"
                                                    v-model="numberPerson"
                                                />
                                                <div class="invalid-feedback" v-for="(error, index) in getErrors('number_of_people')" :key="index">
                                                    <small class="d-block">{{ error }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--div class="col-auto mr-auto mb-3 text-primary pl-4"><h3>{{ __('Payment info') }}</h3></div-->
                                <!--iv class="row">
                                        <div class="col-md-6">
                                            <div class="js-form-message mb-3">
                                                <label>{{ __('Cardholder Name') }}.</label>
                                                <div class="js-focus-state input-group input-group form">
                                                    <input 
                                                        type="text" 
                                                        class="form-control form__input" 
                                                        name="card_holder_name"
                                                        required=""
                                                        placeholder="Jonh" 
                                                        aria-label="Cardholder Name"
                                                        :class="{'is-invalid': hasError('card_holder_name')}"
                                                        v-model="cardholderName"
                                                    />
                                                    <div class="invalid-feedback" v-for="(error, index) in getErrors('card_holder_name')" :key="index">
                                                        <small class="d-block">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="js-form-message mb-3">
                                                <label>{{ __('Number Card') }}.</label>
                                                <div class="js-focus-state input-group input-group form">
                                                    <input 
                                                        type="text" 
                                                        class="form-control form__input" 
                                                        name="number_card"
                                                        required=""
                                                        maxlength="16"
                                                        placeholder="1234 1234 1234 1234" 
                                                        :class="{'is-invalid': hasError('number_card')}"
                                                        v-model="numberCard"
                                                    />
                                                    <div class="invalid-feedback" v-for="(error, index) in getErrors('number_card')" :key="index">
                                                        <small class="d-block">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-div class="row">
                                        <div class="col-md-4">
                                            <div class="js-form-message mb-3">
                                                <label>{{ __('Expiration Month') }}.</label>
                                                <div class="js-focus-state input-group input-group form">
                                                    <input 
                                                        type="text" 
                                                        class="form-control form__input" 
                                                        name="expiration_card"
                                                        required=""
                                                        maxlength="2"
                                                        placeholder="01" 
                                                        aria-label="Name Card"
                                                        :class="{'is-invalid': hasError('expiration_card')}"
                                                        v-model="expirationCard"
                                                    />
                                                    <div class="invalid-feedback" v-for="(error, index) in getErrors('expiration_card')" :key="index">
                                                        <small class="d-block">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="js-form-message mb-3">
                                                <label>{{ __('Expiration Year') }}.</label>
                                                <div class="js-focus-state input-group input-group form">
                                                    <input 
                                                        type="text" 
                                                        class="form-control form__input" 
                                                        name="expiration_year_card"
                                                        required=""
                                                        maxlength="4"
                                                        placeholder="2025" 
                                                        aria-label="Name Card"
                                                        :class="{'is-invalid': hasError('expirationyear_card')}"
                                                        v-model="expirationYearCard"
                                                    />
                                                    <div class="invalid-feedback" v-for="(error, index) in getErrors('expirationyear_card')" :key="index">
                                                        <small class="d-block">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="js-form-message mb-3">
                                                <label>{{ __('CVC') }}.</label>
                                                <div class="js-focus-state input-group input-group form">
                                                    <input 
                                                        type="text" 
                                                        class="form-control form__input" 
                                                        name="cvc"
                                                        required=""
                                                        maxlength="3"
                                                        aria-label="CVC"
                                                        placeholder="123" 
                                                        :class="{'is-invalid': hasError('cvc')}"
                                                        v-model="cvc"
                                                    />
                                                    <div class="invalid-feedback" v-for="(error, index) in getErrors('cvc')" :key="index">
                                                        <small class="d-block">{{ error }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div-->
                                    <div class="form-group mb-4">
                                        <label>{{ __('Comments') }}</label>
                                        <div class="js-focus-state input-group input-group form">
                                            <textarea 
                                                type="text" 
                                                class="form-control form__input" 
                                                name="comments"
                                                :class="{'is-invalid': hasError('address')}"
                                                required="" 
                                                :placeholder="__('Comments')" 
                                                aria-label="Comments"
                                                v-model="comments"
                                            />
                                            <div class="invalid-feedback" v-for="(error, index) in getErrors('comments')" :key="index">
                                                <small class="d-block">{{ error }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-4">
                                        <template>
                                            <vue-recaptcha 
                                            sitekey="6LfpS6gZAAAAABDvDo7FeMwPzeUjlQgVmcaFPFJc" 
                                            :loadRecaptchaScript="true"
                                            ref="recaptcha"
                                            @verify="onCaptchaVerified"
                                            @expired="onCaptchaExpired"
                                            >
                                            </vue-recaptcha>
                                        </template>
                                    </div>
                                <button 
                                    type="sumit"
                                    class="btn btn-block"
                                    style="background-color: #0078B3; color: #fff;" 
                                    :disabled="disableButton">
                                    {{ __('Send Request') }} <i class="fas fa-spin fa-spinner" v-if="loader"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-5 pl-xl-5" v-if="condo">
                        <div class="card border-0 shadow">
                            <div class="card-body p-4">
                                <div class="text-block pb-3">
                                    <div class="media align-items-center">
                                        <div class="media-body">
                                            <img v-bind:src="condo.images[condo.images.length - 1].public_path"
                                            alt="" class="rounded w-100 mb-2" style="height: 200px">
                                            <h4>{{condo.name}}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-block py-3">
                                    <ul class="list-unstyled mb-3">
                                        <li><i class="fas fa-users fa-fw mr-2" style="color: #0078B3;"></i>{{condo.numperson}}
                                            {{ __('People') }}.
                                        </li>
                                        <li v-if="condo.numninos"><i class="fa fa-child fa-fw text-primary mr-2"></i>{{condo.numninos}}
                                            {{ __('People') }}.
                                        </li>
                                        <!--li>
                                            <i class="fas fa-moon text-primary mr-2"></i>
                                            {{condo.numnight}} {{ __('Nights') }}</th>
                                        </li-->
                                    </ul>
                                </div>
                                <div class="text-block pt-3 pb-0">
                                    <div v-if="lang == 'es' && amenitieslimit == true">
                                        <div class="col-12 d-flex align-items-start mb-2" v-for="(amenity, index) in condo.itincludeses" v-bind:key="amenity+1" v-if="index <= 4">
                                            <i v-if="index <= 4" class="fas fa-check-circle mt-2 mr-2 text-muted text-justify"></i>{{amenity}}
                                        </div>
                                    </div>
                                    <div v-if="lang == 'en'  && amenitieslimit == true">
                                        <div class="col-12 d-flex align-items-start mb-2" v-for="(amenity, index) in  condo.itincludesen" v-bind:key="amenity+1" v-if="index <= 4">
                                            <i v-if="index <= 4" class="fas fa-check-circle mt-2 mr-2 text-muted text-justify"></i>{{amenity}}
                                        </div>
                                    </div>

                                    <div v-if="condo.itincludeses.length > 5">
                                        <span v-if="amenitieslimit == true" @click="moreamenities()" style="cursor: pointer; color: #0078B3;">
                                            <span v-if="lang == 'es'">+ Ver Mas</span>
                                            <span v-if="lang == 'en'">+ See More</span>
                                        </span>
                                        <div v-if="lang == 'es' && amenitieslimit == false">
                                        <div class="col-12 d-flex align-items-start mb-2" v-for="(amenity, index) in condo.itincludeses" v-bind:key="amenity+1">
                                            <i class="fas fa-check-circle mt-2 mr-2 text-muted text-justify"></i>{{amenity}}
                                        </div>
                                        </div>
                                        <div v-if="lang == 'en' && amenitieslimit == false">
                                            <div class="col-12 d-flex align-items-start mb-2" v-for="(amenity, index) in  condo.itincludesen" v-bind:key="amenity+1">
                                                <i class="fas fa-check-circle mt-2 mr-2 text-muted text-justify"></i>{{amenity}}
                                            </div>
                                        </div>
                                        <span  v-if="amenitieslimit == false" @click="moreamenities()" style="cursor: pointer; color: #0078B3;">
                                            <span v-if="lang == 'es'">- Ver Menos</span>
                                            <span v-if="lang == 'en'">- See Less</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import errors from "../mixins/errors";

    export default {
        mixins: [errors],
        props: ['currency', 'lang','codes'],
        data() {
            return {
                condo: null,
                name: '',
                last_name: '',
                international_code: '',
                email: '',
                dateReserve:'',
                dateReservecout:'',
                numberPerson:'',
                phone_number: '',
                terms_and_conditions: true,
                errors: [],
                searchParams : null,
                amenitieslimit:true,
                address: '',
                zipCode: null,
                cardholderName:'', 
                numberCard:'',
                expirationCard:'',
                comments:'',
                expirationYearCard:'',
                cvc:'',
                country: null,
                state: null,
                city: null,
                countries: [],
                captchaver: false, 
                loader: false
            }
        },
        mounted() {
            this.condo = JSON.parse(localStorage.getItem('selectedCondo'))
            this.searchParams = JSON.parse(localStorage.getItem('searchParams'))
            this.loadCountries()
        },
        computed: {
            countryOptions() {
                return [].concat(this.countries.map(country => {
                    return {
                        text: country.country_name.toUpperCase(),
                        value: country.id
                    }
                }));
            },
            countrySelected() {
                if (this.country === null) return null;

                return this.countries.find(c => c.id == this.country);
            },
            cityOptions() {
                let options = [{text: this.__('Choose a city'), value: null}];
                if (this.countrySelected !== null) {
                    options = options.concat(this.countrySelected.cities.map(city => {
                        return {
                            text: city.city_name.toUpperCase(),
                            value: city.id
                        }
                    }));
                }

                return options;
            },
            stateOptions() {
                let options = [];
                if (this.countrySelected !== null) {
                    options = options.concat(this.countrySelected.states.map(state => {
                        return {
                            text: state.name.toUpperCase(),
                            value: state.id
                        }
                    }));
                }

                return options;
            },
            disableButton() {
                return (
                    !this.name || 
                    !this.last_name ||
                    !this.international_code || 
                    !this.phone_number || 
                    !this.email || 
                    !this.validEmail(this.email) ||
                    //!this.city ||
                    //!this.address ||
                    //!this.state ||
                    //!this.country ||
                    //!this.zipCode ||
                   // !this.cardholderName || 
                   // !this.numberCard ||
                   // !this.expirationCard ||
                  //  !this.expirationYearCard ||
                  //  !this.cvc ||
                    !this.dateReserve ||
                    !this.dateReservecout ||
                    !this.numberPerson || 
                    !this.captchaver
                )  || this.loader;
            }
        },
        methods: {
             onCaptchaVerified(recaptchaToken){
                this.captchaver = true;
            },
            onCaptchaExpired: function () {
                this.$refs.recaptcha.reset();
            },
            async loadCountries() {
                let { data: countries } = await axios.get('/api/countries');

                this.countries = countries;
            },
            updateValue(event) {
                const value = event.target.value
                if (String(value).length <= 10) {
                   // this.cell_phone = value
                }
                this.$forceUpdate()
            },
            validEmail: function (email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            saveLead: function () {
                
                this.errors = []

                if (!this.name) {
                    this.errors.push("El nombre es obligatorio.")
                }
                if (!this.last_name) {
                    this.errors.push("El nombre es obligatorio.")
                }
                if (!this.email) {
                    this.errors.push('El correo electrónico es obligatorio.')
                } else if (!this.validEmail(this.email)) {
                    this.errors.push('El correo electrónico debe ser válido.')
                }

                if (this.errors.length > 0) {
                    return true
                }
                                
                this.loader = true;
                
                this.$refs['condo_request_form'].submit();
            },
            moreamenities: function(){
                if(this.amenitieslimit == false){
                    this.amenitieslimit = true;
                }else{
                    this.amenitieslimit = false;
                }
            }
        }
    }

</script>
