<template>
    <form action="/cart" method="POST" id="payment-form" ref="paymentForm">
        <csrf-token></csrf-token>
        <input type="hidden" name="total" :value="cart.total" />
        <input type="hidden" name="coupon" :value="coupon">
        <input type="hidden" name="use_coupon" :value="coupon_valid">
        <input type="hidden" name="discount_coupon" :value="discount_coupon">
        <input type="hidden" name="payment_id" :value="payment_id">
        <div class="row" >
            <div class="col-md-8">
                <div v-if="hotel">
                    <input type="hidden" name="hotel" value="1">
                    <input type="hidden" name="hotel_code" :value="hotel.info.code">
                    <input type="hidden" name="hotel_name" :value="hotel.info.name">
                    <input type="hidden" name="hotel_address" :value="hotel.info.address">
                    <input type="hidden" name="hotel_latitude" :value="hotel.info.latitude">
                    <input type="hidden" name="hotel_longitude" :value="hotel.info.longitude">
                    <input type="hidden" name="hotel_main_picture" :value="hotel.info.main_picture">
                    <input type="hidden" name="hotel_phone_number" :value="hotel.info.phone_number">
                    <input type="hidden" name="hotel_description" :value="hotel.info.description">
                    <input type="hidden" name="hotel_inventory_id" :value="hotel.info.InventoryID">
                    <input type="hidden" name="hotel_inv_type" :value="hotel.info.InvType">
                    <input type="hidden" name="hotel_start_datetime" :value="hotel.info.CheckInDate">
                    <input type="hidden" name="hotel_end_datetime" :value="hotel.info.CheckOutDate">
                    <input type="hidden" name="hotel_max_occupancy" :value="hotel.info.MaxOccupancy">

                    <input type="hidden" name="hotel_price" :value="totalHotel">
                    <input type="hidden" name="hotel_net" :value="hotel.netPrice">
                    <input type="hidden" name="city_id" :value="citySearched">
                    <input type="hidden" name="points_value" :value="pointsValue">
                    <input type="hidden" name="points" :value="points">

                    <div class="row mb-3 bg-light p-3">
                        <div class="col-md-2">
                            <img :src="hotel.info.main_picture" :alt="hotel.info.name" class="img-fluid rounded">
                        </div>
                        <div class="col-md-10">
                            <div class="d-flex justify-content-between">
                                <h6 class="text-muted">{{ hotel.info.name }}</h6>
                                <i class="fas fa-spin fa-spinner" v-if="deleting === hotel.itemId"></i>
                                <button v-else type="button" class="close" aria-label="Close" @click="remove(hotel.itemId)">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="h4">{{ hotel.price | currency }} {{ hotel.currency }}</div>
                        </div>
                    </div>
                    <div v-if="points > 0" class="d-flex justify-content-between">
                        <div class="font-weight-normal py-2 text-secondary">
                            {{ points }} {{ __('Points') }}
                        </div>
                        <div class="text-right py-2 text-danger font-weight-bold">- $ {{pointsValue}}
                            {{ currency.code }}
                        </div>
                    </div>
                </div>

                <div v-if="discount_coupon > 0" class="alert alert-info">
                    {{ __('Travel voucher discount') }}
                    <span class="font-weight-bold">{{ discount_coupon | currency }}</span>
                </div>

                <div class="input-group mb-3 mt-5" v-if="discount_coupon == 0 && user !== null && hotel">
                    <input type="text" class="form-control" v-model="coupon" placeholder="Cupón" aria-label="Cupón" aria-describedby="Cupón">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" :disabled="is_coupon_loading" type="button" v-on:click="checkIfCouponIsValid">  <i v-if="is_coupon_loading" class="fas fa-spinner fa-spin"></i> {{ __('Redeem travel voucher') }}</button>
                    </div>
                    <small v-if="couponMessage">{{ couponMessage }}</small>
                </div>

                <!-- Inicio -->
                <div v-if="user === null">
                    <div class="mb-4">
                        <h5>{{ __('Reservation holder') }}</h5>
                    </div>
    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label>{{ __('Full name') }}</label>
                                <div class="input-group input-group form">
                                    <input 
                                        type="text" 
                                        class="form-control form__input" 
                                        name="name"
                                        required="" 
                                        :placeholder="__('Full name')"
                                        aria-label="Nombre completo" 
                                        v-model="name">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label>{{ __('Last Name') }}</label>
                                <div class="input-group input-group form">
                                    <input 
                                        type="text" 
                                        class="form-control form__input" 
                                        name="last_name"
                                        required="" 
                                        :placeholder="__('Last Name')" 
                                        aria-label="Apellidos"
                                        v-model="last_name">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label>{{ __('Email') }}</label>
                        <div class="input-group input-group form">
                            <input 
                                type="email" 
                                class="form-control form__input" 
                                name="email" 
                                required=""
                                :placeholder="__('Email')" 
                                aria-label="Correo electrónico"
                                v-model="email">
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="js-form-message mb-3">
                                <label>{{ __('Phone number') }}</label>
                                <div class="js-focus-state input-group input-group form">
                                    <input 
                                        type="text" 
                                        class="form-control form__input" 
                                        name="phone_number"
                                        required="" 
                                        :placeholder="__('Phone number')" 
                                        aria-label="Teléfono"
                                        v-model="phone_number">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="js-form-message mb-3">
                                <label>{{ __('Cell phone number') }}</label>
                                <div class="js-focus-state input-group input-group form">
                                    <input 
                                        type="number" 
                                        class="form-control form__input" 
                                        name="cellphone"
                                        required="" 
                                        :placeholder="__('Cell phone number')" 
                                        aria-label="Celular"
                                        v-model="cell_phone">
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-md-9">
                            <div class="js-form-message mb-3">
                                <label>{{ __('Address') }}</label>
                                <div class="js-focus-state input-group input-group form">
                                    <input 
                                        type="text" 
                                        class="form-control form__input" 
                                        name="address"
                                        required="" 
                                        :placeholder="__('Address')" 
                                        aria-label="Address"
                                        v-model="address"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="js-form-message mb-3">
                                <label>{{ __('Zip code') }}</label>
                                <div class="js-focus-state input-group input-group form">
                                    <input 
                                        type="number" 
                                        class="form-control form__input" 
                                        name="zip_code"
                                        required="" 
                                        placeholder="77711" 
                                        aria-label="Código postal"
                                        v-model="zipCode"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-md-4">
                            <div class="js-form-message mb-3">
                                <label>{{ __('Country') }}</label>
                                <div class="js-focus-state input-group input-group form">
                                    <select
                                        class="form-control form__input" 
                                        name="country"
                                        required="" 
                                        aria-label="Country"
                                        v-model="country"
                                    >
                                        <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="js-form-message mb-3">
                                <label>{{ __('City') }}</label>
                                <div class="js-focus-state input-group input-group form">
                                    <select 
                                        class="form-control form__input" 
                                        name="city_id"
                                        required="" 
                                        :placeholder="__('City')" 
                                        aria-label="City"
                                        v-model="city"
                                    >
                                        <option v-for="option in cityOptions" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="js-form-message mb-3">
                                <label>{{ __('State') }}</label>
                                <div class="js-focus-state input-group input-group form">
                                    <select 
                                        class="form-control form__input" 
                                        name="state"
                                        :placeholder="__('State')" 
                                        aria-label="state"
                                        v-model="state"
                                    >
                                        <option v-for="option in stateOptions" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin -->


                <div v-for="(item, index) in tours" :key="`tour_${index}`">
                    <div class="row mb-3 bg-light p-3">
                        <div class="col-md-2">
                            <img :src="item.info.main_picture" :alt="item.info.name" class="img-fluid rounded">
                        </div>
                        <div class="col-md-10">
                            <div class="d-flex justify-content-between">
                                <h6 class="text-muted">{{ item.info.name }}</h6>
                                <i class="fas fa-spin fa-spinner" v-if="deleting === item.itemId"></i>
                                <button v-else type="button" class="close" aria-label="Close" @click="remove(item.itemId)">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="h4">{{ item.price | currency }} {{ item.currency }}</div>
                        </div>
                    </div>
                    <input type="hidden" :name="`tours[${index}][id]`" :value="item.id">
                    <input type="hidden" :name="`tours[${index}][city_id]`" :value="item.info.city_id">
                    <div class="form-group row mb-3">
                        <div class="col-form-label col-md-3">{{ __('Date') }}</div>
                        <div class="col-md-9">
                            <!-- :disabled-date="notBeforeToday" -->
                            <date-picker v-model="item.dateTour"
                            type="date"
                            :input-attr="{name: `tours[${index}][datef]`, placeholder: 'Date'}"
                            input-class="form-control"  
                            ></date-picker>
                        </div>
                    </div>

                    <div class="form-group row mb-3" v-for="(adult, adultInfo) in item.info.adults" :key="`adult_${item.itemId}_${adultInfo}`">
                        <input type="hidden" :name="`tours[${index}][adult][${adultInfo}][price]`" :value="item.info.adult_price">
                        <div class="col-form-label col-md-3">{{ __('Adult name :number', {number: adultInfo + 1}) }}</div>
                        <div class="col-md-9">
                            <input type="text" class="form-control" v-model="adult.name" :name="`tours[${index}][adult][${adultInfo}][name]`">
                        </div>
                    </div>
            
                    <div class="form-group row mb-3" v-for="(child, childIndex) in item.info.children" :key="`child_${item.itemId}_${childIndex}`">
                        <input type="hidden" :name="`tours[${index}][child][${childIndex}][price]`" :value="item.info.child_price">
                        <label class="col-form-label col-md-3">{{ __('Child name :number', {number: childIndex + 1}) }}</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" v-model="child.name" :name="`tours[${index}][child][${childIndex}][name]`">
                        </div>
                    </div>

                    <div class="form-group row mb-3" v-for="(infant, infantIndex) in item.info.infants" :key="`infant_${item.itemId}_${infantIndex}`">
                        <input type="hidden" :name="`tours[${index}][infant][${infantIndex}][price]`" :value="item.info.infant_price">
                        <label class="col-form-label col-md-3">{{ __('Infant name :number', {number: infantIndex + 1}) }}</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" v-model="infant.name" :name="`tours[${index}][infant][${infantIndex}][name]`">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">


                <h5 class="text-primary">{{ __('Payment info') }}</h5>

                <stripe-checkout :total="totalPay" 
                    :currency="currency"
                    @payment-successfully="paymentSuccessfully"
                ></stripe-checkout>


            </div>
        </div>
    </form>
</template>

<script>
import EventBus from "./../event-bus";
import { is_string } from "./../util";

export default {
    props: {
        cart: {
            type: Object,
            required: true
        },
        years: {
            type: Array
        },
        now: {
            type: String
        },
        user: {
            type: Object | null
        },
        hasSubscription: {
            type: Boolean
        },
        mexicanCurrencyExchangeRate: {
            type: Object,
            required: true
        },
        points: {
            type: Number,
            default: 0
        },
        pointsValue: {
            type: Number,
            default: 0
        }
    }, 
    data() {
        return {
            name: '',
            last_name: '',
            email: '',
            phone_number: '',
            cell_phone: '',
            address: '',
            zipCode: '',
            country: null,
            city: null,
            state: null,

            countries: [],
            hotel: null,
            tours: [],

            method: 'card',
            deleting: false,
            loader: false,

            coupon: '',
            coupon_valid: false,
            discount_coupon: 0,
            is_coupon_loading:false,
            couponMessage: null,

            payment_id: null,
        }
    },
    mounted() {
        this.loadCountries()
        this.initData(this.cart);
        if (this.couponRegister) {
            this.coupon = this.couponRegister;
            this.checkIfCouponIsValid();
        }
        EventBus.$on('cart-changed', (cart) => this.initData(cart));
    },
    computed: {
        totalDiscount() {
            return this.discount_coupon + this.pointsValue;
        },
        countryOptions() {
            return [{text: this.__('Choose a country'), value: null}].concat(this.countries.map(country => {
                return {
                    text: country.country_name.toUpperCase(),
                    value: country.id
                }
            }));
        },
        countrySelected() {
            if (this.country === null) return null;

            return this.countries.find(c => c.id == this.country);
        },
        cityOptions() {
            let options = [{text: this.__('Choose a city'), value: null}];
            if (this.countrySelected !== null) {
                options = options.concat(this.countrySelected.cities.map(city => {
                    return {
                        text: city.city_name.toUpperCase(),
                        value: city.id
                    }
                }));
            }

            return options;
        },
        stateOptions() {
            let options = [{text: this.__('Choose a state'), value: null}];
            if (this.countrySelected !== null) {
                options = options.concat(this.countrySelected.states.map(state => {
                    return {
                        text: state.name.toUpperCase(),
                        value: state.id
                    }
                }));
            }

            return options;
        },
        totalHotel() {
            return this.hotel.price - this.totalDiscount;
        },
        totalPay() {
            return this.cart.total - this.totalDiscount;
        },
        currency() {
            return this.cart.items[0].currency;
        },
        totalInMexicanCurrency() {
            let total = this.totalPay;

            if (this.currency !== this.mexicanCurrencyExchangeRate.code) {
                total = parseFloat(this.mexicanCurrencyExchangeRate.value * total);
            }

            return total;
        },
        canPayWithMultipleOptions() {
            return this.totalInMexicanCurrency < 10000;
        },
        citySearched() {
            if (this.hotel) {
                return this.hotel.info.city_id;
            }

            if (this.tours.length > 0) {
                return this.tours[0].info.city_id;
            }

            return '';
        }
    },
    methods: {
        paymentSuccessfully(paymentResult) {
            this.payment_id = paymentResult.id;
            this.$nextTick(() => {
                this.$refs.paymentForm.submit();
            })
        },
        async checkIfCouponIsValid() {
            this.is_coupon_loading = true
            let { data } = await axios.get(`/coupon/${this.coupon}`);
            if(data.is_valid) {
                this.coupon = data.coupon.code
                this.discount_coupon = data.coupon.fixed_rate == 1 ? this.hotel.price - data.coupon.value : data.coupon.value
                this.valid_coupon = true
                this.is_coupon_loading = false
            }

            this.couponMessage = data.message || null;
        },
        initData(cart) {
            this.hotel = cart.items.find(i => i.type === 'hotel');
            this.tours = cart.items.filter(i => i.type === 'tours')
                .map(t => {
                    t.dateTour = null;
                    t.info.adults = [];
                    t.info.children = [];
                    t.info.infants = [];

                    for (var i = 0; i < t.info.adult; i++) {
                        t.info.adults.push({name: ''});
                    }

                    for (var i = 0; i < t.info.child; i++) {
                        t.info.children.push({name: ''});
                    }

                    for (var i = 0; i < t.info.infant; i++) {
                        t.info.infants.push({name: ''});
                    }

                    return t;
                });
        },
        async loadCountries() {
            let { data: countries } = await axios.get('/api/countries');

            this.countries = countries;
        },
        async remove(id) {
            this.deleting = id;
            let { data } = await axios.delete(`/cart-item/${id}`);
            this.deleting = null;
            EventBus.$emit('cart-changed', data);
        },
    }
}
</script>
