<template>
    <div>
        <div class="container mt-5">
            <div class="card mb-5">
                <div class="card-body">
                    <div class="row">
                        <div class="col-1">
                            
                        </div>
                        <div class="col-9 text-center">
                            <img clas="img-fluid" style="max-width: 100px;"
                                src="https://www.activehands.com/wp-content/uploads/2019/05/kisspng-check-mark-computer-icons-clip-art-green-check-circle-5b3fb28f711452.5419564815309011354632.png">
                            <h3 style="line-height: 6rem;margin-left: 1rem;">¡{{ __('Congratulations, you chose a good offer') }}!</h3>
                            <p>{{ __('Please download the payment receipt') }}.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body text-center">
                            <i class="fas fa-file-download fa-3x"></i>
                            <p class="card-text" v-if="isLoading">{{ __('Generating payment receipt') }}...</p>
                            <p class="card-text" v-for="pp in pendingPayments" :key="pp.id">
                                {{ __('Payment pending for') }} $ {{ formatPrice(pp.amount) }} MXN <br>
                                <a target="_blank" :href="`/receipt-payment/${pp.id}`">{{ __('Download payment receipt') }}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['reservation_id'],
        data() {
            return {
                pendingPayments: [],
                isLoading: false,
            }
        },
        methods: {
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            async loadPendingPayments() {
                this.isLoading = true
                let {
                    data
                } = await axios.get(`/reservations/${this.reservation_id}/charges`, {
                    params: {
                        status: 'in_progress',
                        charge_methods: ['store', 'bank_account']
                    }
                });
                if (data.length > 0) {
                    this.pendingPayments = data;
                    this.isLoading = false;
                }
            },
        },
        mounted() {
            this.loadPendingPayments()

        }
    }

</script>
