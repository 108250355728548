<template>
  <div class="w-100 py-5 px-md-5 px-xl-6 position-relative">
    <div v-if="newuser == 1" class="alert alert-danger" role="alert">
      <h5>{{ __('Attention') }}</h5>
      {{ __('You must be registered to perform this action. Please register to access this site. Please sign in or create an account.') }}
    </div>
    <div class="mb-4">
      <h2 class="text-primary font-weight-bold">Astuto Travel</h2>
      <!--p class="text-muted" v-if="membershipTypeSelected !== null">{{ __('Sign up and start getting great benefits') }}</p>
      <p v-else>{{ __('Redeem your travelvoucher and start enjoying great benefits') }}</p -->
    </div>
    <div v-if="errorCode != ''" class="alert alert-danger" role="alert">
        <center>{{ __('Code not found') }}</center>
    </div>
    <div class="row">
        <div class="form-group col-md-10">
          <label for="name" class="form-label">{{ __('You have a registration number? (optional)') }}</label>
          <input
            name="name"
            id="name"
            type="string"
            placeholder="AST4566"
            autocomplete="off"
            required
            data-msg="Please enter your name"
            class="form-control"
            :class="{ 'is-invalid': hasErrors('name') }"
            v-model="nuberRegister"
          />
        </div>
        <div class="form-group col-md-2" style="margin-top: 30px;">
          <button @click="findNumberRegister" class="btn btn-primary">{{ __('Search') }}</button>
        </div>
    </div>
    <form
      class="form-validate mt-3 pl-3 pr-3 border-right border-left"
      id="register-form"
      action="/subscriptions"
      method="POST"
    >
      <csrf-token></csrf-token>
      <div v-if="this.memberships.length" class="row">
        <div class="form-group col-md-3"></div>
        <div class="form-group col-md-3">
          <center>
            <label for="name" class="form-label text-primary">{{ __('Membership') }}</label><br>
            <label for="">{{ this.memberships[0].name }}</label>
        </center>
        </div>
        <div class="form-group col-md-3">
          <center>
            <img v-bind:src="this.membershipsIcon" alt="" style="background-color: #6e0068;width: 60px;" />
          </center>
        </div>
        <div class="form-group col-md-3"></div>
        <input type="hidden" :value="this.canalSelected" name="chanel"/>
        <input type="hidden" :value="this.membershipSelected" name="membershipSelected"/>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label for="name" class="form-label">{{ __('Name') }}</label>
          <input type="hidden" value="10000" name="membership_type_id" id="membership_type_id"/>
          <input
            name="name"
            id="name"
            type="string"
            placeholder="John"
            autocomplete="off"
            required
            data-msg="Please enter your name"
            class="form-control"
            :class="{ 'is-invalid': hasErrors('name') }"
            v-model="client.name"
          />
          <div class="invalid-feedback" v-for="(error, index) in getErrors('name')" :key="index">
            {{ error }}
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="last_name" class="form-label">{{ __('Last Name') }}</label>
          <input
            name="last_name"
            id="last_name"
            type="string"
            placeholder="Doe"
            autocomplete="off"
            required
            data-msg="Please enter your last name"
            class="form-control"
            :class="{ 'is-invalid': hasErrors('last_name') }"
            v-model="client.last_name"
          />
          <div class="invalid-feedback" v-for="(error, index) in getErrors('last_name')" :key="index">
            {{ error }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="email" class="form-label">{{ __('Email') }}</label>
        <input
          name="email"
          id="email"
          type="email"
          placeholder="name@address.com"
          autocomplete="off"
          required
          data-msg="Please enter your email"
          class="form-control"
          :class="{ 'is-invalid': hasErrors('email') }"
          v-model="client.email"
        />
        <div class="invalid-feedback" v-for="(error, index) in getErrors('email')" :key="index">
          {{ error }}
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="international_code" class="form-label">{{ __('Country') }}</label>
            <select
              name="international_code"
              id="international_code"
              required
              class="form-control"
              :class="{ 'is-invalid': hasErrors('international_code') }"
              v-model="client.international_code"
            >
              <option :value="null">{{ __('Choose a option') }}</option>
              <option :value="'+52'">MEXICO +52</option>
              <option :value="'+1'">UNITED STATES +1</option>
              <option :value="'+1'">CANADA +1</option>
              <option :value="'+57'">COLOMBIA +57</option>
              <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
            </select>
            <div class="invalid-feedback" v-for="(error, index) in getErrors('international_code')" :key="index">
              {{ error }}
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="form-group">
            <label for="phone_number" class="form-label">{{ __('Phone number') }}</label>
            <input
              name="phone_number"
              id="phone_number"
              type="phone_number"
              placeholder="999 555 6226"
              autocomplete="off"
              required
              data-msg="Please enter your phone number"
              class="form-control"
              :class="{ 'is-invalid': hasErrors('phone_number') }"
              v-model="client.phone_number"
            />
            <div class="invalid-feedback" v-for="(error, index) in getErrors('phone_number')" :key="index">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.memberships.length">
        <br>
        <div class="row">
        <!--div class="form-group col-md-6">
          <label for="name" class="form-label">{{ __('Name') }} {{ __('Second person') }}</label>
          <input type="hidden" value="10000" name="membership_type_id" id="membership_type_id"/>
          <input
            name="name2"
            id="name2"
            type="string"
            placeholder="John"
            autocomplete="off"
            required
            data-msg="Please enter your name"
            class="form-control"
            :class="{ 'is-invalid': hasErrors('name2') }"
            v-model="client.name2"
          />
          <div class="invalid-feedback" v-for="(error, index) in getErrors('name2')" :key="index">
            {{ error }}
          </div>
        </div-->
        <!--div class="form-group col-md-6">
          <label for="last_name" class="form-label">{{ __('Last Name') }} {{ __('Second person') }}</label>
          <input
            name="last_name2"
            id="last_name2"
            type="string"
            placeholder="Doe"
            autocomplete="off"
            required
            data-msg="Please enter your last name"
            class="form-control"
            :class="{ 'is-invalid': hasErrors('last_name2') }"
            v-model="client.last_name2"
          />
          <div class="invalid-feedback" v-for="(error, index) in getErrors('last_name2')" :key="index">
            {{ error }}
          </div>
        </div-->
      </div>

      <!--div class="form-group">
        <label for="email2" class="form-label">{{ __('Email') }} {{ __('Second person') }}</label>
        <input
          name="email2"
          id="email2"
          type="email"
          placeholder="name@address.com"
          autocomplete="off"
          required
          data-msg="Please enter your email"
          class="form-control"
          :class="{ 'is-invalid': hasErrors('email2') }"
          v-model="client.email2"
        />
        <div class="invalid-feedback" v-for="(error, index) in getErrors('email2')" :key="index">
          {{ error }}
        </div>
      </div-->
        <!--div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="international_code" class="form-label">{{ __('Country') }}</label>
              <select
                name="international_code2"
                id="international_code2"
                required
                class="form-control"
                :class="{ 'is-invalid': hasErrors('international_code2') }"
                v-model="client.international_code2"
              >
                <option :value="null">{{ __('Choose a option') }}</option>
                <option :value="'+52'">MEXICO +52</option>
                <option :value="'+1'">UNITED STATES +1</option>
                <option :value="'+1'">CANADA +1</option>
                <option :value="'+57'">COLOMBIA +57</option>
                <option v-for="(item, index) in codes" :key="index" :value="item.code">{{ item.country }} {{ item.code }}</option>
              </select>
              <div class="invalid-feedback" v-for="(error, index) in getErrors('international_code2')" :key="index">
                {{ error }}
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <label for="phone_number" class="form-label">{{ __('Phone number') }} {{ __('Second person') }}</label>
              <input
                name="phone_number2"
                id="phone_number2"
                type="phone_number"
                placeholder="999 555 6226"
                autocomplete="off"
                required
                data-msg="Please enter your phone number"
                class="form-control"
                :class="{ 'is-invalid': hasErrors('phone_number2') }"
                v-model="client.phone_number2"
              />
              <div class="invalid-feedback" v-for="(error, index) in getErrors('phone_number2')" :key="index">
                {{ error }}
              </div>
            </div>
          </div>
        </div-->
      </div>
      <div v-if="this.memberships.length">
        <div class="row">
          <div class="col-md-9">
              <div class="js-form-message mb-3">
                  <label>{{ __('Address') }}</label>
                  <div class="js-focus-state input-group input-group form">
                      <input 
                          type="text" 
                          class="form-control form__input" 
                          name="address"
                          required="" 
                          :placeholder="__('Address')" 
                          aria-label="Address"
                          v-model="client.address"
                      />
                  </div>
              </div>
          </div>
          <div class="col-md-3">
              <div class="js-form-message mb-3">
                  <label>{{ __('Zip code') }}</label>
                  <div class="js-focus-state input-group input-group form">
                      <input 
                          type="number" 
                          class="form-control form__input" 
                          name="zip_code"
                          required="" 
                          placeholder="77711" 
                          aria-label="Código postal"
                          v-model="client.zipCode"
                      />
                  </div>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
              <div class="js-form-message mb-3">
                  <label>{{ __('Country') }}</label>
                  <div class="js-focus-state input-group input-group form">
                      <select
                          class="form-control form__input" 
                          name="country"
                          required="" 
                          aria-label="Country"
                          v-model="client.country"
                      >
                          <option :value="null">{{ __('Choose a option') }}</option>
                          <option :value="121">MEXICO</option>
                          <option :value="195">USA</option>
                          <option :value="32">CANADA</option>
                          <option :value="40">COLOMBIA</option>
                          <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </option>
                          
                      </select>
                  </div>
              </div>
          </div>
          <div class="col-md-4" v-if="stateOptions.length > 0">
              <div class="js-form-message mb-3">
                  <label>{{ __('State') }}</label>
                  <div class="js-focus-state input-group input-group form">
                      <select 
                          class="form-control form__input" 
                          name="state"
                          :placeholder="__('State')" 
                          aria-label="state"
                          v-model="client.state"
                      >
                          <option v-for="option in stateOptions" :key="option.value" :value="option.value">
                              {{ option.text }}
                          </option>
                      </select>
                  </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="js-form-message mb-3">
                  <label>{{ __('City') }}</label>
                  <div class="js-focus-state input-group input-group form">
                      <input 
                          class="form-control form__input" 
                          name="city_id"
                          required="" 
                          :placeholder="__('City')" 
                          aria-label="City"
                          v-model="client.city"
                      />
                  </div>
              </div>
          </div>
      </div>
      </div>
      <div class="form-group">
        <label for="password" class="form-label">{{ __('Password') }}</label>
        <input
          name="password"
          id="password"
          placeholder="Password"
          type="password"
          required
          data-msg="Please enter your password"
          :class="{ 'is-invalid': hasErrors('password') }"
          class="form-control"
          v-model="client.passport"
          min="8"
        />
        <small class="text-muted">* {{ __('The password must contain at least 8 characters.')}}</small>
        <div class="invalid-feedback" v-for="(error, index) in getErrors('password')" :key="index">
          {{ error }}
        </div>
      </div>
      <div class="form-group mb-4">
        <label for="password_confirm" class="form-label">{{ __('Confirm password') }}</label>
        <input
          name="password_confirmation"
          id="password_confirm"
          placeholder="Password"
          type="password"
          required
          data-msg="Please enter your password"
          class="form-control"
          :class="{ 'is-invalid': hasErrors('password_confirmation') }"
          v-model="client.password_confirmation"
          min="8"
        />
        <small class="text-muted">* {{ __('The password must contain at least 8 characters.')}}</small>
        <div class="invalid-feedback" v-for="(error, index) in getErrors('password_confirmation')" :key="index">
          {{ error }}
        </div>
      </div>
      <div class="form-group mb-4">
        <template>
          <vue-recaptcha 
          sitekey="6LfpS6gZAAAAABDvDo7FeMwPzeUjlQgVmcaFPFJc" 
          :loadRecaptchaScript="true"
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
          >
          </vue-recaptcha>
        </template>
      </div>
      <br/>
      <button
        type="submit"
        class="btn btn-lg btn-block btn-primary"
        :disabled="disableButton"
      >
        {{ __('Register') }}
      </button>
      
      <hr class="my-4" />
      <div class="form-group col-md-12">
      <center>
      <p class="text-sm text-muted">
        <input type="checkbox" class="form-group form-check-input" v-model="terms_and_conditions" id="terms_and_conditions">
        {{ __('With the user registration you accept') }}
        <a :href="`/termsAndConditions`" target="_blank">
          {{ __('Terms and conditions') }}
        </a> {{ __('and') }}
        <a :href="`/docs/privacy-policies-${lang}.pdf`">{{ __('Privacy policies') }}</a>.
      </p>
      </center>
      </div>
      <hr class="my-4" />
    </form>
    <a href="index.html" class="close-absolute mr-md-5 mr-xl-6 pt-5 d-none">
      <svg class="svg-icon w-3rem h-3rem">
        <use xlink:href="#close-1" />
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  props: ['old', 'errors', 'years', 'now', 'lang', 'codes','newuser'],
  data() {
    return {
      client: {  
        name: '',
        last_name: '',
        email: '',
        phone_number: '',
        passport: '',
        password_confirmation: '',
        international_code: null,
        address:null,
        zipCode:null,
        country:null,
        state:null,
        city:null,
        name2:null,
        last_name2:null,
        email2:null,
        international_code2:null,
        phone_number2:null
      },
      terms_and_conditions: false,
      captchaver: false,
      countries: [],
      nuberRegister:'',
      errorCode:'',
      membershipSelected:'',
      memberships: [],
      membershipsIcon: '',
      price:0,
      currencyprice:'',
      canalSelected:''
    }
  },
  mounted() {
    this.checkOld();
    this.loadCountries();
  },
  computed: {
    disableButton() {
      return !this.terms_and_conditions || !this.captchaver;
    },
    countryOptions() {
      return [].concat(this.countries.map(country => {
        return {
          text: country.country_name.toUpperCase(),
          value: country.id
        }
      }));
    },
    countrySelected() {
        if (this.client.country === null) return null;

        return this.countries.find(c => c.id == this.client.country);
    },
    cityOptions() {
        let options = [{text: this.__('Choose a city'), value: null}];
        if (this.countrySelected !== null) {
            options = options.concat(this.countrySelected.cities.map(city => {
                return {
                    text: city.city_name.toUpperCase(),
                    value: city.id
                }
            }));
        }

        return options;
    },
    stateOptions() {
        let options = [];
        if (this.countrySelected !== null && this.countrySelected) {
            options = options.concat(this.countrySelected.states.map(state => {
                return {
                    text: state.name.toUpperCase(),
                    value: state.id
                }
            }));

            if (options.length > 0) {
                options = [{text: this.__('Choose a state'), value: null}].concat(options);
            }
        }

        return options;
    }
  },
  methods: {
    onCaptchaVerified(recaptchaToken){
      this.captchaver = true;
    },
    async loadCountries() {
        let { data: countries } = await axios.get('/api/countries');

        this.countries = countries;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    formatPrice(value) {
        let val = (value / 1).toFixed(0).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    hasErrors(field) {
      return this.errors.hasOwnProperty(field) && Array.isArray(this.errors[field]) && this.errors[field].length > 0;
    },
    getErrors(field) {
      if (this.hasErrors(field)) {
        return this.errors[field];
      }

      return [];
    },
    async findNumberRegister(){
      this.errorCode = '';
      let response = await axios.get(`/membership-number-register/${this.nuberRegister}`);
      if(response.data == "error"){
        this.errorCode = "Codigo no encontrado";
      }else{
        this.memberships = response.data.membresias;
        this.membershipsIcon = this.memberships[0].svgIcon;
        this.canal = response.data.canal;
        this.canalSelected = this.canal.id;
        this.price = this.memberships[0].stripe_price;
        this.membershipSelected = this.memberships[0].id;
        this.currencyprice = this.memberships[0].stripe_currency_code;
      }
    },
    changeMembership(){
    },
    checkOld() {
        if (this.old instanceof Object) {
          if (this.old.hasOwnProperty('name')) {
            this.client.name = this.old.name;
          }
          
          if (this.old.hasOwnProperty('last_name')) {
            this.client.last_name = this.old.last_name;
          }

          if (this.old.hasOwnProperty('email')) {
            this.client.email = this.old.email;
          }
          
          if (this.old.hasOwnProperty('international_code')) {
            this.client.international_code = this.old.international_code;
          }

          if (this.old.hasOwnProperty('phone_number')) {
            this.client.phone_number = this.old.phone_number;
          }

          if (this.old.hasOwnProperty('passport')) {
            this.client.passport = this.old.passport;
          }

          if (this.old.hasOwnProperty('password_confirmation')) {
            this.client.password_confirmation = this.old.password_confirmation;
          }
        }
      }
  }
}
</script>