<template>
  <form id="pendingPaymentForm" name="payment-form" action="/process-pending-payment" method="POST" ref="pendingPaymentForm">
    <csrf-token></csrf-token>
    <input type="hidden" name="payment_id" :value="payment_id">
    <input type="hidden" name="reservation_id" :value="reservationId">
    <stripe-checkout :total="pending" 
      :currency="currency"
      @requesting-payment="is_loading = true"
      @payment-successfully="paymentSuccessfully"
      @payment-failure="paymentFailure"
    ></stripe-checkout>
  </form>
</template>

<script>
export default {
  props: {
    pending: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true
    },
    reservationId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      is_loading: false,
      payment_id: null
    }
  },
  methods: {
    paymentSuccessfully(paymentResult) {
      this.payment_id = paymentResult.id;
      this.$nextTick(() => {
        this.$refs.pendingPaymentForm.submit();
      })
    },
    paymentFailure(paymentResult){
      this.is_loading = false;
    },
  }
}
</script>
