export default {
    props: {
        serverErrors: {
            type: Object,
            default: function () {
                return {}
            } 
        }
    },
    methods: {
        hasError(field) {
            return Object.hasOwnProperty.call(this.serverErrors, field);
        },
        getErrors(field) {
            if (this.hasError(field))
                return this.serverErrors[field];

            return [];
        }
    }
}