<template>
    <div>
        <div v-if="yate">
            <div class="bg-gray-100 ">
                <div class="container py-3">
                    <div class="row">
                        <div class="col-12 col-xl-8 p-1 d-xl-block" style="max-height: 600px;">
                            <img v-bind:src="yate.images.length > 0 ? yate.images[0].public_path : ''" alt="..." class="img-fluid h-100 w-100 imglist" data-fancybox data-caption="" v-bind:href="yate.images.length > 0 ? yate.images[0].public_path : '#'">
                        </div>
                        <div class="col-12 col-xl-4 pt-1 pb-0 pl-0 pr-0">
                            <div class="swiper-container" v-swiper v-if="yate.images">
                                <div class="swiper-wrapper" style="max-height: 600px;">
                                    <div class="swiper-slide w-100" v-for="(asset, index) of yate.images" v-bind:key="asset.id" v-if="index != 0">
                                        <a data-fancybox data-caption="..." v-bind:href="asset.public_path" class="imglist">
                                            <img v-bind:src="asset.public_path" alt="..." class="img-fluid w-100 h-100">
                                        </a>
                                    </div>
                                </div>
                                <div class="swiper-pagination d-none d-xl-block"></div>
                                <div class="swiper-button-next d-xl-none"></div>
                                <div class="swiper-button-prev d-xl-none"></div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="d-flex justify-content-between align-items-start flex-column flex-lg-row align-items-lg-end">
                    <div class="mb-4 mb-lg-0">
                        <h2 class="mt-3"><i style="color: #0078b3;" class="fas fa-ship" aria-hidden="true"></i>{{yate.name}}</h2>
                    </div>
                </div>
            </div>
        
            <section>
                <div class="container">
                    <div class="row">
                        
                        <div class="col-lg-8">
                            <div class="text-block text-justify text-break">
                                <h3 v-if="lang == 'en'" class="mb-3" style="color: #0078b3;">{{ __('About this Yacht') }}</h3>
                                <h3 v-if="lang == 'es'" class="mb-3" style="color: #0078b3;">Acerca de este Yate</h3>
                                <p> 
                                <span v-if="lang == 'en'">
                                    {{ yate.descriptiones }} 
                                </span>
                                <span v-else>
                                  {{ yate.descriptiones }} 
                                </span>

                                </p>
                            </div>
                            <div class="text-block" v-if="lang == 'es'">
                                <h3 class="mb-4" style="color: #0078b3;">{{ __('Amenities') }}</h3>
                                <div class="row">
                                    <div class="col-4 d-flex align-items-start mb-2" v-for="tag in yate.amenities.split(',')" v-bind:key="tag+1">
                                        <i class="fas fa-check-circle mt-2 mr-2 text-justify text-muted"></i>
                                        <span>{{tag}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-block" v-else>
                                <h3 class="mb-4" style="color: #0078b3;">{{ __('Amenities') }}</h3>
                                <div class="row">
                                    <div class="col-4 d-flex align-items-start mb-2" v-for="tag in yate.amenitien.split(',')" v-bind:key="tag+1">
                                        <i class="fas fa-check-circle mt-2 mr-2 text-justify text-muted"></i>
                                        <span>{{tag}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="text-block" v-if="lang == 'es'">
                                <h3 class="mt-3 mb-4" style="color: #0078b3;">{{ __('Equipamento') }}</h3>
                                <div class="row">
                                    <div class="col-4 d-flex align-items-start mb-2" v-for="tag in yate.equipmentes.split(',')" v-bind:key="tag+1">
                                        <i class="fas fa-check-circle mt-2 mr-2 text-justify text-muted"></i>
                                        <span>{{tag}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-block" v-else>
                                <h3 class="mb-4 mt-3" style="color: #0078b3;">{{ __('Equipament') }}</h3>
                                <div class="row">
                                    <div class="col-4 d-flex align-items-start mb-2" v-for="tag in yate.equipmenten.split(',')" v-bind:key="tag+1">
                                        <i class="fas fa-check-circle mt-2 mr-2 text-justify text-muted"></i>
                                        <span>{{tag}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="pl-xl-4">
                                <div class="card border-0 shadow mb-5 d-sm-block">
                                    <div class="card-header bg-gray-100 py-4 border-0" style="background-color: #ececec !important;">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <p class="subtitle text-sm font-weight-bold">¡{{ __('Single offer') }}!</p>
                                                <h4 class="m-0">{{ __('Availability') }}</h4>

                                                <small><!-- Studio --></small>
                                                <ul class="list-inline text-sm m-0 mb-2 d-flex justify-content-between mt-3">
                                                    <li class="list-inline-item mr-3">
                                                        <i class="fas fa-ship mr-1 text-secondary"></i> {{ yate.name }} 
                                                    </li>
                                                </ul>
                                                <ul class="list-inline text-sm m-0 mb-2 d-flex justify-content-between mt-3">
                                                    <li class="list-inline-item mr-3">
                                                        <i class="fa fa-users mr-1 text-secondary"></i> {{ yate.max_pax }} {{ __('People') }}
                                                    </li>
                                                </ul>
                                                <ul class="list-inline text-sm m-0 mb-2 d-flex justify-content-between mt-3">
                                                    <li class="list-inline-item mr-3">
                                                        <i class="fas fa-ruler-horizontal mr-1 text-secondary" aria-hidden="true"></i> {{ yate.pies }}
                                                    </li>
                                                </ul>

                                                <div style="color: #0078b3;text-align: center;border-radius: 10px;margin-bottom: 10px;">
                                                    <span v-if="lang == 'es'" class="font-weight-bold" style="font-size: 26px;">
                                                        <span v-if="yate.type == 'compar'">Compartido</span>
                                                        <span v-if="yate.type == 'priv'">Privado</span>
                                                    </span>
                                                    <span v-if="lang == 'en'" class="font-weight-bold" style="font-size: 26px;">
                                                        <span v-if="yate.type == 'compar'">Shared</span>
                                                        <span v-if="yate.type == 'priv'">Private</span>
                                                    </span>
                                                </div>

                                                <div v-if="this.yate.prices" class="subtitle text-sm">
                                                    <span v-if="lang == 'en'">
                                                        <div v-for="price in yate.prices" style="color: #fff; background-color: #0078b3;text-align: center;border-radius: 10px;margin-bottom: 10px;">
                                                            <span class="font-weight-bold" style="font-size: 26px;"> ${{ formatPrice(price.price_publicusd) }} Dlls</span>
                                                            <div  style="font-size: 10px;margin-top: -8px;margin-left: 70px;">PER {{price.ours}} HOURS.</div>
                                                        </div>
                                                    </span>
                                                    
                                                    <span v-else>
                                                        <div v-for="price in yate.prices" style="color: #fff; background-color: #0078b3;text-align: center;border-radius: 10px;margin-bottom: 10px;">
                                                            <span class="font-weight-bold" style="font-size: 26px;"> ${{ formatPrice(price.price_publicmxn) }} Mxn</span>
                                                            <div  style="font-size: 10px;margin-top: -8px;margin-left: 70px;">POR {{price.ours}} HORA.</div>
                                                        </div>
                                                    </span>   
                                                </div>
                                                <button v-on:click="selectYate(yate)"
                                                :disabled="loader"
                                                style="background-color: #0078b3; color: #fff;"
                                                class="btn btn-block"> <i
                                                    class="far fa-paper-plane mr-2"></i>¡{{ __('I want this offer') }}! <i v-if="loader" class="fas fa-spin fa-spinner"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Contact-->
                                <div class="card border-0 shadow mb-5">                
                                    <div class="card border-0">
                                        <div class=" bg-gray-100 py-2 border-0 card-header" style="background-color: #e2e2e2 !important;">
                                            <div class="font-weight-bold mb-1" style="background-color: #e2e2e2;">{{ __('If you have doubts') }}</div>
                                            <h4>{{ __('Contact us') }}</h4>
                                        </div>
                                        <div class="card-body" style="background-color: #ececec !important;">
                                            <ul class="list-unstyled mb-4">
                                                <li class="mb-2" style="margin-bottom: -10px !important;"> 
                                                    <a style="color: #0078b3;" href="#" class="text-gray-00 text-sm text-decoration-none">
                                                        <span style="margin-top: 5px;" class="fa-stack fa-lg">
                                                        <i style="font-size: 30px !important;" class="fa fa-circle fa-stack-2x"></i>
                                                        <i style="font-size: 20px !important; margin-top: -5px;" class="fa fa-mobile fa-stack-1x fa-inverse"></i>
                                                        </span>
                                                        <span class="text-muted">+52 998 296 1015</span>
                                                    </a>
                                                </li>
                                                <li class="mb-2" style="margin-bottom: -10px !important;"> 
                                                    <a style="color: #0078b3;" href="#" class="text-gray-00 text-sm text-decoration-none">
                                                        <span style="margin-top: 5px;" class="fa-stack fa-lg">
                                                        <i style="font-size: 30px !important;" class="fa fa-circle fa-stack-2x"></i>
                                                        <i style="font-size: 20px !important; margin-top: -5px;color: #fff;" class="fab fa-whatsapp fa-stack-1x"></i>
                                                        </span>
                                                        <span class="text-muted">+52 998 128 6711</span>
                                                    </a>
                                                </li>
                                                <li class="mb-2" style="margin-bottom: -10px !important;"> 
                                                    <a href="#" style="color: #0078b3;" class=" text-sm text-decoration-none">
                                                        <span style="margin-top: 5px;" class="fa-stack fa-lg">
                                                        <i style="font-size: 30px !important;" class="fa fa-circle fa-stack-2x"></i>
                                                        <i style="font-size: 20px !important; margin-top: -5px;" class="fa fa-envelope fa-stack-1x fa-inverse"></i>
                                                        </span>
                                                        <span class="text-muted">info@astutotravel.com</span>
                                                    </a>
                                                </li>
                                                <li class="mb-2" style="margin-bottom: -10px !important;"> 
                                                    <a style="color: #0078b3;" href="#" class="text-blue text-sm text-decoration-none">
                                                        <span style="margin-top: 5px;" class="fa-stack fa-lg">
                                                        <i style="font-size: 30px;" class="fab fa-facebook fa-stack-2x"></i>
                                                        </span>
                                                        <span class="text-muted">Vacancy Rewards</span>
                                                    </a>
                                                </li>
                                                <li class="mb-2" style="margin-bottom: -10px !important;"> 
                                                    <a style="color: #0078b3;" href="#" class=" text-sm text-decoration-none">
                                                        <span style="margin-top: 5px;" class="fa-stack fa-lg">
                                                        <i style="font-size: 30px !important;" class="fa fa-circle fa-stack-2x"></i>
                                                        <i style="font-size: 20px !important; margin-top: -5px; color: #fff;" class="fab fa-instagram fa-stack-1x"></i>
                                                        </span>
                                                        <span class="text-muted">Vacancy Rewards</span>
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="text-center" v-else>
            <div class="spinner-border text-primary my-5" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</template>


<script>

    export default {
        props: ['currency','yate','lang'],
        data() {
            return {
                loader: false,
                name:'',
                mail:'',
                phone:'',
                comments:''
            }
        },
        mounted() {
        },
        methods: {
            selectYate(yate) {
                this.loader = true;
                localStorage.setItem('selectedYate', JSON.stringify(yate));
                window.location.href = "/client/information/yate/"+yate.id
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace(',', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        }
    }

</script>
<style type="text/css">
    .swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic{
       width: 15px;                                                                                        
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main{
        border: 2px solid rgb(98, 37, 107);
        height: 14px;
        width: 14px;
        margin-left: -1px;
        opacity: 1;
        transform: scale(1) !important;
        background: rgb(255, 255, 255) !important;
    }
    /*.swiper-pagination-bullet*/
    .swiper-pagination-bullet.swiper-pagination-bullet-active-prev, .swiper-pagination-bullet.swiper-pagination-bullet-active-next, .swiper-pagination-bullet.swiper-pagination-bullet-active-next-next{
        border: 2px solid rgb(98, 37, 107);
        height: 14px;
        width: 14px;
        margin-left: -1px;
        opacity: 1;
        transform: scale(.8) !important;
        background: rgb(255, 255, 255) !important;                                                                                                                              
    }
</style>