<template>
  <div class="rounded p-3" :class="bg">
    <slot>
      <h3 class="text-muted">{{ __('Transfers') }}</h3>
    </slot>
    <form action="/list/car-rentals" method="GET" @submit.prevent="checkForm" ref="carRentalAvailabilityForm">
      <div class="row">
        <div class="col-md-2">
          <div class="mb-3">
            <select name="type" id="type" class="form-control" v-model="type">
              <option :value="null">{{ __('Select an option') }}</option>
              <option v-for="t in typeOptions" :key="t.id" :value="t.id">{{ t.name }}</option>
            </select>
            <div v-if="errors.type" class="invalid-feedback">
              {{ __('Invalid value') }}
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="mb-3">
            <select name="zone" id="zone" class="form-control" v-model="zone">
              <option :value="null">{{ __('Select an option') }}</option>
              <option v-for="z in zoneOptions" :key="z.id" :value="z.id">{{ z.name }}</option>
            </select>
            <div v-if="errors.zone" class="invalid-feedback">
              {{ __('Invalid value') }}
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="mb-3">
            <div class="input-group input-group form">
              <date-picker v-model="selectedDate" 
                type="date" 
                :disabled-date="notBeforeToday"
                :input-attr="{name: 'date'}"
                :placeholder="__('Date')"
                input-class="form-control"  
              ></date-picker>
            </div>
            <div v-if="errors.date" class="invalid-feedback">
              {{ __('Invalid value') }}
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="mb-3" v-if="type == 1">
            <div class="input-group input-group form">
              <date-picker v-model="selectedEndDate" 
                type="date" 
                :disabled-date="notBeforeStartDate"
                :input-attr="{name: 'end_date'}"
                :placeholder="__('End date')"
                input-class="form-control"  
              ></date-picker>
            </div>
            <div v-if="errors.end_date" class="invalid-feedback">
              {{ __('Invalid value') }}
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <button
            type="submit"
            id="searchButton"
            class="btn btn-block btn-primary"
            :disabled="loader"
          >{{ __('Search for the best offer') }} <i v-if="loader" class="fas fa-spin fa-spinner"></i></button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import _ from "underscore";
import DatePicker from 'vue2-datepicker';
import parse from "date-fns/parse";
import isBefore from "date-fns/isBefore";

const API_URL = "/api/cities";
const today = new Date();

export default {
  props: {
    months: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    },
    bg: {
      type: String,
      required: false,
      default: "bg-white"
    },
    zoneSelected: {
      type: Number,
      default: null
    },
    typeSelected: {
      type: Number,
      default: null
    },
    zones: {
      type: Array,
      default: function () {
        return [];
      }
    },
    date: {
      type: String,
      default: ""
    },
    endDate: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      type: null,
      zone: null,
      selectedDate: null,
      selectedEndDate: null,
      errors: {
        type: false,
        zone: false,
        date: false,
        end_date: false
      },
      loader: false,
      loading: false
    };
  },
  components: {
    DatePicker
  },
  mounted() {
    if (this.date) {
      this.selectedDate = parse(this.date, 'yyyy-MM-dd', new Date());
    } 

    if (this.endDate) {
      this.selectedEndDate = parse(this.endDate, 'yyyy-MM-dd', new Date());
    }

    this.type = this.typeSelected;
    this.$nextTick(() => {
      this.zone = this.zoneSelected;
    });
  },
  methods: {
    notBeforeToday(date) {
      return date < today;
    },
    notBeforeStartDate(date) {
      return this.selectedDate === null ? date < today : isBefore(date, this.selectedDate);
    },
    checkForm: function(e) {
      this.errors.type = this.type === null;
      this.errors.zone = this.zone === null;
      this.errors.date = this.selectedDate === null;

      if (this.type == 2) {
        this.errors.end_date = false;
      } else {
        this.errors.end_date = this.selectedEndDate === null;
      }

      let isValid = Object.keys(this.errors).reduce((ac, key) => ac && !this.errors[key], true);

      if (!isValid) return;

      $("#cover-spin").show(0);
      this.loader = true;
      
      this.$refs['carRentalAvailabilityForm'].submit();
    }
  },
  computed: {
    typeOptions() {
      return this.zones.map(({type, label}) => {
        return {
          id: type, 
          name: label
        };
      });
    },
    typeOptionSelected() {
      if (this.type !== null) {
        return this.zones.find(z => z.type == this.type);
      }

      return null;
    },
    zoneOptions() {
      if (this.typeOptionSelected !== null) {
        return this.typeOptionSelected.data.map(z => {
          return {
            id: z.zone_id,
            name: `${z.zone_name}, ${z.city_name}`
          };
        });
      }

      return [];
    }
  }
};
</script>

<style scoped>
.white-translucent {
  background: rgba(255, 255, 255, 0.8);
}
</style>